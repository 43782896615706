import { hideError, showError } from "../../appRedux/error/Actions";
import constants from "../../assets/constants.json";
import { get, post, patch, put } from "./http";

const _prepareReponseInformation = (data, status, errorMsg, statusCode) => {
    return { response: data, status, errorMsg, statusCode };
};

const handleSuccessResponse = async (response, responseProps) => {

    const { data, status, statusCode } = response;

    const { callback , dispatch  } = responseProps;

    dispatch && dispatch(hideError());

    callback && callback(
        _prepareReponseInformation(
            data,
            true,
            undefined,
            statusCode || status
        )
    );
};

const handleErrorResponse = (error, responseProps) => {

    const { response } = error;

    const { callback , dispatch  } = responseProps;

    let errorMsg = `${error}`;

    if (response) {
        errorMsg = `API ERROR - response: ${response?.status} |  status: ${response?.statusText} | Path: ${response?.config?.url}`;
    }

    dispatch && dispatch(hideError());

    setTimeout(() => {
        dispatch && dispatch(showError());
    }, 500);

    callback && callback(
        _prepareReponseInformation(
            response?.data,
            false,
            errorMsg,
            response?.statusCode || response?.status
        )
    );
};

const responseHandler = ({ response, error }, responseProps) => {
    response !== undefined ? handleSuccessResponse(response, responseProps) : handleErrorResponse(error, responseProps);
};

const callApi = (
    {
        httpMethod,
        apiEndPoint,
        reqHeader,
        body,
        queryParams
    },
    callback,
    dispatch
) => {
    /**
     * responseProps needed in handlers after response is recieved
     * we can handle Apploader as well as global error here
     */
    const responseProps = { callback, dispatch };
    switch (httpMethod) {

        case constants.GET:
            get(apiEndPoint, reqHeader, queryParams)(responseHandler, responseProps);
            break;

        case constants.POST:
            post(apiEndPoint, reqHeader, body, queryParams)(responseHandler, responseProps);
            break;

        case constants.PATCH:
            patch(apiEndPoint, reqHeader, body, queryParams)(responseHandler, responseProps);
            break;

        case constants.PUT:
            put(apiEndPoint, reqHeader, body, queryParams)(responseHandler, responseProps);
            break;

        default:
            break;
    }
}

export { callApi };