import { useLang, rem, colors, Stack } from "@manulife/mux";
import { CriticalIllness } from "@manulife/mux-cds-icons";
import {
  NormalText,
  SectionLabel,
  InsuranceAmt,
} from "../../assets/appStyledComponents";
import ResultConstants from "../../assets/resultConstants";
import LifeResultConstants from "../../assets/resultLifeConstants.json";
import NumberConstants from "../../assets/appStyledComponents/numberConstant.json";
import { formatAmount } from "../../services/helpers/commonHelpers";

function LifePersonDetails({ name, insuranceAmount, costAmount, age }) {
  const { num20, num8, num100000, num18, num60 } = NumberConstants;
  const selectedLang = useLang();
  const getName = (name) => {
    if (name === "you") {
      return ResultConstants.name[selectedLang];
    } else if (name === "partner") {
      return ResultConstants.partnerName[selectedLang];
    } else {
      return name;
    }
  };
  const showWarning = () => {
    return LifeResultConstants.warningAmt[selectedLang];
  };
  return (
    <>
      <Stack
        align="flex-start"
        style={{ alignSelf: "stretch" }}
        gap={rem(num20)}
      >
        <SectionLabel>{getName(name)}</SectionLabel>
        <Stack direction="row" gapX="0">
          <Stack style={{ width: "10%" }}>
            <CriticalIllness
              fontSize={rem(num20)}
              color={colors.dark_navy}
              style={{ marginRight: rem(num8) }}
            />
          </Stack>
          <Stack style={{ width: "90%" }}>
            {LifeResultConstants.productName[`${selectedLang}`]}
          </Stack>
        </Stack>
        <Stack
          align="flex-start"
          style={{ alignSelf: "stretch" }}
          gap={rem(num8)}
        >
          <NormalText>
            {LifeResultConstants.insuranceAmt[`${selectedLang}`]}
          </NormalText>
          <InsuranceAmt>
            {formatAmount(insuranceAmount, selectedLang)}
          </InsuranceAmt>
        </Stack>
        <Stack
          align="flex-start"
          style={{ alignSelf: "stretch" }}
          gap={rem(num8)}
        >
          <NormalText>
            {LifeResultConstants.costAmt[`${selectedLang}`]}
          </NormalText>
          <InsuranceAmt>{formatAmount(Number(costAmount).toFixed(2), selectedLang)}</InsuranceAmt>
        </Stack>
        <NormalText>
          {insuranceAmount !== "0.00" &&
            insuranceAmount === num100000 &&
            showWarning()}
        </NormalText>
        <NormalText>
          {!(Number(age) > num18 && Number(age) <= num60) &&
            LifeResultConstants?.warningAge[selectedLang]}
        </NormalText>
      </Stack>
    </>
  );
}

export default LifePersonDetails;
