import {
  RESET_CHECKS,
  UPDATE_CHECKS
} from "./Types";

const initialState = {};

const IrResultCheckboxReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case RESET_CHECKS:
      return {
        ...initialState,
      };

    case UPDATE_CHECKS:
      return {
        ...payload,
      };

    default:
      return state;
  }
};

export default IrResultCheckboxReducer;
