import React from 'react';
import { NormalText, SectionHeadding } from '../../assets/appStyledComponents';
import { BREAKPOINTS, Button, Stack, TextLink, rem, useLang } from '@manulife/mux';
import { useNavigate } from 'react-router-dom';
import NumberConstants from '../../assets/appStyledComponents/numberConstant.json';
import useWindowSize from '../../customHooks/useWindowSize';
import { useFormContext } from 'react-hook-form';
import IrCurrentEmploymentIncome from './IrCurrentEmploymentIncome';
import IrMonthlyExpenses from './IrMonthlyExpenses';
import IrDisabilityInsuranceOrOtherIncome from './IrDisabilityInsuranceOrOtherIncome';
import { eCalculatorTab, eEmploymentStatusType, eIncluded } from '../../services/helpers/udmConstants';
import DisabilityInputs from './IrDisabilityInputs.json'
import { getOnlyNumbers } from '../../services/helpers/commonHelpers';
import { useDispatch } from 'react-redux';
import { fetchReport } from '../../appRedux/IrCalculator/Actions';

function IrDisabilityInputs({ storedAboutYouData }) {
    const selectedLang = useLang();
    const { width: windowWidth } = useWindowSize();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { handleSubmit } = useFormContext(); // retrieve all hook methods

    const { num32, num24, num12000 } = NumberConstants;

    const { irInsuranceFor, you, partner } = storedAboutYouData;

    const {
        tabHeading,
        tabSubHeading,
        calculateBtn,
        backBtn,
        optionalSubheading: {
            text1,
            text2,
            linkText,
            url
        }
    } = DisabilityInputs;

    const fetchCalculation = (data) => {
        dispatch(fetchReport({
            ...data
        }, selectedLang));
    };

    const displayOptional = irInsuranceFor === eIncluded.justMe ? Number(you.irAge) > 60 || you.irEmploymentStatus === eEmploymentStatusType.notEmployed || Number(getOnlyNumbers(you.irAnnualIncome)) < num12000 : Number(you.irAge) > 60 || Number(partner.irAge) > 60 ||
        you.irEmploymentStatus === eEmploymentStatusType.notEmployed || partner.irEmploymentStatus === eEmploymentStatusType.notEmployed ||
        Number(getOnlyNumbers(you.irAnnualIncome)) < num12000 || Number(getOnlyNumbers(partner.irAnnualIncome)) < num12000

    return (
        <Stack align="flex-start" gap={`${rem(num32)}`} style={{ alignSelf: "stretch" }}>
            <SectionHeadding style={{
                padding: windowWidth > BREAKPOINTS.MAX_768 ? `${rem(0)}` : `${rem(0)} ${rem(num24)}`
            }}>{tabHeading[selectedLang]}</SectionHeadding>

            <NormalText style={{
                padding: windowWidth > BREAKPOINTS.MAX_768 ? `${rem(0)}` : `${rem(0)} ${rem(num24)}`
            }}>{tabSubHeading[selectedLang]}</NormalText>

            {displayOptional && <NormalText style={{
                padding: windowWidth > BREAKPOINTS.MAX_768 ? `${rem(0)}` : `${rem(0)} ${rem(num24)}`
            }}>
                {text1[selectedLang]}
                <TextLink
                    children={linkText[selectedLang]}
                    to={url[selectedLang]}
                    aria-label={linkText[selectedLang]}
                    target="_blank"
                    rel="noopener noreferrer"
                    isDocument
                />
                {text2[selectedLang]}
            </NormalText>}
            <IrCurrentEmploymentIncome tabName={eCalculatorTab.disability} name1={you.irName} name2={partner.irName} selectedIrInsuranceFor={irInsuranceFor} storedAboutYouData={storedAboutYouData} />
            <IrMonthlyExpenses tabName={eCalculatorTab.disability} name1={you.irName} name2={partner.irName} selectedIrInsuranceFor={irInsuranceFor} />
            <IrDisabilityInsuranceOrOtherIncome tabName={eCalculatorTab.disability} name1={you.irName} name2={partner.irName} selectedIrInsuranceFor={irInsuranceFor} />
            <Stack direction={windowWidth > BREAKPOINTS.MAX_768 ? "row" : "column-reverse"} align="center" justify="space-between" style={{
                padding: windowWidth > BREAKPOINTS.MAX_768 ? `${rem(0)}` : `${rem(0)} ${rem(num24)}`,
                alignSelf: "stretch"
            }}>
                <Button
                    variant={Button.VARIANT.TERTIARY_BACK}
                    onClick={() => navigate("/about", { replace: true })}
                    customStyle={{
                        buttonStyle: {
                            media: [
                                {
                                    maximum: BREAKPOINTS.PHONE_PORTRAIT_LG,
                                    width: "100%",
                                },
                            ],
                        },
                    }}>{backBtn[selectedLang]}</Button>
                <Button
                    onClick={(v, e) => {
                        // console.log(e);
                        e.preventDefault();
                        window.scrollTo({ top: 0 });
                        handleSubmit(fetchCalculation)()
                    }}
                    customStyle={{
                        buttonStyle: {
                            media: [
                                {
                                    maximum: BREAKPOINTS.PHONE_PORTRAIT_LG,
                                    width: "100%",
                                },
                            ],
                        },
                    }}>{calculateBtn[selectedLang]}</Button>
            </Stack>
        </Stack>
    )
}

export default IrDisabilityInputs