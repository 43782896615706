import React from 'react';
import { BREAKPOINTS, Stack, colors, rem, useLang } from '@manulife/mux'
import useWindowSize from '../../customHooks/useWindowSize';
import NumberConstants from '../../assets/appStyledComponents/numberConstant.json';
import { BoldResultText } from '../../assets/appStyledComponents';
import { eForWhom, eIncluded } from '../../services/helpers/udmConstants';
import LABELS from '../../assets/labels';

function IrTabTblHeader({ name1, name2, selectedIrInsuranceFor }) {
    const selectedLang = useLang();
    const { width: windowWidth } = useWindowSize();
    const { num12, num24, num16, num2, num10, num200 } = NumberConstants;
    return (
        <Stack align="flex-start" gap={`${rem(0)}`} style={{ alignSelf: `stretch` }}>
            <Stack align="flex-start" gap={`${rem(0)}`} style={{
                padding: rem(num2),
                alignSelf: `stretch`,
                backgroundColor: colors.light_grey
            }}>
            </Stack>
            {
                windowWidth > BREAKPOINTS.MAX_768 && (<>
                    <Stack direction="row" align="flex-start" gap={`${rem(num24)}`}
                        style={{
                            padding: windowWidth > BREAKPOINTS.MAX_768 ? `${rem(num16)} ${rem(num12)}` : `${rem(num16)} ${rem(num24)}`,
                            alignSelf: `stretch`
                        }}>
                        <Stack align="center" gap={`${rem(num10)}`} style={{ flex: "1 0 0", alignSelf: `stretch` }}>
                        </Stack>
                        <Stack align="flex-start" gap={`${rem(num10)}`} style={{ width: rem(num200), alignSelf: `stretch` }}>
                            <BoldResultText>{name1 === eForWhom.you ? LABELS.youHeader[selectedLang] : name1}</BoldResultText>
                        </Stack>
                        <Stack align="flex-start" gap={`${rem(num10)}`} style={{ width: rem(num200), alignSelf: `stretch` }}>
                            {selectedIrInsuranceFor === eIncluded.meAndPartner && <BoldResultText>{name2 === eForWhom.partner ? LABELS.partnerHeader[selectedLang] : name2}</BoldResultText>}
                        </Stack>
                    </Stack>
                    <Stack align="flex-start" gap={`${rem(0)}`} style={{
                        padding: rem(1),
                        margin: windowWidth > BREAKPOINTS.MAX_768 ? rem(0) : `${rem(0)} ${rem(num24)}`,
                        alignSelf: `stretch`,
                        backgroundColor: colors.light_grey
                    }}>
                    </Stack>
                </>)
            }
        </Stack>
    )
}

export default IrTabTblHeader;