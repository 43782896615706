import LifeEmailContent from "../EmailTemplates/Life/LifeEmailContent";
import { formatDate } from "../../../services/date/dateProcessors";
import {
  formatAmount,
  replaceKeysWithValues,
} from "../../../services/helpers/commonHelpers";
import ResultConstants from "../../../assets/resultConstants";
import {
  displayGender,
  displayHealthStyle,
  eCalculatorTab,
  eIncluded,
  eProduct,
  getProductName,
} from "../../../services/helpers/udmConstants";

export const emailLifeBody = (lang, pageData, resultData, checkedBtns) => {

  return `${replaceKeysWithValues(LifeEmailContent.bodyLine1[lang], {
    current_date: formatDate("emailTemplateDate", lang),
  })} 

${getYou(pageData, lang, resultData, checkedBtns)}

${getPartner(pageData, lang, resultData, checkedBtns)}

${LifeEmailContent.footer[lang]}`;
};

const getName = (name, lang) => {
  if (name === "you") {
    return ResultConstants.name[lang];
  } else if (name === "partner") {
    return ResultConstants.partnerName[lang];
  } else {
    return name;
  }
};

const getYou = (pageData, lang, resultData, checkedBtns) => {

  const lifeTabData = resultData?.[eCalculatorTab.life];
  const ciTabData = resultData?.[eCalculatorTab.criticalIllness];
  const disabilityTabData = resultData?.[eCalculatorTab.disability];

  const youLifePolicy = lifeTabData?.illustration?.policy.filter(
    (obj) =>
      obj.product === getProductName(eProduct.FT) &&
      obj.coverage[0].partyId === `Life.1`
  );

  const youCIPolicy = ciTabData?.illustration?.policy.filter(
    (obj) =>
      obj.product === getProductName(eProduct.LC) &&
      obj.coverage[0].partyId === `Life.1`
  );

  const youDisabilityPolicy = disabilityTabData?.illustration?.policy.filter(
    (obj) =>
      obj.product === getProductName(eProduct.VS) &&
      obj.coverage[0].partyId === `Life.1`
  );

  return `${replaceKeysWithValues(LifeEmailContent.you[lang], {
    name: getName(pageData?.you?.irName, lang),
  })} ${replaceKeysWithValues(LifeEmailContent.gender[lang], {
    gender: displayGender(pageData?.you?.irSex,lang),
  })} ${replaceKeysWithValues(LifeEmailContent.age[lang], {
    age: pageData?.you?.irAge,
  })} ${replaceKeysWithValues(LifeEmailContent.smokingStatus[lang], {
    smokingStatus: displayHealthStyle(pageData?.you?.irSmokingStatus,lang),
  })}
${checkedBtns[eCalculatorTab.life]
      ? getLife(
        youLifePolicy[0]?.coverage[0]?.amount,
        youLifePolicy[0]?.minimumModalPremium,
        lang
      )
      : ""
    }${checkedBtns[eCalculatorTab.disability]
      ? getDisability(
        youDisabilityPolicy[0]?.coverage[0]?.amount,
        lang
      )
      : ""
    }${checkedBtns[eCalculatorTab.criticalIllness]
      ? getCI(
        youCIPolicy[0]?.coverage[0]?.amount,
        youCIPolicy[0]?.minimumModalPremium,
        lang
      )
      : ""
    }`;
};

const getPartner = (pageData, lang, resultData, checkedBtns) => {

  const lifeTabData = resultData?.[eCalculatorTab.life];
  const ciTabData = resultData?.[eCalculatorTab.criticalIllness];
  const disabilityTabData = resultData?.[eCalculatorTab.disability];

  const partnerLifePolicy = lifeTabData?.illustration?.policy.filter(
    (obj) =>
      obj.product === getProductName(eProduct.FT) &&
      obj.coverage[0].partyId === `Life.2`
  );

  const partnerCIPolicy = ciTabData?.illustration?.policy.filter(
    (obj) =>
      obj.product === getProductName(eProduct.LC) &&
      obj.coverage[0].partyId === `Life.2`
  );

  const partnerDisabilityPolicy = disabilityTabData?.illustration?.policy.filter(
    (obj) =>
      obj.product === getProductName(eProduct.VS) &&
      obj.coverage[0].partyId === `Life.2`
  );
  if (pageData?.irInsuranceFor === eIncluded.meAndPartner) {
    return `${replaceKeysWithValues(LifeEmailContent.yourPartner[lang], {
      yourPartner: getName(pageData?.partner?.irName, lang),
    })} ${replaceKeysWithValues(LifeEmailContent.partnerGender[lang], {
      gender: displayGender(pageData?.partner?.irSex, lang),
    })} ${replaceKeysWithValues(LifeEmailContent.partnerAge[lang], {
      age: pageData?.partner?.irAge,
    })} ${replaceKeysWithValues(LifeEmailContent.partnerSmokingStatus[lang], {
      smokingStatus: displayHealthStyle(pageData?.partner?.irSmokingStatus, lang),
    })}
${checkedBtns[eCalculatorTab.life]
        ? getLife(
          partnerLifePolicy[0]?.coverage[0]?.amount,
          partnerLifePolicy[0]?.minimumModalPremium,
          lang
        )
        : ""
      }${checkedBtns[eCalculatorTab.disability]
        ? getDisability(
          partnerDisabilityPolicy[0]?.coverage[0]?.amount,
          lang
        )
        : ""
      }${checkedBtns[eCalculatorTab.criticalIllness]
        ? getCI(
          partnerCIPolicy[0]?.coverage[0]?.amount,
          partnerCIPolicy[0]?.minimumModalPremium,
          lang
        )
        : ""
      }`;
  } else {
    return "";
  }
};

const getLife = (insuranceAmt, costAmt, lang) => {
  return `
${LifeEmailContent.lifeResult[lang]}
  - ${replaceKeysWithValues(LifeEmailContent.lifeResultAmt[lang], {
    insuranceAmount: formatAmount(insuranceAmt, lang),
  })}
  - ${replaceKeysWithValues(LifeEmailContent.lifeResultCost[lang], {
    costAmount: formatAmount(Number(costAmt).toFixed(2), lang),
  })}`;
};

const getDisability = (disabilityAmt, lang) => {
  return `
${LifeEmailContent.disabilityResult[lang]}
  - ${replaceKeysWithValues(LifeEmailContent.disabilityResultAmt[lang], {
    disabilityAmount: formatAmount(disabilityAmt, lang),
  })}
  - ${LifeEmailContent.disabilityResultCost[lang]}`;
};

const getCI = (ciAmt, costAmt, lang) => {
  return `
${LifeEmailContent.ciResult[lang]}
  - ${replaceKeysWithValues(LifeEmailContent.ciResultAmt[lang], {
    ciAmount: formatAmount(ciAmt, lang),
  })}
  - ${replaceKeysWithValues(LifeEmailContent.ciResultCost[lang], {
    costAmount: formatAmount(Number(costAmt).toFixed(2), lang),
  })}`;
};
