import { eChoice } from "../helpers/udmConstants";

export const generateIllustration = (
    language,
    childCount,
    collegeFeeInd,
    partyArray,
    policyArray
) => {
    return {
        language,
        childCount,
        collegeFeeInd: collegeFeeInd === eChoice.yes ? true : false,
        party: partyArray,
        policy: policyArray
    };
};