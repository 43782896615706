import React from "react";
import { useSelector } from "react-redux";
import {
  eCalculatorTab,
  eIncluded,
  eProduct,
  getProductName,
} from "../../services/helpers/udmConstants";
import DisabilityDetails from "./DisabilityDetails";
import { Divider } from "../../assets/appStyledComponents";
import IrResultWrapper from "./IrResultWrapper";

function IrDisabilityResultSection() {
  const PageData = useSelector((state) => state.irCalculatorReducer?.data);
  const currTabData = PageData?.result?.[eCalculatorTab.disability];
  const youPolicy = currTabData?.illustration?.policy.filter(
    (obj) =>
      obj.product === getProductName(eProduct.VS) &&
      obj.coverage[0].partyId === `Life.1`
  );
  const partnerPolicy = currTabData?.illustration?.policy.filter(
    (obj) =>
      obj.product === getProductName(eProduct.VS) &&
      obj.coverage[0].partyId === `Life.2`
  );
  return (
    <IrResultWrapper>
      <DisabilityDetails
        name={PageData?.step1?.you?.irName}
        insuranceAmount={
          youPolicy && youPolicy[0]?.coverage[0]?.amount
            ? youPolicy[0]?.coverage[0]?.amount
            : "0.00"
        }
      />
      {PageData?.step1?.irInsuranceFor === eIncluded.meAndPartner && (
        <>
          <Divider />
          <DisabilityDetails
            name={PageData?.step1?.partner?.irName}
            insuranceAmount={
              partnerPolicy && partnerPolicy[0]?.coverage[0]?.amount
                ? partnerPolicy[0]?.coverage[0]?.amount
                : "0.00"
            }
          />
        </>
      )}
    </IrResultWrapper>
  );
}

export default IrDisabilityResultSection;
