import React from "react";
import IrResultWrapper from "./IrResultWrapper";
import { useSelector } from "react-redux";
import {
  eCalculatorTab,
  eIncluded,
  eProduct,
  getProductName,
} from "../../services/helpers/udmConstants";
import LifePersonDetails from "./LifePersonDetails";
import { Divider } from "../../assets/appStyledComponents";

function IrLifeResultSection() {
  const PageData = useSelector((state) => state.irCalculatorReducer?.data);
  const currTabData = PageData?.result?.[eCalculatorTab.life];
  const youPolicy = currTabData?.illustration?.policy.filter(
    (obj) =>
      obj.product === getProductName(eProduct.FT) &&
      obj.coverage[0].partyId === `Life.1`
  );
  const partnerPolicy = currTabData?.illustration?.policy.filter(
    (obj) =>
      obj.product === getProductName(eProduct.FT) &&
      obj.coverage[0].partyId === `Life.2`
  );
  return (
    <IrResultWrapper>
      <LifePersonDetails
        name={PageData?.step1?.you?.irName}
        insuranceAmount={
          youPolicy && youPolicy[0]?.coverage[0]?.amount
            ? youPolicy[0]?.coverage[0]?.amount
            : "0.00"
        }
        costAmount={
          youPolicy && youPolicy[0]?.minimumModalPremium
            ? youPolicy[0]?.minimumModalPremium
            : "0.00"
        }
        age ={PageData?.step1?.you?.irAge}
      />
      {PageData?.step1?.irInsuranceFor === eIncluded.meAndPartner && (
        <>
          <Divider />
          <LifePersonDetails
            name={PageData?.step1?.partner?.irName}
            insuranceAmount={
              partnerPolicy && partnerPolicy[0]?.coverage[0]?.amount
                ? partnerPolicy[0]?.coverage[0]?.amount
                : "0.00"
            }
            costAmount={
              partnerPolicy && partnerPolicy[0]?.minimumModalPremium
                ? partnerPolicy[0]?.minimumModalPremium
                : "0.00"
            }
            age={PageData?.step1?.partner?.irAge}
          />
        </>
      )}
    </IrResultWrapper>
  );
}

export default IrLifeResultSection;
