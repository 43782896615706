const LifeEmailContent = {
  subject: {
    "en-CA": "Your InsureRight quote",
    "fr-CA": "Votre devis Bien s’assurer",
  },
  bodyLine1: {
    "en-CA": "Your InsureRight results as of {{current_date}}",
    "fr-CA": "Vos besoins en assurance au {{current_date}}",
  },
  footer: {
    "en-CA":
      "Insurance products are issued by The Manufacturers Life Insurance Company. The Manulife InsureRight calculator does not provide an offer to insure. Applications for insurance are subject to underwriting. Rates are subject to change without notice. © 2023 Manulife. All rights reserved.",
    "fr-CA":
      "Les produits d’assurance sont établis par La Compagnie d’Assurance-Vie Manufacturers. Le calculateur « Bien s’assurer » n’a pas pour but d’offrir de l’assurance. Les propositions sont soumises à la tarification. Les taux peuvent être modifiés sans préavis. © Manuvie, 2023. Tous droits réservés.",
  },
  you: {
    "en-CA": "{{name}}: ",
    "fr-CA": "{{name}}: ",
  },
  gender: {
    "en-CA": "{{gender}}, ",
    "fr-CA": "{{gender}}, ",
  },
  age: {
    "en-CA": "age {{age}}, ",
    "fr-CA": "{{age}} ans, ",
  },
  smokingStatus: {
    "en-CA": "{{smokingStatus}}",
    "fr-CA": "{{smokingStatus}}",
  },
  yourPartner: {
    "en-CA": "{{yourPartner}}: ",
    "fr-CA": "{{yourPartner}}: ",
  },
  partnerGender: {
    "en-CA": "{{gender}}, ",
    "fr-CA": "{{gender}}, ",
  },
  partnerAge: {
    "en-CA": "age {{age}}, ",
    "fr-CA": "{{age}} ans, ",
  },
  partnerSmokingStatus: {
    "en-CA": "{{smokingStatus}}",
    "fr-CA": "{{smokingStatus}}",
  },
  lifeResult: {
    "en-CA": "Life insurance: Family Term, Term-20",
    "fr-CA": "Assurance vie: Temporaire Famille – Temporaire 20 ans",
  },
  lifeResultAmt: {
    "en-CA": "Amount: {{insuranceAmount}}",
    "fr-CA": "Montant: {{insuranceAmount}}",
  },
  lifeResultCost: {
    "en-CA": "Monthly cost: {{costAmount}}",
    "fr-CA": "Coût mensuel: {{costAmount}}",
  },
  disabilityResult: {
    "en-CA": "Disability insurance:",
    "fr-CA": "Assurance invalidité:",
  },
  disabilityResultAmt: {
    "en-CA": "Amount: {{disabilityAmount}} per month",
    "fr-CA": "Montant: {{disabilityAmount}} par mois",
  },
  disabilityResultCost: {
    "en-CA":
      "Monthly cost: Consider Synergy, Affinity solutions or group benefits",
    "fr-CA":
      "Coût mensuel: Songez Synergie, solutions d’affinité ou d’assurance collective",
  },
  ciResult: {
    "en-CA": "Critical illness insurance: Lifecheque, Term-10 with ROPD",
    "fr-CA": "Assurance maladies graves: Chèque-vie, T-10 avec RPD",
  },
  ciResultAmt: {
    "en-CA": "Amount: {{ciAmount}}",
    "fr-CA": "Montant: {{ciAmount}}",
  },
  ciResultCost: {
    "en-CA": "Monthly cost: {{costAmount}}",
    "fr-CA": "Coût mensuel: {{costAmount}}",
  },
};
export default LifeEmailContent;
