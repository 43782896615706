import {
  FETCH_ENV_REQUEST,
  FETCH_ENV_FAILURE,
  FETCH_ENV_SUCCESS
} from "./Types";

const initialState = {
  isLoading: false,
  currentEnv: "",
  error: "",
};

const EnvironmentReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_ENV_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_ENV_SUCCESS:
      return {
        isLoading: false,
        currentEnv: payload
      };

    case FETCH_ENV_FAILURE:
      return {
        isLoading: false,
        currentEnv: ""
      };

    default:
      return state;
  }
};

export default EnvironmentReducer;
