import { eUDMType } from "../helpers/udmConstants";

export const generateUdm = (
    illustrationObj,
    type = eUDMType.summary
) => {
    return {
        type,
        illustration: illustrationObj
    };
};