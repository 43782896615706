import React from "react";
import { useLang, LANG, rem, Stack, BREAKPOINTS, Card } from "@manulife/mux";
import { Manulife, ManulifeFr } from "@manulife/mux-logos";
import useWindowSize from "../customHooks/useWindowSize";
import NumberConstants from "../assets/appStyledComponents/numberConstant.json";
import BannerImage from "../assets/images/InsureRightBanner.jpg";
import Calculater from "../assets/images/Calculater.png";

import {
  PageHeadding,
  ScreenHeadding,
  PageParagraphHeadding,
  NormalText,
} from "../assets/appStyledComponents";
import HomeConstants from "../assets/homeConstants.json";
import { useNavigate } from "react-router-dom";

function Home() {
  const { num30, num290, num400, num2, num32, num40, num80, num64, num20, num192, num24, num144 } = NumberConstants;
  const { width: windowWidth } = useWindowSize();
  const selectedLang = useLang();
  const styles = {
    logo: {
      height: rem(num30),
      margin: `${rem(num30)} ${rem("0")}`,
    },
  };
  const navigate = useNavigate();

  const cardStyle = windowWidth > BREAKPOINTS.MAX_1024 ? { width: `${rem(num400)}`, height: `${rem(num290)}` } : { width: `100%` };

  const onHover = (e) => {
    e.currentTarget.style.boxShadow = '0 0 20px rgba(0, 0, 0, 0.15)';
  };
  const offHover = (e) => {
    e.currentTarget.style.boxShadow = '';
  };

  return (
    <>
      <Stack
        align="flex-start"
        style={{
          backgroundImage: `url(${BannerImage}`,
          alignSelf: `stretch`,
          backgroundPosition: `center`,
          backgroundRepeat: `lightgray 50% /no-repeat`,
          backgroundSize: `cover`,
        }}
      >
        {windowWidth > BREAKPOINTS.MAX_1024 && (
          <Stack
            align="flex-start"
            style={{
              padding: `${rem(0)} ${rem(num30)}`,
            }}
          >
            <Stack
              direction="row"
              justify="flex-start"
              style={{ width: "100%" }}
            >
              <>
                {selectedLang === LANG.EN_CA ? (
                  <Manulife.WORDMARK_BLACK
                    title="Manulife"
                    style={styles.logo}
                  />
                ) : (
                  <ManulifeFr.WORDMARK_BLACK
                    title="Manuvie"
                    style={styles.logo}
                  />
                )}
              </>
            </Stack>
          </Stack>
        )}
        <PageHeadding
          role="heading"
          aria-level="1"
          style={windowWidth > BREAKPOINTS.MAX_1024 ? { padding: `${rem(0)} ${rem(0)}  ${rem(0)} ${rem(num64)}` } : { padding: `${rem(num32)} ${rem(num24)} ${rem(0)} ${rem(num24)}`, fontSize: rem(num30) }}
        >
          {HomeConstants.pageHeading[`${selectedLang}`]}
        </PageHeadding>
        <PageParagraphHeadding
          role="paragraph"
          style={windowWidth > BREAKPOINTS.MAX_1024 ? { padding: `${rem(0)} ${rem(num64)}  ${rem(num192)}` } : { padding: `${rem(0)} ${rem(num144)} ${rem(num64)} ${rem(num32)}` }}
        >
          {HomeConstants.pageParagraph[`${selectedLang}`]}
        </PageParagraphHeadding>
      </Stack>
      <Stack
        align="flex-start"
        style={{
          padding: `${rem(num40)} ${rem(num64)}  ${rem(num80)} ${rem(num64)}`,
        }}
      >
        <Stack style={{
          padding: `${rem(0)} ${rem(0)}  ${rem(num20)} ${rem(0)}`,
        }}>
          <ScreenHeadding role="homeSectionHeading" aria-level={num2}>
            {HomeConstants.screenHeading[`${selectedLang}`]}
          </ScreenHeadding>
        </Stack>
        <Stack style={{
          padding: `${rem(0)} ${rem(0)}  ${rem(num30)} ${rem(0)}`,
        }}>
          <NormalText>{HomeConstants.screenText[`${selectedLang}`]}</NormalText>
        </Stack>
        <Stack direction="row" justify="space-between" style={{ width: '100%' }}>
          <Stack onClick={() => navigate("/about")} style={{ cursor: `pointer` }} onMouseOver={onHover} onMouseOut={offHover}>
            <Card customStyle={{ cardStyle }}>
              <Card.Image src={Calculater} alt="Calculator" />
              <Card.Header headerLevel={Card.HEADER_LEVEL.H4}>
                {HomeConstants.calculaterHeader[`${selectedLang}`]}
              </Card.Header>
              <Card.Content>
                {HomeConstants.calculaterText[`${selectedLang}`]}
              </Card.Content>
            </Card>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default Home;
