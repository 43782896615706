import React from 'react'
import { IrTabSecWrapper, secComponents } from '../IrCommon';
import { useLang } from '@manulife/mux';
import DisabilityInputs from './IrDisabilityInputs.json'
import { addCurrencySign, getOnlyNumbers } from '../../services/helpers/commonHelpers';
import { eForWhom } from '../../services/helpers/udmConstants';
import { useFormContext } from 'react-hook-form';

function IrMonthlyExpenses({ name1, name2, selectedIrInsuranceFor, tabName }) {

    const selectedLang = useLang();
    const { getValues, setValue } = useFormContext();

    const SecName = "IrMonthlyExpenses";
    const disabilityInsurance = "IrDisabilityInsuranceOrOtherIncome";
    const currEmployment = "IrCurrentEmploymentIncome";

    const commonProps = {
        name1,
        name2,
        selectedIrInsuranceFor,
        tabName,
        secName: SecName,
    }

    const {
        monthlyExpense: {
            secTitle,
            inputComponents: {
                mortageOrRent,
                deptPayments,
                savingContributions,
                otherExpenses
            }
        },
        insuranceOrOtherIncome: {
            inputComponents: {
                nonTaxableBenefit,
                taxableBenefit,
                avgTaxRate,
                otherMonthlyIncome,
            }
        },
    } = DisabilityInputs;

    const calculateTotal = (forWhom) => {
        const mortage = Number(getOnlyNumbers(getValues(`${tabName}.${SecName}.${forWhom}.${mortageOrRent.name}`))) || 0;
        const dept = Number(getOnlyNumbers(getValues(`${tabName}.${SecName}.${forWhom}.${deptPayments.name}`))) || 0;
        const savings = Number(getOnlyNumbers(getValues(`${tabName}.${SecName}.${forWhom}.${savingContributions.name}`))) || 0;
        const other = Number(getOnlyNumbers(getValues(`${tabName}.${SecName}.${forWhom}.${otherExpenses.name}`))) || 0;

        const nonTaxable = Number(getOnlyNumbers(getValues(`${tabName}.${disabilityInsurance}.${forWhom}.${nonTaxableBenefit.name}`))) || 0;
        const taxable = Number(getOnlyNumbers(getValues(`${tabName}.${disabilityInsurance}.${forWhom}.${taxableBenefit.name}`))) || 0;
        const taxRate = Number(getOnlyNumbers(getValues(`${tabName}.${disabilityInsurance}.${forWhom}.${avgTaxRate.name}`))) || 0;
        const otherMonthly = Number(getOnlyNumbers(getValues(`${tabName}.${disabilityInsurance}.${forWhom}.${otherMonthlyIncome.name}`))) || 0;

        const totalAmount = mortage + dept + savings + other - nonTaxable - (taxable * (1 - (taxRate / 100))) - otherMonthly

        setValue(`${tabName}.${SecName}.${forWhom}.totalAmount`, addCurrencySign(totalAmount > 0 ? totalAmount : 0, selectedLang));
    }

    const hideYourInput = `${tabName}.${currEmployment}.${eForWhom.you}.hideInput`;
    const hidePartnerInput = `${tabName}.${currEmployment}.${eForWhom.partner}.hideInput`;

    const ComponentList = [
        {
            compName: "IrTabTblHeader",
            compProps: {}
        },
        {
            compName: "IrTabTblRowAmount",
            compProps: {
                rowLabel: mortageOrRent.label[selectedLang],
                keyName: mortageOrRent.name,
                maxAmount: mortageOrRent.maxValue,
                hideYourInput,
                hidePartnerInput,
                onYouBlur: () => calculateTotal(eForWhom.you),
                onPartnerBlur: () => calculateTotal(eForWhom.partner)
            }
        },
        {
            compName: "IrTabTblRowAmount",
            compProps: {
                rowLabel: deptPayments.label[selectedLang],
                keyName: deptPayments.name,
                maxAmount: deptPayments.maxValue,
                hideYourInput,
                hidePartnerInput,
                onYouBlur: () => calculateTotal(eForWhom.you),
                onPartnerBlur: () => calculateTotal(eForWhom.partner)
            }
        },
        {
            compName: "IrTabTblRowAmount",
            compProps: {
                rowLabel: savingContributions.label[selectedLang],
                keyName: savingContributions.name,
                maxAmount: savingContributions.maxValue,
                hideYourInput,
                hidePartnerInput,
                onYouBlur: () => calculateTotal(eForWhom.you),
                onPartnerBlur: () => calculateTotal(eForWhom.partner)
            }
        },
        {
            compName: "IrTabTblRowAmount",
            compProps: {
                rowLabel: otherExpenses.label[selectedLang],
                keyName: otherExpenses.name,
                maxAmount: otherExpenses.maxValue,
                tooltipText: otherExpenses.toolTip[selectedLang],
                tooltipAriaLabel: otherExpenses.tooltipAriaLabel[selectedLang],
                hideYourInput,
                hidePartnerInput,
                onYouBlur: () => calculateTotal(eForWhom.you),
                onPartnerBlur: () => calculateTotal(eForWhom.partner)
            }
        },
    ];

    return (
        <IrTabSecWrapper secTitle={secTitle[selectedLang]}>
            {
                ComponentList.map((componentOdj, index) => {
                    const { compName, compProps } = componentOdj;
                    return React.createElement(secComponents[compName], {
                        key: `${Date.now()}${index}`,
                        ...commonProps,
                        ...compProps
                    })
                })
            }
        </IrTabSecWrapper>
    )
}

export default IrMonthlyExpenses