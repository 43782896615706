import React, { useEffect, useState } from "react";
import { MuxProvider, LANG, Layout, Stack, BREAKPOINTS } from "@manulife/mux";
import IrAppHeader from "./components/IrAppHeader/IrAppHeader";
import "./App.css";
import IrFooter from "./components/IrFooter/IrFooter";
import IrSideNav from "./components/IrSideNav/IrSideNav";
import CONSTANTS from "./assets/constants.json";
import IrPageFooter from "./components/IrPageFooter/IrPageFooter";
import useWindowSize from "./customHooks/useWindowSize";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "./AppRoutes/AppRouter";
import { Provider } from "react-redux";
import IrStore from "./appRedux/IrStore";


const App = () => {
  const { width: windowWidth } = useWindowSize();
  const domain = window.location.href;
  const [selectedLang, setSelectedLang] = useState({ lang: LANG.FR_CA });

  useEffect(() => {
    window.history.scrollRestoration = "manual";
    return () => true;
  }, []);

  useEffect(() => {
    if (domain && domain.includes(CONSTANTS.domainString)) {
      setSelectedLang({ lang: LANG.FR_CA });
    } else {
      setSelectedLang({ lang: LANG.EN_CA });
    }
    return () => true;
  }, [domain]);

  return (
    <Provider store={IrStore}>
      <BrowserRouter>
        <MuxProvider langProvider={selectedLang}>
          <Layout
            header={<IrAppHeader setSelectedLang={setSelectedLang} />}
            footer={<IrFooter />}
            sideNav={<IrSideNav setSelectedLang={setSelectedLang} />}
            className={windowWidth > BREAKPOINTS.MAX_1024 ? "layout-container" : "layout-phone"}
          >
            <Stack>
              <AppRouter />
            </Stack>

            <IrPageFooter />
          </Layout>
        </MuxProvider>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
