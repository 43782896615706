import { eProduct, getCoverageIdPrefix, getProductName } from "../helpers/udmConstants";
import { generateCoverage } from "./coverageGenerator";

export const generatePolicy = (
  product,
  coverageArray
) => {
  return {
    product,
    coverage: coverageArray
  }
};

export const generatingPolicy = (
  paramObj,
  asset,
  expense,
  income
) => {
  const {
    partyId,
    product,
    costType
  } = paramObj;

  let coverageIdPrefix = getCoverageIdPrefix(product);
  let coverageArray = [];
  let coverageArrayLength = coverageArray.length;

  switch (product) {
    case eProduct.LC:
      coverageArray.push({
        ...generateCoverage(
          `${coverageIdPrefix}${coverageArrayLength++}`,
          partyId,
          costType,
          asset,
          expense,
          income
        ),
        "premiumDuration": "Expiry",
        "ropType": [
          "D"
        ]
      });
      break;
    case eProduct.VS:
      coverageArray.push({
        ...generateCoverage(
          `${coverageIdPrefix}${coverageArrayLength++}`,
          partyId,
          getProductName(product),
          asset,
          expense,
          income
        )
      });
      break;
    default:
      coverageArray.push(generateCoverage(
        `${coverageIdPrefix}${coverageArrayLength++}`,
        partyId,
        costType,
        asset,
        expense,
        income
      ));
  }
  // console.log("coverageArray => ", coverageArray);
  const policyObj = generatePolicy(
    getProductName(product),
    // getProductFamily(product),
    coverageArray
  );
  // console.log("policyObj => ", policyObj);
  return policyObj;
};