import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Home, IrAboutYou, IrCalculatorPage } from '../screens';

function AppRouter() {
    return (
        <Routes>
            <Route path='/' element={<Home />} />
            <Route path='about' element={<IrAboutYou />} />
            <Route path='calculator' element={<IrCalculatorPage />} />
        </Routes>
    )
};

export default AppRouter;