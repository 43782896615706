import React from 'react';
import { BREAKPOINTS, Stack, rem, useLang } from '@manulife/mux';
import useWindowSize from '../../customHooks/useWindowSize';
import LABELS from '../../assets/labels';
import { IrAge, IrAnnualIncome, IrEmploymentStatus, IrName, IrSex, IrSmokingStatus } from './Fields';
import { eForWhom } from '../../services/helpers/udmConstants';
import { SectionLabel } from '../../assets/appStyledComponents';
import NumberConstants from '../../assets/appStyledComponents/numberConstant.json';

function IrAboutYouInputs({ forWhom }) {
    const selectedLang = useLang();
    const { width: windowWidth } = useWindowSize();
    const { num20 } = NumberConstants;
    return (
        <Stack
            data-testid="IrAboutYouAllInputs"
            align="flex-start"
            gap={`${rem(num20)}`}
            style={{
                width: windowWidth > BREAKPOINTS.MAX_1280 ? LABELS.colWidth : "100%"
            }}
        >
            <SectionLabel>{LABELS[forWhom === eForWhom.you ? "youSectionHeading" : "partnerSectionHeading"][selectedLang]}</SectionLabel>
            <IrName detailFor={forWhom} />
            <IrAge detailFor={forWhom} />
            <IrSex detailFor={forWhom} />
            <IrSmokingStatus detailFor={forWhom} />
            <IrEmploymentStatus detailFor={forWhom} />
            <IrAnnualIncome detailFor={forWhom} />
        </Stack>
    )
}

export default IrAboutYouInputs;