import { typography, rem,colors } from "@manulife/mux";
import styled from "styled-components";
import NumberConstants from "./numberConstant.json";

const { num24, num16, num44, num54, num22, num18, num26, num32 } = NumberConstants;


const PageHeadding = styled.h1`
    font-family: ${typography.fontFamily};
    font-style: ${typography.fontStyle};
    font-weight: ${typography.fontWeightLight};
    margin: ${rem(0)};

    font-size: ${rem(num44)};
    line-height: ${rem(num54)};
`;

const SectionHeadding = styled.h2`
    font-family: ${typography.fontFamily};
    font-style: ${typography.fontStyle};
    font-weight: ${typography.fontWeightLight};
    margin: ${rem(0)};

    font-size: ${rem(num32)};
    line-height: ${rem(num44)};
`;

const SectionLabel = styled.h3`
    font-family: ${typography.fontFamily};
    font-style: ${typography.fontStyle};
    font-weight: ${typography.fontWeightDemibold};
    margin: ${rem(0)};
    font-size: ${rem(num22)};
    line-height: ${rem(num32)};
`;

const InsuranceAmt = styled.h3`
    font-family: ${typography.fontFamily};
    font-style: ${typography.fontStyle};
    font-weight: ${typography.fontWeightDemibold};
    margin: ${rem(0)};
    font-size: ${rem(num22)};
    line-height: ${rem(num32)};
    color: ${colors.light_1_navy}
`;

const ScreenHeadding = styled.h2`
    font-family: ${typography.fontFamily};
    font-style: ${typography.fontStyle};
    font-weight: ${typography.fontWeightLight};

    font-size:${rem(num32)};
    line-height: ${rem(num44)};
`;

const ChartIndexLabel = styled.h2`
    font-family: ${typography.fontFamily};
    font-style: ${typography.fontStyle};
    font-weight: ${typography.fontWeightDemibold};
    font-size: ${rem(num18)};
    line-height: ${rem(num26)};
`;

const ModalHeader = styled.h2`
    font-family: ${typography.fontFamily};
    font-style: ${typography.fontStyle};
    font-weight: ${typography.fontWeightLight};
    font-size: ${rem(num32)};
    line-height: ${rem(num44)};
`;

const BoldChartHeading = styled.h2`
    font-family: ${typography.fontFamily};
    font-style: ${typography.fontStyle};
    font-weight: ${typography.fontWeightDemibold};
    font-size: ${rem(num16)};
    line-height: ${rem(num24)};
    text-align: center;
`;

/* const SectionHeadding = styled.h1`
    font-family: ${typography.fontFamily};
    font-style: ${typography.fontStyle};
    font-weight: ${typography.fontWeightLight};

    font-size:  ${rem(num2)};
    line-height:  ${rem(numD275)};
`; */


export {
    PageHeadding,
    SectionHeadding,
    SectionLabel,
    ScreenHeadding,
    ChartIndexLabel,
    BoldChartHeading,
    ModalHeader,
    InsuranceAmt
};

/***
 * 
 * Font Family
 * -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
 * * * typography.fontFamily for Manulife JH Sans
 * * * typography.fontFamilySerif for Manulife JH Serif
 * -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
 * 
 * Font Size
 * -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
 * * * typography.fontSize for 1rem
 * * * typography.fontSizeSmall for 0.875rem
 * -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
 * 
 * Font Style
 * -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
 * * * typography.fontStyle for normal
 * * * typography.fontStyleItalic for italic
 * -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
 * 
 * Font Weight
 * -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
 * * * typography.fontWeightLight for 300
 * * * typography.fontWeightNormal for 400
 * * * typography.fontWeightDemibold for 600
 * * * typography.fontWeightBold for 700
 * -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
 * 
 */