import React from 'react';
import { BREAKPOINTS, Stack, rem } from '@manulife/mux';
import { NormalText, SectionLabel } from '../../assets/appStyledComponents';
import useWindowSize from '../../customHooks/useWindowSize';
import NumberConstants from '../../assets/appStyledComponents/numberConstant.json';

function IrTabSecWrapper({ secTitle, secSubTitle, children }) {
    const { width: windowWidth } = useWindowSize();
    const { num12, num20, num24 } = NumberConstants;
    return (
        <Stack align="flex-start" gap={`${rem(num12)}`} style={{ alignSelf: "stretch" }}>
            <SectionLabel style={{
                padding: windowWidth > BREAKPOINTS.MAX_768 ? `${rem(0)}` : `${rem(0)} ${rem(num24)}`
            }}>{secTitle}</SectionLabel>
            <Stack align="flex-start" gap={`${rem(num20)}`} style={{ alignSelf: "stretch" }}>
                {secSubTitle && <NormalText style={{
                    padding: windowWidth > BREAKPOINTS.MAX_768 ? `${rem(0)}` : `${rem(0)} ${rem(num24)}`
                }}>{secSubTitle}</NormalText>}
                <Stack align="flex-start" gap={`${rem(0)}`} style={{ alignSelf: `stretch` }}>
                    {children}
                </Stack>
            </Stack >
        </Stack >
    )
}

export default IrTabSecWrapper;