export const IRC_SET_INITIAL_STATE = 'IRC_SET_INITIAL_STATE';
export const IRC_SET_STEP_1 = 'IRC_SET_STEP_1';
export const IRC_SET_STEP_2 = 'IRC_SET_STEP_2';

export const RESET_STEP_2_RESULT = 'RESET_STEP_2_RESULT';

export const CURR_TAB = 'CURR_TAB';

export const FETCH_REPORT_REQUEST = 'FETCH_REPORT_REQUEST';
export const FETCH_REPORT_SUCCESS = 'FETCH_REPORT_SUCCESS';
export const FETCH_REPORT_FAILURE = 'FETCH_REPORT_FAILURE';

export const DOWNLOAD_REPORT_REQUEST = 'DOWNLOAD_REPORT_REQUEST';