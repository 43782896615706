import { getAmountNumbers } from "../helpers/commonHelpers";

export const generateParty = (
    partyId,
    age,
    gender,
    healthstyle,
    annualIncome,
    employmentStatus,
    name = ""
) => {
    return {
        partyId,
        name,
        annualIncome: getAmountNumbers(annualIncome),
        employmentStatus,
        age,
        gender,
        healthstyle
    };
};