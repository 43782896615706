const downloadPDF = (Base64str) => {
    let byteChars = window.atob(Base64str);
    let dataArray = new Array(byteChars.length);
    for (let index = 0; index < byteChars.length; index++) {
        dataArray[index] = byteChars.charCodeAt(index);
    }
    let byteArray = new Uint8Array(dataArray);
    let file = new Blob([byteArray], { type: "application/pdf" });
    let fileURL = URL.createObjectURL(file);
    window.open(fileURL);
}

export {
    downloadPDF
}