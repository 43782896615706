import React from 'react';
import { ButtonSelector, useLang } from '@manulife/mux';
import { Controller, useFormContext } from 'react-hook-form';
import LABELS from '../../../assets/labels';
import NumberConstants from '../../../assets/appStyledComponents/numberConstant.json';

function IrSmokingStatus({ detailFor }) {
    const selectedLang = useLang();
    const { control } = useFormContext(); // retrieve all hook methods
    const { num300 } = NumberConstants;
    return (
        <Controller
            control={control}
            name={`${detailFor}.irSmokingStatus`}
            rules={{
                required: LABELS.errors.required.irSmokingStatus[detailFor][selectedLang]
            }}
            render={({
                field: { onChange, onBlur, value, name },
                fieldState: { error },
            }) => (
                <ButtonSelector
                    id={name}
                    label={LABELS.question5[selectedLang][detailFor].question}
                    ariaLabel={LABELS.question5[selectedLang][detailFor].ariaLabel}
                    ariaRequired={true}
                    values={LABELS.question5[selectedLang].options}
                    verticalStackingBreakPoint={num300}
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    errors={error && [{ id: `error-${name}`, isError: true, msg: error.message }]}
                />
            )}
        />
    )
}

export default IrSmokingStatus