import React, { useCallback, useEffect } from 'react'
import NumberConstants from '../../assets/appStyledComponents/numberConstant.json';
import { IrTabSecWrapper, secComponents } from '../IrCommon';
import LifeTabContent from './IrLifeInputs.json';
import { useLang } from '@manulife/mux';
import { eForWhom } from '../../services/helpers/udmConstants';
import { useFormContext } from 'react-hook-form';
import { addCurrencySign, getAmountNumbers } from '../../services/helpers/commonHelpers';

function IrAssetsAndExistingInsurance({ name1, name2, selectedIrInsuranceFor, tabName }) {

    const selectedLang = useLang();

    const { getValues, setValue } = useFormContext();

    const {
        assentsAndInsurance: {
            secTitle,
            secSubTitle,
            inputComponents: {
                savings,
                registeredInvestments,
                nonRegisteredInvestments,
                taxFreeSavings,
                principalResidence,
                otherAssets,
                existingLifeInsurance,
                cppDeathBenefit,
                totalAssets
            }
        }
    } = LifeTabContent;

    const SecName = "IrAssetsAndExistingInsurance";

    const commonProps = {
        name1,
        name2,
        selectedIrInsuranceFor,
        tabName,
        secName: SecName,
    }

    const { num9999999 } = NumberConstants;

    const AssetsAndExistingInsuranceComponentList = [
        {
            compName: "IrTabTblHeader",
            compProps: {}
        },
        {
            compName: "IrTabTblRowAmount",
            compProps: {
                rowLabel: savings.label[selectedLang],
                tooltipText: savings.toolTip[selectedLang],
                tooltipAriaLabel: savings.tooltipAriaLabel[selectedLang],
                keyName: savings.keyName,
                maxAmount: num9999999,
                isBlurUpdateNext: true,
                onYouBlur: () => doSumOfAssets(eForWhom.you),
                onPartnerBlur: () => doSumOfAssets(eForWhom.partner)
            }
        },
        {
            compName: "IrTabTblRowAmount",
            compProps: {
                rowLabel: registeredInvestments.label[selectedLang],
                tooltipText: registeredInvestments.toolTip[selectedLang],
                tooltipAriaLabel: registeredInvestments.tooltipAriaLabel[selectedLang],
                keyName: registeredInvestments.keyName,
                maxAmount: num9999999,
                onYouBlur: () => doSumOfAssets(eForWhom.you),
                onPartnerBlur: () => doSumOfAssets(eForWhom.partner)
            }
        },
        {
            compName: "IrTabTblRowAmount",
            compProps: {
                rowLabel: nonRegisteredInvestments.label[selectedLang],
                tooltipText: nonRegisteredInvestments.toolTip[selectedLang],
                tooltipAriaLabel: nonRegisteredInvestments.tooltipAriaLabel[selectedLang],
                keyName: nonRegisteredInvestments.keyName,
                maxAmount: num9999999,
                onYouBlur: () => doSumOfAssets(eForWhom.you),
                onPartnerBlur: () => doSumOfAssets(eForWhom.partner)
            }
        },
        {
            compName: "IrTabTblRowAmount",
            compProps: {
                rowLabel: taxFreeSavings.label[selectedLang],
                tooltipText: taxFreeSavings.toolTip[selectedLang],
                tooltipAriaLabel: taxFreeSavings.tooltipAriaLabel[selectedLang],
                keyName: taxFreeSavings.keyName,
                maxAmount: num9999999,
                onYouBlur: () => doSumOfAssets(eForWhom.you),
                onPartnerBlur: () => doSumOfAssets(eForWhom.partner)
            }
        },
        {
            compName: "IrTabTblRowAmount",
            compProps: {
                rowLabel: principalResidence.label[selectedLang],
                tooltipText: principalResidence.toolTip[selectedLang],
                tooltipAriaLabel: principalResidence.tooltipAriaLabel[selectedLang],
                keyName: principalResidence.keyName,
                maxAmount: num9999999,
                onYouBlur: () => doSumOfAssets(eForWhom.you),
                onPartnerBlur: () => doSumOfAssets(eForWhom.partner)
            }
        },
        {
            compName: "IrTabTblRowAmount",
            compProps: {
                rowLabel: otherAssets.label[selectedLang],
                tooltipText: otherAssets.toolTip[selectedLang],
                tooltipAriaLabel: otherAssets.tooltipAriaLabel[selectedLang],
                keyName: otherAssets.keyName,
                maxAmount: num9999999,
                onYouBlur: () => doSumOfAssets(eForWhom.you),
                onPartnerBlur: () => doSumOfAssets(eForWhom.partner)
            }
        },
        {
            compName: "IrTabTblRowAmount",
            compProps: {
                rowLabel: existingLifeInsurance.label[selectedLang],
                tooltipText: existingLifeInsurance.toolTip[selectedLang],
                tooltipAriaLabel: existingLifeInsurance.tooltipAriaLabel[selectedLang],
                keyName: existingLifeInsurance.keyName,
                maxAmount: num9999999,
                onYouBlur: () => doSumOfAssets(eForWhom.you),
                onPartnerBlur: () => doSumOfAssets(eForWhom.partner)
            }
        },
        {
            compName: "IrTabTblRowAmount",
            compProps: {
                rowLabel: cppDeathBenefit.label[selectedLang],
                tooltipText: cppDeathBenefit.toolTip[selectedLang],
                tooltipAriaLabel: cppDeathBenefit.tooltipAriaLabel[selectedLang],
                keyName: cppDeathBenefit.keyName,
                maxAmount: num9999999,
                isBottomLine: false,
                onYouBlur: () => doSumOfAssets(eForWhom.you),
                onPartnerBlur: () => doSumOfAssets(eForWhom.partner)
            }
        },
        {
            compName: "IrTabTblRowTotal",
            compProps: {
                rowLabel: totalAssets.label[selectedLang],
                // value1: totals[eForWhom.you],
                // value2: totals[eForWhom.partner],
                isKeyAsValue: true,
                value1: `${tabName}.${SecName}.${eForWhom.you}.${totalAssets.keyName}`,
                value2: `${tabName}.${SecName}.${eForWhom.partner}.${totalAssets.keyName}`,
                isTopLine: true,
                isBottomLine: false
            }
        }
    ];

    const doSumOfAssets = useCallback((forWhom) => {
        const allValues = getValues([
            `${tabName}.${SecName}.${forWhom}.${savings.keyName}`,
            `${tabName}.${SecName}.${forWhom}.${registeredInvestments.keyName}`,
            `${tabName}.${SecName}.${forWhom}.${nonRegisteredInvestments.keyName}`,
            `${tabName}.${SecName}.${forWhom}.${taxFreeSavings.keyName}`,
            `${tabName}.${SecName}.${forWhom}.${principalResidence.keyName}`,
            `${tabName}.${SecName}.${forWhom}.${otherAssets.keyName}`,
            `${tabName}.${SecName}.${forWhom}.${existingLifeInsurance.keyName}`,
            `${tabName}.${SecName}.${forWhom}.${cppDeathBenefit.keyName}`
        ]);
        if (Array.isArray(allValues)) {
            const currTotal = allValues.reduce((total, amt) => {
                return total + Number(getAmountNumbers(amt))
            }, 0);
            setValue(`${tabName}.${SecName}.${forWhom}.${totalAssets.keyName}`, addCurrencySign(currTotal, selectedLang));
            if (forWhom === eForWhom.you) {
                doSumOfAssets(eForWhom.partner);
            }
        }
    }, [cppDeathBenefit.keyName, existingLifeInsurance.keyName, getValues, nonRegisteredInvestments.keyName, otherAssets.keyName, principalResidence.keyName, registeredInvestments.keyName, savings.keyName, selectedLang, setValue, tabName, taxFreeSavings.keyName, totalAssets.keyName]);

    useEffect(() => {
        doSumOfAssets(eForWhom.you);
    }, [doSumOfAssets]);

    return (
        <IrTabSecWrapper secTitle={secTitle[selectedLang]} secSubTitle={secSubTitle[selectedLang]}>
            {
                AssetsAndExistingInsuranceComponentList.map((componentOdj, index) => {
                    const { compName, compProps } = componentOdj;
                    return React.createElement(secComponents[compName], {
                        key: `${Date.now()}${index}`,
                        ...commonProps,
                        ...compProps
                    })
                })
            }
        </IrTabSecWrapper>
    )
}

export default React.memo(IrAssetsAndExistingInsurance);