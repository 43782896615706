import React from 'react';
import { BREAKPOINTS, Stack, rem, useLang, MessageCard, VIEWPORTS, colors } from "@manulife/mux";
import { NormalText, PageHeadding } from '../../assets/appStyledComponents';
import LABELS from '../../assets/labels';
import NumberConstants from '../../assets/appStyledComponents/numberConstant.json';
import useWindowSize from '../../customHooks/useWindowSize';
import { useSelector } from 'react-redux';
import { ButtonXIcon } from '@manulife/mux-cds-icons';
import Labels from "../../assets/labels";

function IrHeading() {
    const { width: windowWidth } = useWindowSize();
    const isError = useSelector(
        (state) => state.errorReducer?.isError
    );
    const selectedLang = useLang();
    const { num20, num24, num40 } = NumberConstants;
    return (
        <Stack align="flex-start" gap={`${rem(num20)}`} style={{
            maxWidth: `${rem(BREAKPOINTS.DESKTOP_MED)}`,
            padding: windowWidth > BREAKPOINTS.MAX_768 ? `${rem(0)}` : `${rem(0)} ${rem(num24)}`
        }}>
            {isError && <MessageCard
                className={windowWidth < VIEWPORTS.XS ? "rtgErrorMessageCardXs" : "rtgErrorMessageCard"}
                variant={MessageCard.VARIANT.PRIMARY_ALERT}
                hasCloseButton
                closeIcon={<ButtonXIcon color={colors.dark_navy} fontSize={rem(num40)} />}
            >
                {Labels.errors.apiFailed[selectedLang]}
            </MessageCard>}
            <PageHeadding role="heading" aria-level="1" >{LABELS.irCalculatorPageHeading[selectedLang]}</PageHeadding>
            <NormalText>{LABELS.irCalculatorDescription[selectedLang]}</NormalText>
        </Stack>
    )
}

export default IrHeading