import React, { useEffect } from 'react';

import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { TextInput, useLang, BREAKPOINTS } from '@manulife/mux';
import { getAmountNumbers } from '../../../services/helpers/commonHelpers';
import NumberConstants from "../../../assets/appStyledComponents/numberConstant.json";
import LABELS from '../../../assets/labels';
import useWindowSize from '../../../customHooks/useWindowSize';

const { num100 } = NumberConstants;

function IrNumber({ fieldName, fieldLabel, fieldAriaLabel, maxNumber = num100, handleCommonBlur = () => null, hideInput }) {
    const { width: windowWidth } = useWindowSize();
    const selectedLang = useLang();
    const { control, setValue, getValues } = useFormContext(); // retrieve all hook methods

    const isHide = useWatch({
        control,
        name: `${hideInput}`
    })

    useEffect(() => {
        setValue(`${fieldName}`, getAmountNumbers(getValues(`${fieldName}`)));
        return () => null
    }, [setValue, getValues, fieldName]);

    const handleChange = (newValue, event, onChange) => {
        /* onChange */
        const amountInNumber = getAmountNumbers(newValue);
        if (amountInNumber > maxNumber) {
            onChange(getAmountNumbers(maxNumber));
        } else {
            onChange(amountInNumber);
        }
    };

    const handleAmountBlur = (newValue, onBlur) => {
        handleCommonBlur(newValue);
        onBlur();
    }

    const normalStyle = {
        width: "100%",
    }

    return (
        <>
            {!isHide && <Controller
                control={control}
                name={`${fieldName}`}
                rules={{
                    required: LABELS.errors.required[selectedLang]
                }}
                render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { error },
                }) => (
                    <TextInput
                        label={windowWidth > BREAKPOINTS.MAX_768 ? "" : fieldLabel}
                        ariaLabel={fieldAriaLabel}
                        ariaRequired={true}
                        data-testid={name}
                        placeholder="0"
                        customStyle={{
                            rootStyle: normalStyle,
                        }}
                        ref={ref}
                        value={String(value)}
                        onBlur={(newValue) => handleAmountBlur(newValue, onBlur)}
                        onChange={(newValue, event) => handleChange(newValue, event, onChange)}
                        errors={error && [{ id: `error-${name}`, isError: true, msg: error.message }]}
                    />
                )}
            />}
        </>
    )
};

export default IrNumber;