import {
  IRC_SET_INITIAL_STATE,
  IRC_SET_STEP_1,
  IRC_SET_STEP_2,
  RESET_STEP_2_RESULT,
  FETCH_REPORT_REQUEST,
  DOWNLOAD_REPORT_REQUEST,
  FETCH_REPORT_SUCCESS,
  FETCH_REPORT_FAILURE,
  CURR_TAB,
} from "./Types";

import constants from "../../assets/constants.json";
import { callApi } from "../../services/http/api-service";
import {
  generateIllustration,
  generateParty,
  generateUdm,
} from "../../services/udmGenerator";
import { replaceAllKeysAmtToNum } from "../../services/helpers/commonHelpers";
import {
  eCalculatorTab,
  eCostType,
  eIncluded,
  eProduct,
  eUDMType,
  getLang,
  getName,
} from "../../services/helpers/udmConstants";
import { generatingPolicy } from "../../services/udmGenerator/policyGenerator";
import { updateChecks } from "../IrResultCheckbox/Actions";
import { showError } from "../error/Actions";

export const setInitialState = () => {
  return {
    type: IRC_SET_INITIAL_STATE,
  };
};

export const setAboutYouData = (aboutYouData) => {
  return {
    type: IRC_SET_STEP_1,
    payload: aboutYouData,
  };
};

export const setCalculatorData = (calculatorData) => {
  return {
    type: IRC_SET_STEP_2,
    payload: calculatorData,
  };
};

export const resetCalculatorDataResult = () => {
  return {
    type: RESET_STEP_2_RESULT,
  };
};

export const setCurrTab = (currTab) => {
  return {
    type: CURR_TAB,
    payload: currTab,
  };
};

export const fetchReportRequest = () => {
  return {
    type: FETCH_REPORT_REQUEST,
  };
};

export const downloadReportRequestStart = () => {
  return {
    type: DOWNLOAD_REPORT_REQUEST,
  };
};

export const fetchReportSuccess = (ReportData) => {
  let data = ReportData;
  return {
    type: FETCH_REPORT_SUCCESS,
    payload: data,
  };
};

export const fetchReportFailure = (error) => {
  return {
    type: FETCH_REPORT_FAILURE,
    payload: error,
  };
};

export const fetchReport = (formData = {}, selectedLang, cb = () => null) => {
  const {
    life: {
      IrAssetsAndExistingInsurance: lifeAsset,
      IrDebtsAndExpenses: lifeExpense,
      IrIncome: lifeIncome,
    },
    disability: {
      IrCurrentEmploymentIncome: disabilityAsset,
      IrMonthlyExpenses: disabilityExpense,
      IrDisabilityInsuranceOrOtherIncome: disabilityIncome,
    },
    criticalIllness: {
      IrOffsetCosts: ciAsset,
      IrPayDebtsAndExpenses: ciExpense,
      IrReplaceIncome: ciIncome,
    },
  } = formData;
  return async (dispatch, getState) => {
    const currState = getState();
    // console.log("currState => ", currState);
    const {
      irResultCheckboxReducer,
      irCalculatorReducer: {
        currTab,
        data: {
          step1: {
            irInsuranceFor,
            irChildCount,
            irHaveMoney,
            you: {
              irName: youIrName,
              irAge: youIrAge,
              irSex: youIrSex,
              irSmokingStatus: youIrSmokingStatus,
              irAnnualIncome: youIrAnnualIncome,
              irEmploymentStatus: youIrEmploymentStatus,
            },
            partner: {
              irName: partnerIrName,
              irAge: partnerIrAge,
              irSex: partnerIrSex,
              /* THIS WILL REQUIRED FOR MULTIPLE POLICIES */
              irSmokingStatus: partnerIrSmokingStatus,
              irAnnualIncome: partnerIrAnnualIncome,
              irEmploymentStatus: partnerIrEmploymentStatus,
            },
          },
        },
      },
    } = currState;

    const partyArray = [
      {
        ...generateParty(
          `Life.1`,
          youIrAge,
          youIrSex,
          youIrSmokingStatus,
          youIrAnnualIncome,
          youIrEmploymentStatus,
          getName(youIrName, selectedLang)
        ),
      },
    ];

    const isHavePartner = irInsuranceFor === eIncluded.meAndPartner;

    if (isHavePartner) {
      partyArray.push({
        ...generateParty(
          `Life.2`,
          partnerIrAge,
          partnerIrSex,
          partnerIrSmokingStatus,
          partnerIrAnnualIncome,
          partnerIrEmploymentStatus,
          getName(partnerIrName, selectedLang)
        ),
      });
    }

    const getPolicyObj = (objType, prtId) => {
      let generatedPolicyObj;

      switch (objType) {
        case eCalculatorTab.life:
          generatedPolicyObj = generatingPolicy(
            {
              partyId: prtId,
              product: eProduct.FT,
              costType: eCostType.FT_FTV_T20
            },
            {
              ...replaceAllKeysAmtToNum(
                prtId === `Life.1` ? lifeAsset.you : lifeAsset.partner
              ),
            },
            {
              ...replaceAllKeysAmtToNum(
                prtId === `Life.1` ? lifeExpense.you : lifeExpense.partner
              ),
            },
            {
              ...replaceAllKeysAmtToNum(
                prtId === `Life.1` ? lifeIncome.you : lifeIncome.partner,
                ["capitalFund"]
              ),
            }
          );
          break;
        case eCalculatorTab.disability:
          generatedPolicyObj = generatingPolicy(
            {
              partyId: prtId,
              product: eProduct.VS,
              costType: eCostType.SYN_T10
            },
            {},
            {
              ...replaceAllKeysAmtToNum(
                prtId === `Life.1`
                  ? disabilityExpense.you
                  : disabilityExpense.partner
              ),
            },
            {
              ...replaceAllKeysAmtToNum(
                prtId === `Life.1`
                  ? {
                    ...disabilityAsset.you,
                    ...disabilityIncome.you,
                  }
                  : {
                    ...disabilityAsset.partner,
                    ...disabilityIncome.partner,
                  },
                ["capitalFundType"]
              ),
            }
          );
          break;
        case eCalculatorTab.criticalIllness:
          generatedPolicyObj = generatingPolicy(
            {
              partyId: prtId,
              product: eProduct.LC,
              costType: eCostType.LC_T10
            },
            {
              ...replaceAllKeysAmtToNum(
                prtId === `Life.1` ? ciAsset.you : ciAsset.partner
              ),
            },
            {
              ...replaceAllKeysAmtToNum(
                prtId === `Life.1` ? ciExpense.you : ciExpense.partner
              ),
            },
            {
              ...replaceAllKeysAmtToNum(
                prtId === `Life.1` ? ciIncome.you : ciIncome.partner,
                ["capitalFundType"]
              ),
            }
          );
          break;

        default:
          break;
      }

      return generatedPolicyObj;
    };

    const policyArray = [];

    partyArray.forEach(({ partyId }) => {
      if (!formData.isDownloadPDF) {
        policyArray.push(getPolicyObj(currTab, partyId));
      } else {
        for (const key in irResultCheckboxReducer) {
          if (
            Object.hasOwnProperty.call(irResultCheckboxReducer, key) &&
            irResultCheckboxReducer[key]
          ) {
            policyArray.push(getPolicyObj(key, partyId));
          }
        }
      }
    });

    const illustrationObj = generateIllustration(
      getLang(selectedLang),
      irChildCount,
      irHaveMoney,
      partyArray,
      policyArray
    );
    // console.log("illustrationObj => ", illustrationObj);
    const UDM = generateUdm(illustrationObj);
    // console.log("UDM => ", UDM);
    // return false;
    if (!formData.isDownloadPDF) {
      dispatch(setCalculatorData(formData));
      dispatch(fetchReportRequest());
    } else {
      UDM.type = eUDMType.report;
      dispatch(downloadReportRequestStart());
    }
    callApi(
      {
        httpMethod: constants.POST,
        apiEndPoint: `/apix/calculate`,
        body: UDM,
        reqHeader: {
          "X-CSRF-Token": document.cookie
            .split(";")
            .find((i) => i.includes("_csrf"))
            ?.split("=")[1],
        },
      },
      ({ response, status, errorMsg }) => {
        if (status) {
          dispatch(fetchReportSuccess(response, formData));
          if (formData.isDownloadPDF === true) {
            if (response.reportData) {
              cb(response.reportData);
            } else {
              dispatch(showError());
            }
          } else {
            dispatch(
              updateChecks({
                ...irResultCheckboxReducer,
                [currTab]: true,
              })
            );
          }
        } else {
          dispatch(fetchReportFailure(errorMsg));
        }
      },
      dispatch
    );
  };
};
