import React, { useEffect, useCallback } from 'react'
import { IrTabSecWrapper, secComponents } from '../IrCommon';
import { useLang } from '@manulife/mux';
import CriticalInputs from './IrCriticalIllnessInputs.json'
import { useFormContext } from 'react-hook-form';
import { addCurrencySign, getOnlyNumbers } from '../../services/helpers/commonHelpers';
import { setAboutYouData } from '../../appRedux/IrCalculator/Actions';
import { useDispatch } from 'react-redux';
import { eCalculatorTab, eForWhom, eIncluded } from '../../services/helpers/udmConstants';
import IrDisabilityInputs from '../IrDisabilityInputs/IrDisabilityInputs.json';

function IrReplaceIncome({ name1, name2, selectedIrInsuranceFor, tabName, storedAboutYouData }) {

    const selectedLang = useLang();
    const dispatch = useDispatch();
    const { getValues, setValue } = useFormContext();

    const SecName = "IrReplaceIncome";

    const commonProps = {
        name1,
        name2,
        selectedIrInsuranceFor,
        tabName,
        secName: SecName,
    }

    const {
        replaceIncome: {
            secTitle,
            secSubTitle,
            inputComponents: {
                replaceIncome1,
                replaceIncome2,
                replaceIncome3,
                replaceIncome4,
                months1,
                months2,
                months3,
                months4,
                subTotal1,
                subTotal2,
                subTotal3,
                subTotal4,
                total
            }
        }
    } = CriticalInputs

    const {
        currentIncome: {
            inputComponents: {
                employmentStatus
            }
        }
    } = IrDisabilityInputs;

    const setInitialValues = useCallback(() => {
        const yourIncome = Math.round(Number(getOnlyNumbers(storedAboutYouData.you.irAnnualIncome))) / 12 || 0;
        const partnerIncome = Math.round(Number(getOnlyNumbers(storedAboutYouData.partner.irAnnualIncome))) / 12 || 0;
        setValue(`${tabName}.${SecName}.${eForWhom.you}.${replaceIncome1.name}`, addCurrencySign(Math.round(yourIncome), selectedLang));
        setValue(`${tabName}.${SecName}.${eForWhom.you}.${replaceIncome2.name}`, addCurrencySign(Math.round(partnerIncome), selectedLang));
        setValue(`${tabName}.${SecName}.${eForWhom.partner}.${replaceIncome3.name}`, addCurrencySign(Math.round(partnerIncome), selectedLang));
        setValue(`${tabName}.${SecName}.${eForWhom.partner}.${replaceIncome4.name}`, addCurrencySign(Math.round(yourIncome), selectedLang));

        if (!getValues(`${tabName}.${SecName}.${eForWhom.you}.${subTotal1.name}`)) {
            setValue(`${tabName}.${SecName}.${eForWhom.you}.${subTotal1.name}`, addCurrencySign(0, selectedLang));
        }
        if (!getValues(`${tabName}.${SecName}.${eForWhom.you}.${subTotal2.name}`)) {
            setValue(`${tabName}.${SecName}.${eForWhom.you}.${subTotal2.name}`, addCurrencySign(0, selectedLang));
        }
        if (!getValues(`${tabName}.${SecName}.${eForWhom.partner}.${subTotal3.name}`)) {
            setValue(`${tabName}.${SecName}.${eForWhom.partner}.${subTotal3.name}`, addCurrencySign(0, selectedLang));
        }
        if (!getValues(`${tabName}.${SecName}.${eForWhom.partner}.${subTotal4.name}`)) {
            setValue(`${tabName}.${SecName}.${eForWhom.partner}.${subTotal4.name}`, addCurrencySign(0, selectedLang));
        }
        if (!getValues(`${tabName}.${SecName}.${eForWhom.you}.${total.name}`)) {
            setValue(`${tabName}.${SecName}.${eForWhom.you}.${total.name}`, addCurrencySign(0, selectedLang));
        }
        if (!getValues(`${tabName}.${SecName}.${eForWhom.partner}.${total.name}`)) {
            setValue(`${tabName}.${SecName}.${eForWhom.partner}.${total.name}`, addCurrencySign(0, selectedLang));
        }
    }, [replaceIncome1.name, replaceIncome2.name, replaceIncome3.name, replaceIncome4.name, selectedLang, setValue, storedAboutYouData.partner.irAnnualIncome, storedAboutYouData.you.irAnnualIncome, subTotal1.name, subTotal2.name, subTotal3.name, subTotal4.name, tabName, total.name])

    useEffect(() => {
        setInitialValues();

        return () => {
            const yourIncome = Number(getOnlyNumbers(getValues(`${tabName}.${SecName}.${eForWhom.you}.${replaceIncome1.name}`)));
            const partnerIncome = Number(getOnlyNumbers(getValues(`${tabName}.${SecName}.${eForWhom.partner}.${replaceIncome3.name}`)));

            const yourEmployment = getValues(`${eCalculatorTab.disability}.IrCurrentEmploymentIncome.${eForWhom.you}.${employmentStatus.name}`);
            const partnerEmployment = getValues(`${eCalculatorTab.disability}.IrCurrentEmploymentIncome.${eForWhom.partner}.${employmentStatus.name}`);

            const storedIncome = Math.round(Number(getOnlyNumbers(storedAboutYouData.you.irAnnualIncome))) / 12 || 0;
            const storedPartnerIncome = Math.round(Number(getOnlyNumbers(storedAboutYouData.partner.irAnnualIncome))) / 12 || 0;

            const finalIncome = yourIncome !== Math.round(storedIncome) ? yourIncome * 12 : Number(getOnlyNumbers(storedAboutYouData.you.irAnnualIncome));
            const finalPartnerIncome = partnerIncome !== Math.round(storedPartnerIncome) ? partnerIncome * 12 : Number(getOnlyNumbers(storedAboutYouData.partner.irAnnualIncome));

            dispatch(setAboutYouData({
                ...storedAboutYouData,
                you: {
                    ...storedAboutYouData.you,
                    irAnnualIncome: addCurrencySign(finalIncome, selectedLang),
                    irEmploymentStatus: yourEmployment || storedAboutYouData.you.irEmploymentStatus
                },
                partner: {
                    ...storedAboutYouData.partner,
                    irAnnualIncome: addCurrencySign(finalPartnerIncome, selectedLang),
                    irEmploymentStatus: partnerEmployment || storedAboutYouData.partner.irEmploymentStatus
                }
            }));
        }
    }, [])

    useEffect(() => {
        setInitialValues();
    }, [storedAboutYouData])

    const hidePartnerInput = `${tabName}.${SecName}.hideInput`;
    const hideYourInput = `${tabName}.${SecName}.hideInput`;

    const noDisplayRow = selectedIrInsuranceFor === eIncluded.justMe;

    const calculateSubtotal = (forWhom, name) => {
        let amountString;
        let monthsString;
        let targetSring;

        let partnerAmount;
        let partnerMonths;

        let updateAmountString;
        let updateMounthString;
        let updateTargetString;

        let updatePartnerSubtotal;
        switch (name) {
            case replaceIncome1.name:
            case months1.name:
                amountString = replaceIncome1.name
                monthsString = months1.name
                targetSring = subTotal1.name

                partnerAmount = replaceIncome2.name
                partnerMonths = months2.name

                if (name === replaceIncome1.name) {
                    updateAmountString = replaceIncome4.name
                    updateMounthString = months4.name
                    updateTargetString = subTotal4.name

                    updatePartnerSubtotal = subTotal3.name


                }
                break;
            case replaceIncome2.name:
            case months2.name:
                amountString = replaceIncome2.name
                monthsString = months2.name
                targetSring = subTotal2.name

                partnerAmount = replaceIncome1.name
                partnerMonths = months1.name

                if (name === replaceIncome2.name) {
                    updateAmountString = replaceIncome3.name
                    updateMounthString = months3.name
                    updateTargetString = subTotal3.name

                    updatePartnerSubtotal = subTotal4.name
                }
                break;
            case replaceIncome3.name:
            case months3.name:
                amountString = replaceIncome3.name
                monthsString = months3.name
                targetSring = subTotal3.name

                partnerAmount = replaceIncome4.name
                partnerMonths = months4.name
                if (name === replaceIncome3.name) {
                    updateAmountString = replaceIncome2.name
                    updateMounthString = months2.name
                    updateTargetString = subTotal2.name

                    updatePartnerSubtotal = subTotal1.name
                }
                break;
            case replaceIncome4.name:
            case months4.name:
                amountString = replaceIncome4.name
                monthsString = months4.name
                targetSring = subTotal4.name

                partnerAmount = replaceIncome3.name
                partnerMonths = months3.name
                if (name === replaceIncome4.name) {
                    updateAmountString = replaceIncome1.name
                    updateMounthString = months1.name
                    updateTargetString = subTotal1.name

                    updatePartnerSubtotal = subTotal2.name
                }
                break
            default:
                amountString = ''
                monthsString = ''
                targetSring = ''
        }
        const monthlyAmount = Number(getOnlyNumbers(getValues(`${tabName}.${SecName}.${forWhom}.${amountString}`)));
        const months = Number(getOnlyNumbers(getValues(`${tabName}.${SecName}.${forWhom}.${monthsString}`)));
        setValue(`${tabName}.${SecName}.${forWhom}.${targetSring}`, addCurrencySign(monthlyAmount * months, selectedLang));

        const partnerMonthlyAmount = Number(getOnlyNumbers(getValues(`${tabName}.${SecName}.${forWhom}.${partnerAmount}`)));
        const partnerxMonths = Number(getOnlyNumbers(getValues(`${tabName}.${SecName}.${forWhom}.${partnerMonths}`)));

        setValue(`${tabName}.${SecName}.${forWhom}.${total.name}`, addCurrencySign((monthlyAmount * months) + (partnerMonthlyAmount * partnerxMonths), selectedLang))
        if (updateAmountString) {
            const person = forWhom === eForWhom.you ? eForWhom.partner : eForWhom.you;
            const updateMonths = Number(getOnlyNumbers(getValues(`${tabName}.${SecName}.${person}.${updateMounthString}`)));
            const partnerSubtotal = Number(getOnlyNumbers(getValues(`${tabName}.${SecName}.${person}.${updatePartnerSubtotal}`)));
            setValue(`${tabName}.${SecName}.${person}.${updateAmountString}`, monthlyAmount)
            setValue(`${tabName}.${SecName}.${person}.${updateTargetString}`, addCurrencySign(monthlyAmount * updateMonths, selectedLang))
            setValue(`${tabName}.${SecName}.${person}.${total.name}`, addCurrencySign(partnerSubtotal + (monthlyAmount * updateMonths), selectedLang))
        }
    }

    const ComponentList = [
        {
            compName: "IrTabTblHeader",
            compProps: {}
        },
        {
            compName: "IrTabTblRowAmount",
            compProps: {
                rowLabel: replaceIncome1.label[selectedLang],
                keyName: replaceIncome1.name,
                maxAmount: replaceIncome1.maxValue,
                tooltipText: replaceIncome1.toolTip[selectedLang],
                tooltipAriaLabel: replaceIncome1.tooltipAriaLabel[selectedLang],
                hidePartnerInput,
                onYouBlur: () => calculateSubtotal(eForWhom.you, replaceIncome1.name),
            }
        },
        {
            compName: "IrTabTblRowNumber",
            compProps: {
                rowLabel: months1.label[selectedLang],
                keyName: months1.name,
                maxNumber: months1.maxValue,
                hidePartnerInput,
                onYouBlur: () => calculateSubtotal(eForWhom.you, months1.name),
            }
        },
        {
            compName: "IrTabTblRowTotal",
            compProps: {
                rowLabel: subTotal1.label[selectedLang],
                bgColor: "light_grey",
                isKeyAsValue: true,
                value1: `${tabName}.${SecName}.${eForWhom.you}.${subTotal1.name}`,
            }
        },
        {
            compName: "IrTabTblRowAmount",
            compProps: {
                rowLabel: replaceIncome2.label[selectedLang],
                keyName: replaceIncome2.name,
                maxAmount: replaceIncome2.maxValue,
                tooltipText: replaceIncome2.toolTip[selectedLang],
                tooltipAriaLabel: replaceIncome2.tooltipAriaLabel[selectedLang],
                hidePartnerInput,
                noDisplay: noDisplayRow,
                onYouBlur: () => calculateSubtotal(eForWhom.you, replaceIncome2.name),
            }
        },
        {
            compName: "IrTabTblRowNumber",
            compProps: {
                rowLabel: months2.label[selectedLang],
                keyName: months2.name,
                maxNumber: months2.maxValue,
                hidePartnerInput,
                noDisplay: noDisplayRow,
                onYouBlur: () => calculateSubtotal(eForWhom.you, months2.name),
            }
        },
        {
            compName: "IrTabTblRowTotal",
            compProps: {
                rowLabel: subTotal2.label[selectedLang],
                bgColor: "light_grey",
                isKeyAsValue: true,
                noDisplay: noDisplayRow,
                value1: `${tabName}.${SecName}.${eForWhom.you}.${subTotal2.name}`,
            }
        },
        {
            compName: "IrTabTblRowAmount",
            compProps: {
                rowLabel: replaceIncome3.label[selectedLang],
                keyName: replaceIncome3.name,
                maxAmount: replaceIncome3.maxValue,
                tooltipText: replaceIncome3.toolTip[selectedLang],
                tooltipAriaLabel: replaceIncome3.tooltipAriaLabel[selectedLang],
                hideYourInput,
                noDisplay: noDisplayRow,
                onPartnerBlur: () => calculateSubtotal(eForWhom.partner, replaceIncome3.name)
            }
        },
        {
            compName: "IrTabTblRowNumber",
            compProps: {
                rowLabel: months3.label[selectedLang],
                keyName: months3.name,
                maxNumber: months3.maxValue,
                hideYourInput,
                noDisplay: noDisplayRow,
                onPartnerBlur: () => calculateSubtotal(eForWhom.partner, months3.name)
            }
        },
        {
            compName: "IrTabTblRowTotal",
            compProps: {
                rowLabel: subTotal3.label[selectedLang],
                bgColor: "light_grey",
                isKeyAsValue: true,
                noDisplay: noDisplayRow,
                value2: `${tabName}.${SecName}.${eForWhom.partner}.${subTotal3.name}`,
            }
        },
        {
            compName: "IrTabTblRowAmount",
            compProps: {
                rowLabel: replaceIncome4.label[selectedLang],
                keyName: replaceIncome4.name,
                maxAmount: replaceIncome4.maxValue,
                tooltipText: replaceIncome4.toolTip[selectedLang],
                tooltipAriaLabel: replaceIncome4.tooltipAriaLabel[selectedLang],
                hideYourInput,
                noDisplay: noDisplayRow,
                onPartnerBlur: () => calculateSubtotal(eForWhom.partner, replaceIncome4.name)
            }
        },
        {
            compName: "IrTabTblRowNumber",
            compProps: {
                rowLabel: months4.label[selectedLang],
                keyName: months4.name,
                maxNumber: months4.maxValue,
                hideYourInput,
                noDisplay: noDisplayRow,
                onPartnerBlur: () => calculateSubtotal(eForWhom.partner, months4.name)
            }
        },
        {
            compName: "IrTabTblRowTotal",
            compProps: {
                rowLabel: subTotal4.label[selectedLang],
                bgColor: "light_grey",
                isKeyAsValue: true,
                noDisplay: noDisplayRow,
                value2: `${tabName}.${SecName}.${eForWhom.partner}.${subTotal4.name}`,
            }
        },
        {
            compName: "IrTabTblRowTotal",
            compProps: {
                rowLabel: total.label[selectedLang],
                isKeyAsValue: true,
                value1: `${tabName}.${SecName}.${eForWhom.you}.${total.name}`,
                value2: `${tabName}.${SecName}.${eForWhom.partner}.${total.name}`,
            }
        },
    ];

    return (
        <IrTabSecWrapper secTitle={secTitle[selectedLang]} secSubTitle={secSubTitle[selectedLang]}>
            {
                ComponentList.map((componentOdj, index) => {
                    const { compName, compProps } = componentOdj;
                    if (!compProps.noDisplay) {
                        return React.createElement(secComponents[compName], {
                            key: `${Date.now()}${index}`,
                            ...commonProps,
                            ...compProps
                        })
                    } else {
                        return null;
                    }
                })
            }
        </IrTabSecWrapper >
    )
}

export default IrReplaceIncome