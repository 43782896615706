import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { useLang, BREAKPOINTS, Dropdown } from '@manulife/mux';
import LABELS from '../../../assets/labels';
import useWindowSize from '../../../customHooks/useWindowSize';

function IrDropdown({ fieldName, fieldLabel, fieldAriaLabel, fieldOptions, handleDropdownChange = () => null, forWhom }) {
    const { width: windowWidth } = useWindowSize();
    const selectedLang = useLang();
    const { control } = useFormContext(); // retrieve all hook methods

    const handleChange = (newValue, onChange) => {
        onChange(newValue)
        handleDropdownChange(newValue, forWhom)
    }

    return (
        <Controller
            control={control}
            name={`${fieldName}`}
            rules={{
                required: LABELS.errors.required[selectedLang]
            }}
            render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { error },
            }) => (
                <Dropdown
                    id={`${fieldLabel}_${fieldName}`}
                    label={windowWidth > BREAKPOINTS.MAX_768 ? "" : fieldLabel}
                    ariaLabel={fieldAriaLabel}
                    ariaRequired={true}
                    dropdownItems={fieldOptions}
                    customStyle={{
                        rootStyle: {
                            width: "100%",
                        },
                    }}
                    ref={ref}
                    value={value}
                    onBlur={onBlur}
                    onChange={(value) => handleChange(value, onChange)}
                    errors={
                        error && [
                            { id: `error-${name}`, isError: true, msg: error.message },
                        ]
                    }
                />
            )}
        />
    )
};

export default IrDropdown;