import { combineReducers } from "redux";
import EnvironmentReducer from "./environment/Reducer";
import IrCalculatorReducer from "./IrCalculator/Reducer";
import IrResultCheckboxReducer from "./IrResultCheckbox/Reducer";
import ErrorReducer from "./error/Reducer";

export default combineReducers({
    errorReducer: ErrorReducer,
    environmentReducer: EnvironmentReducer,
    irCalculatorReducer: IrCalculatorReducer,
    irResultCheckboxReducer: IrResultCheckboxReducer,
})