import { useLang, rem, Stack, colors } from "@manulife/mux";
import { Stethoscope } from "@manulife/mux-cds-icons";
import {
  NormalText,
  SectionLabel,
  InsuranceAmt,
} from "../../assets/appStyledComponents";
import ResultConstants from "../../assets/resultConstants";
import CIResultConstants from "../../assets/resultCIConstants.json";
import NumberConstants from "../../assets/appStyledComponents/numberConstant.json";
import { formatAmount } from "../../services/helpers/commonHelpers";

function CIDetails({ name, insuranceAmount, costAmount, age }) {
  const { num20, num8, num25000, num2000000 , num18, num60} = NumberConstants;
  const selectedLang = useLang();
  const getName = (name) => {
    if (name === "you") {
      return ResultConstants.name[selectedLang];
    } else if (name === "partner") {
      return ResultConstants.partnerName[selectedLang];
    } else {
      return name;
    }
  };
  const showWarningMin = () =>{
    return CIResultConstants.warningAmtMin[selectedLang];
  };
  const showWarningMax = () =>{
    return CIResultConstants.warningAmtMax[selectedLang];
  };
  return (
    <>
      <Stack
        align="flex-start"
        style={{ alignSelf: "stretch" }}
        gap={rem(num20)}
      >
        <SectionLabel>{getName(name)}</SectionLabel>
        <Stack direction="row" gapX="0">
          <Stack style={{ width: "10%" }}>
            <Stethoscope
              fontSize={rem(num20)}
              color={colors.dark_navy}
              style={{ marginRight: rem(num8) }}
            />
          </Stack>
          <Stack style={{ width: "90%" }}>
            {CIResultConstants.productName[`${selectedLang}`]}
          </Stack>
        </Stack>
        <Stack
          align="flex-start"
          style={{ alignSelf: "stretch" }}
          gap={rem(num8)}
        >
          <NormalText>
            {CIResultConstants.insuranceAmt[`${selectedLang}`]}
          </NormalText>
          <InsuranceAmt>
            {formatAmount(insuranceAmount, selectedLang)}
          </InsuranceAmt>
        </Stack>
        <Stack
          align="flex-start"
          style={{ alignSelf: "stretch" }}
          gap={rem(num8)}
        >
          <NormalText>
            {CIResultConstants.costAmt[`${selectedLang}`]}
          </NormalText>
          <InsuranceAmt>{formatAmount(Number(costAmount).toFixed(2), selectedLang)}</InsuranceAmt>
        </Stack>
        <NormalText>
        {insuranceAmount === num25000 && showWarningMin()}
        </NormalText>
        <NormalText>
        {insuranceAmount === num2000000 && showWarningMax()}
        </NormalText>
        <NormalText>
        {!(Number(age) > num18 && Number(age) <= num60) &&
            CIResultConstants?.warningAge[selectedLang]}
        </NormalText>
      </Stack>
    </>
  );
};

export default CIDetails;
