import { LANG } from "@manulife/mux";
import Constants from "../../assets/constants.json";

export const eProduct = {
  ALL: "All",
  FT: "FamilyTerm",
  FTV: "FamilyTermVitality",
  LC: "Lifecheque",
  SYN: "Synergy",
  VS: "VentureSeries",
};

export const eCostType = {
  FT_FTV_T10: "Term10",
  FT_FTV_T20: "Term20",
  FT_FTV_T65: "Term65",
  LC_T10: "LifechequeRenewable10",
  LC_T20: "LifechequeRenewable20",
  LC_T65: "LifechequePrimary65",
  LC_T75: "LifechequeLevel75",
  LC_T100: "LifechequePermanent",
  LC_PAY15: "LifechequePermanent-15Pay",
  SYN_T10: "Renewable10",
  SYN_LEVEL: "Level65",
};

export const eCapitalFundType = {
  none: "None",
  deplete: "Deplete",
  retain: "Retain",
};

export const eEmploymentStatusType = {
  none: "None",
  employed: "Employed",
  notEmployed: "NotEmployed",
  selfEmployed: "SelfEmployed"
};

export const eSex = {
  male: "Male",
  female: "Female",
};

export const eIncluded = {
  justMe: "JustMe",
  meAndPartner: "MeAndPartner",
};

export const eForWhom = {
  you: "you",
  partner: "partner",
};

export const eHealthstyleType = {
  none: "None",
  hs1: "HS1",
  hs2: "HS2",
  hs3: "HS3",
  hs4: "HS4",
  hs5: "HS5",
  nonSmoker: "NonSmoker",
  smoker: "Smoker"
};

export const displayGender = (selectedOption, lang) => {
  switch (selectedOption) {
    case eSex.male:
      return lang === LANG.EN_CA ? eSex.male : "Homme";
    case eSex.female:
      return lang === LANG.EN_CA ? eSex.female : "Femme";
    default:
      return "";
  }
};

export const displayHealthStyle = (selectedOption, lang) => {
  switch (selectedOption) {
    case eHealthstyleType.hs3:
      return lang === LANG.EN_CA ? "Non-Smoker" : "Non-fumeur";
    case eHealthstyleType.hs5:
      return lang === LANG.EN_CA ? "Smoker" : "Fumeur";
    case eHealthstyleType.nonSmoker:
      return lang === LANG.EN_CA ? "Non-Smoker" : "Non-fumeur";
    case eHealthstyleType.smoker:
      return lang === LANG.EN_CA ? "Smoker" : "Fumeur";
    default:
      return "";
  }
};

export const eChoice = {
  yes: 'yes',
  no: 'no',
};

export const eCalculatorTab = {
  life: "life",
  disability: "disability",
  criticalIllness: "criticalIllness"
}

export const eCoverageIndicatorType = {
  none: "None",
  base: "Base",
  enhancer: "Enhancer",
  rider: "Rider",
  termRider: "TermRider"
}

export const eCoverageType = {
  single: "Single",
  combined: "CombinedTir",
  jointFirst: "JointFtd",
  jointLast: "JointLtd",
  jointLastPayLastDeath: "JointLtdCtl",
};

export const eBusinessType = {
  NB: "NewBusiness",
  IF: "Inforce",
};

export const eSystemType = {
  IR: "InsureRight",
  R2G: "RatesToGo",
  WEB: "Web",
};

export const eUserType = {
  advisor: "Advisor",
  headOffice: "HeadOffice",
};

export const eUDMType = {
  validation: "Validation",
  illustration: "Full",
  coverageCompare: "CoverageCompare",
  summary: "Summary",
  report: "Report"
};

export const getLang = (selectedLang) => selectedLang === LANG.EN_CA ? "English" : "French";

export const getCoverageIdPrefix = (selectedProduct) => {
  switch (selectedProduct) {
    case eProduct.FT:
    case eProduct.FTV:
      return "Term.";
    case eProduct.LC:
      return "Lc.";
    case eProduct.SYN:
      return "Synergy.";
    case eProduct.VS:
      return "Venture.";
    default:
      return "";
  }
};

export const getProductName = (selectedProduct) => {
  switch (selectedProduct) {
    case eProduct.FT:
    case eProduct.FTV:
      return "FamilyTerm";
    case eProduct.LC:
      return "Lifecheque";
    case eProduct.SYN:
      return "Synergy";
    case eProduct.VS:
      return "VentureSeries";
    default:
      return "";
  }
};

export const getProductFamily = (selectedProduct) => {
  switch (selectedProduct) {
    case eProduct.FT:
    case eProduct.FTV:
      return "Term";
    case eProduct.LC:
      return "Lifecheque";
    case eProduct.SYN:
      return "Synergy";
    case eProduct.VS:
      return "VentureSeries";
    default:
      return "";
  }
};

export const getSmokingStatus = (selectedSmokingStatus, selectedProduct) => {
  let sss = "";
  switch (selectedProduct) {
    case eProduct.FT:
      sss = selectedSmokingStatus;
      break;
    case eProduct.LC:
      sss = selectedSmokingStatus === eHealthstyleType.hs3 ? eHealthstyleType.nonSmoker : eHealthstyleType.smoker;
      break;
    case eProduct.SYN:
      sss = selectedSmokingStatus === eHealthstyleType.hs3 ? eHealthstyleType.nonSmoker : eHealthstyleType.smoker;
      break;
    case eProduct.VS:
      sss = selectedSmokingStatus === eHealthstyleType.hs3 ? eHealthstyleType.nonSmoker : eHealthstyleType.smoker;
      break;
    default:
  }
  return sss;
}

export const getName = (name, selectedLang) => {
  let sss = "";
  switch (name) {
    case eForWhom.you:
      sss = Constants.name[selectedLang];
      break;
    case eForWhom.partner:
      sss = Constants.partnerName[selectedLang];
      break;
    default:
      sss = name;
  }
  return sss;
}