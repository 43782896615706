import React, { useEffect } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { TextInput, useLang, LANG } from '@manulife/mux';
import { addCurrencySign, getAmountNumbers } from '../../../services/helpers/commonHelpers';
import NumberConstants from "../../../assets/appStyledComponents/numberConstant.json";
import LABELS from '../../../assets/labels';

function IrAnnualIncome({ detailFor }) {
    const { num2, num1000000 } = NumberConstants;
    const selectedLang = useLang();
    const { control, setValue, getValues } = useFormContext(); // retrieve all hook methods

    useEffect(() => {
        setValue(`${detailFor}.irAnnualIncome`, addCurrencySign(getAmountNumbers(getValues(`${detailFor}.irAnnualIncome`)), selectedLang));
        return () => null
    }, [selectedLang, setValue, getValues, detailFor]);

    const handleAmountChange = (newValue, event, onChange) => {
        /* onChange */
        const amountInNumber = getAmountNumbers(newValue);
        if (amountInNumber > num1000000) {
            onChange(addCurrencySign(num1000000, selectedLang));
        } else {
            onChange(addCurrencySign(amountInNumber, selectedLang));
        }
        if (selectedLang === LANG.FR_CA) {
            setTimeout(() => {
                event.target.setSelectionRange(
                    event.target.value.length - num2,
                    event.target.value.length - num2
                );
            }, 0);
        };
    };

    return (
        <Controller
            control={control}
            name={`${detailFor}.irAnnualIncome`}
            rules={{
                required: LABELS.errors.required[selectedLang]
            }}
            render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { error },
            }) => (
                <TextInput
                    label={LABELS.question7[selectedLang][detailFor].question}
                    ariaLabel={LABELS.question7[selectedLang][detailFor].ariaLabel}
                    ariaRequired={true}
                    data-testid={name}
                    placeholder={selectedLang === LANG.EN_CA ? "$ 0" : "0 $"}
                    customStyle={{
                        rootStyle: {
                            width: "100%",
                        },
                    }}
                    ref={ref}
                    value={value}
                    onBlur={onBlur}
                    onFocus={(newValue, event) => {
                        if (selectedLang === LANG.FR_CA) {
                            event.target.setSelectionRange(
                                event.target.value.length - num2,
                                event.target.value.length - num2
                            );
                        }
                    }}
                    onChange={(newValue, event) => handleAmountChange(newValue, event, onChange)}
                    errors={error && [{ id: `error-${name}`, isError: true, msg: error.message }]}
                />
            )}
        />
    )
};

export default IrAnnualIncome