import { LANG } from "@manulife/mux";
import constants from "../../assets/constants.json";

const todaysDate = new Date();

const formatDate = (formatIdentifier, selectedLang) => {
    switch (formatIdentifier) {
        case "resultSection":
            if (selectedLang === LANG.EN_CA) {
                return `As of ${constants.MONTHS[todaysDate.getMonth()][selectedLang]} ${todaysDate.getDate()}, ${todaysDate.getFullYear()}`;
            } else {
                return `Au ${todaysDate.getDate()} ${constants.MONTHS[todaysDate.getMonth()][selectedLang]} ${todaysDate.getFullYear()}`;
            }
        case "emailTemplateDate":
            if (selectedLang === LANG.EN_CA) {
                return `${constants.MONTHS[todaysDate.getMonth()][selectedLang]} ${todaysDate.getDate()}, ${todaysDate.getFullYear()}`;
            } else {
                return `${todaysDate.getDate()} ${constants.MONTHS[todaysDate.getMonth()][selectedLang]} ${todaysDate.getFullYear()}`;
            }
        default:
            return "";
    }
}

export { formatDate };