import React, { useCallback, useEffect } from 'react';
import { IrTabSecWrapper, secComponents } from '../IrCommon';
import { useLang } from '@manulife/mux';
import { eForWhom } from '../../services/helpers/udmConstants';
import CriticalInputs from './IrCriticalIllnessInputs.json';
import { addCurrencySign, getOnlyNumbers } from '../../services/helpers/commonHelpers';
import { useFormContext } from 'react-hook-form';

function IrPayDebtsAndExpenses({ name1, name2, selectedIrInsuranceFor, tabName }) {

    const selectedLang = useLang();
    const { getValues, setValue } = useFormContext();

    const SecName = "IrPayDebtsAndExpenses";

    const commonProps = {
        name1,
        name2,
        selectedIrInsuranceFor,
        tabName,
        secName: SecName,
    };

    const {
        deptsAndExpenses: {
            secTitle,
            secSubTitle,
            inputComponents: {
                mortageOrRent,
                deptPayments,
                savingContributions,
                otherExpenses,
                lumpSum,
                months1,
                months2,
                months3,
                months4,
                additionalExpense,
                subTotal1,
                subTotal2,
                subTotal3,
                subTotal4,
                total
            }
        }
    } = CriticalInputs;

    const setInitialValues = useCallback((forWhom) => {
        if (!getValues(`${tabName}.${SecName}.${forWhom}.${subTotal1.name}`)) {
            setValue(`${tabName}.${SecName}.${forWhom}.${subTotal1.name}`, addCurrencySign(0, selectedLang));
        }
        if (!getValues(`${tabName}.${SecName}.${forWhom}.${subTotal2.name}`)) {
            setValue(`${tabName}.${SecName}.${forWhom}.${subTotal2.name}`, addCurrencySign(0, selectedLang));
        }
        if (!getValues(`${tabName}.${SecName}.${forWhom}.${subTotal3.name}`)) {
            setValue(`${tabName}.${SecName}.${forWhom}.${subTotal3.name}`, addCurrencySign(0, selectedLang));
        }
        if (!getValues(`${tabName}.${SecName}.${forWhom}.${subTotal4.name}`)) {
            setValue(`${tabName}.${SecName}.${forWhom}.${subTotal4.name}`, addCurrencySign(0, selectedLang));
        }
        if (!getValues(`${tabName}.${SecName}.${forWhom}.${total.name}`)) {
            setValue(`${tabName}.${SecName}.${forWhom}.${total.name}`, addCurrencySign(0, selectedLang));
        }
    }, [selectedLang, getValues, setValue, subTotal1.name, subTotal2.name, subTotal3.name, subTotal4.name, tabName, total.name]);

    useEffect(() => {
        setInitialValues(eForWhom.you)
        setInitialValues(eForWhom.partner)
    }, [setInitialValues]);

    const setSubtotal = (forWhom, amountString, monthsString, subtotal) => {
        const amount = Number(getOnlyNumbers(getValues(`${tabName}.${SecName}.${forWhom}.${amountString}`)));
        const months = Number(getOnlyNumbers(getValues(`${tabName}.${SecName}.${forWhom}.${monthsString}`)));
        setValue(`${tabName}.${SecName}.${forWhom}.${subtotal}`, addCurrencySign(amount * months, selectedLang));
        setTotal(forWhom);
    };

    const setTotal = (forWhom) => {
        const mortage = Number(getOnlyNumbers(getValues(`${tabName}.${SecName}.${forWhom}.${mortageOrRent.name}`)))
        const month1 = Number(getOnlyNumbers(getValues(`${tabName}.${SecName}.${forWhom}.${months1.name}`)))
        const dept = Number(getOnlyNumbers(getValues(`${tabName}.${SecName}.${forWhom}.${deptPayments.name}`)))
        const month2 = Number(getOnlyNumbers(getValues(`${tabName}.${SecName}.${forWhom}.${months2.name}`)))
        const savings = Number(getOnlyNumbers(getValues(`${tabName}.${SecName}.${forWhom}.${savingContributions.name}`)))
        const month3 = Number(getOnlyNumbers(getValues(`${tabName}.${SecName}.${forWhom}.${months3.name}`)))
        const other = Number(getOnlyNumbers(getValues(`${tabName}.${SecName}.${forWhom}.${otherExpenses.name}`)))
        const month4 = Number(getOnlyNumbers(getValues(`${tabName}.${SecName}.${forWhom}.${months4.name}`)))
        const lump = Number(getOnlyNumbers(getValues(`${tabName}.${SecName}.${forWhom}.${lumpSum.name}`)))
        const additional = Number(getOnlyNumbers(getValues(`${tabName}.${SecName}.${forWhom}.${additionalExpense.name}`)))

        const totalAmount = (mortage * month1) + (dept * month2) + (savings * month3) + (other * month4) + lump + additional
        setValue(`${tabName}.${SecName}.${forWhom}.${total.name}`, addCurrencySign(totalAmount, selectedLang));
    };

    const ComponentList = [
        {
            compName: "IrTabTblHeader",
            compProps: {}
        },
        {
            compName: "IrTabTblRowAmount",
            compProps: {
                rowLabel: mortageOrRent.label[selectedLang],
                keyName: mortageOrRent.name,
                maxAmount: mortageOrRent.maxValue,
                onYouBlur: () => setSubtotal(eForWhom.you, mortageOrRent.name, months1.name, subTotal1.name),
                onPartnerBlur: () => setSubtotal(eForWhom.partner, mortageOrRent.name, months1.name, subTotal1.name),
            }
        },
        {
            compName: "IrTabTblRowNumber",
            compProps: {
                rowLabel: months1.label[selectedLang],
                keyName: months1.name,
                maxNumber: months1.maxValue,
                onYouBlur: () => setSubtotal(eForWhom.you, mortageOrRent.name, months1.name, subTotal1.name),
                onPartnerBlur: () => setSubtotal(eForWhom.partner, mortageOrRent.name, months1.name, subTotal1.name),
            }
        },
        {
            compName: "IrTabTblRowTotal",
            compProps: {
                rowLabel: subTotal1.label[selectedLang],
                bgColor: "light_grey",
                isKeyAsValue: true,
                value1: `${tabName}.${SecName}.${eForWhom.you}.${subTotal1.name}`,
                value2: `${tabName}.${SecName}.${eForWhom.partner}.${subTotal1.name}`,
            }
        },
        {
            compName: "IrTabTblRowAmount",
            compProps: {
                rowLabel: deptPayments.label[selectedLang],
                keyName: deptPayments.name,
                maxAmount: deptPayments.maxValue,
                onYouBlur: () => setSubtotal(eForWhom.you, deptPayments.name, months2.name, subTotal2.name),
                onPartnerBlur: () => setSubtotal(eForWhom.partner, deptPayments.name, months2.name, subTotal2.name),
            }
        },
        {
            compName: "IrTabTblRowNumber",
            compProps: {
                rowLabel: months2.label[selectedLang],
                keyName: months2.name,
                maxNumber: months2.maxValue,
                onYouBlur: () => setSubtotal(eForWhom.you, deptPayments.name, months2.name, subTotal2.name),
                onPartnerBlur: () => setSubtotal(eForWhom.partner, deptPayments.name, months2.name, subTotal2.name),
            }
        },
        {
            compName: "IrTabTblRowTotal",
            compProps: {
                rowLabel: subTotal2.label[selectedLang],
                bgColor: "light_grey",
                isKeyAsValue: true,
                value1: `${tabName}.${SecName}.${eForWhom.you}.${subTotal2.name}`,
                value2: `${tabName}.${SecName}.${eForWhom.partner}.${subTotal2.name}`,
            }
        },
        {
            compName: "IrTabTblRowAmount",
            compProps: {
                rowLabel: savingContributions.label[selectedLang],
                keyName: savingContributions.name,
                maxAmount: savingContributions.maxValue,
                onYouBlur: () => setSubtotal(eForWhom.you, savingContributions.name, months3.name, subTotal3.name),
                onPartnerBlur: () => setSubtotal(eForWhom.partner, savingContributions.name, months3.name, subTotal3.name),
            }
        },
        {
            compName: "IrTabTblRowNumber",
            compProps: {
                rowLabel: months3.label[selectedLang],
                keyName: months3.name,
                maxNumber: months3.maxValue,
                onYouBlur: () => setSubtotal(eForWhom.you, savingContributions.name, months3.name, subTotal3.name),
                onPartnerBlur: () => setSubtotal(eForWhom.partner, savingContributions.name, months3.name, subTotal3.name),
            }
        },
        {
            compName: "IrTabTblRowTotal",
            compProps: {
                rowLabel: subTotal3.label[selectedLang],
                bgColor: "light_grey",
                isKeyAsValue: true,
                value1: `${tabName}.${SecName}.${eForWhom.you}.${subTotal3.name}`,
                value2: `${tabName}.${SecName}.${eForWhom.partner}.${subTotal3.name}`,
            }
        },
        {
            compName: "IrTabTblRowAmount",
            compProps: {
                rowLabel: otherExpenses.label[selectedLang],
                keyName: otherExpenses.name,
                maxAmount: otherExpenses.maxValue,
                onYouBlur: () => setSubtotal(eForWhom.you, otherExpenses.name, months4.name, subTotal4.name),
                onPartnerBlur: () => setSubtotal(eForWhom.partner, otherExpenses.name, months4.name, subTotal4.name),
            }
        },
        {
            compName: "IrTabTblRowNumber",
            compProps: {
                rowLabel: months4.label[selectedLang],
                keyName: months4.name,
                maxNumber: months4.maxValue,
                onYouBlur: () => setSubtotal(eForWhom.you, otherExpenses.name, months4.name, subTotal4.name),
                onPartnerBlur: () => setSubtotal(eForWhom.partner, otherExpenses.name, months4.name, subTotal4.name),
            }
        },
        {
            compName: "IrTabTblRowTotal",
            compProps: {
                rowLabel: subTotal4.label[selectedLang],
                bgColor: "light_grey",
                isKeyAsValue: true,
                value1: `${tabName}.${SecName}.${eForWhom.you}.${subTotal4.name}`,
                value2: `${tabName}.${SecName}.${eForWhom.partner}.${subTotal4.name}`,
            }
        },
        {
            compName: "IrTabTblRowAmount",
            compProps: {
                rowLabel: lumpSum.label[selectedLang],
                keyName: lumpSum.name,
                maxAmount: lumpSum.maxValue,
                onYouBlur: () => setTotal(eForWhom.you),
                onPartnerBlur: () => setTotal(eForWhom.partner),
            }
        },
        {
            compName: "IrTabTblRowAmount",
            compProps: {
                rowLabel: additionalExpense.label[selectedLang],
                keyName: additionalExpense.name,
                maxAmount: additionalExpense.maxValue,
                onYouBlur: () => setTotal(eForWhom.you),
                onPartnerBlur: () => setTotal(eForWhom.partner),
            }
        },
        {
            compName: "IrTabTblRowTotal",
            compProps: {
                rowLabel: total.label[selectedLang],
                isKeyAsValue: true,
                value1: `${tabName}.${SecName}.${eForWhom.you}.${total.name}`,
                value2: `${tabName}.${SecName}.${eForWhom.partner}.${total.name}`,
            }
        },
    ];

    return (
        <IrTabSecWrapper secTitle={secTitle[selectedLang]} secSubTitle={secSubTitle[selectedLang]}>
            {
                ComponentList.map((componentOdj, index) => {
                    const { compName, compProps } = componentOdj;
                    return React.createElement(secComponents[compName], {
                        key: `${Date.now()}${index}`,
                        ...commonProps,
                        ...compProps
                    })
                })
            }
        </IrTabSecWrapper>
    )
}

export default IrPayDebtsAndExpenses;