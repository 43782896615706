import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { Dropdown, useLang } from '@manulife/mux';
import LABELS from '../../../assets/labels';

function IrEmploymentStatus({ detailFor }) {
    const selectedLang = useLang();
    const { control } = useFormContext(); // retrieve all hook methods
    return (
        <Controller
            control={control}
            name={`${detailFor}.irEmploymentStatus`}
            rules={{
                required: LABELS.errors.required.irEmploymentStatus[detailFor][selectedLang]
            }}
            render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { error },
            }) => (

                <Dropdown
                    id={name}
                    dropdownItems={LABELS.question6[selectedLang].options}
                    placeholder={LABELS.select[selectedLang]}
                    label={LABELS.question6[selectedLang][detailFor].question}
                    ariaLabel={LABELS.question6[selectedLang][detailFor].ariaLabel}
                    ariaRequired={true}
                    customStyle={{
                        rootStyle: {
                            width: "100%",
                        },
                        labelStyle: {},
                        dropdownInputStyle: {},
                        dropdownMenuStyle: {},
                    }}
                    ref={ref}
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    errors={error && [{ id: `error-${name}`, isError: true, msg: error.message }]}
                />
            )}
        />
    )
}

export default IrEmploymentStatus