import { SideNav, useLang } from "@manulife/mux";
import { Link, useLocation } from "react-router-dom";
import { Calculator } from '@manulife/mux-cds-icons';
import { changeLang } from "../../services/helpers/commonHelpers";
import LABELS from "../../assets/labels";

function IrSideNav({ setSelectedLang }) {
  const selectedLang = useLang();

  const { pathname } = useLocation();


  return (
    <SideNav
      activeKey={pathname}
      shouldCloseOnBlur={false}
      mobileFooterItems={[<SideNav.Lang key="lang" onClick={() => changeLang(selectedLang)} />]}
      brandLogoProps={{ href: "/" }}
    >
      <SideNav.Section>
        <SideNav.NavItem as={Link} id="insureRightCalculatorLink" label={LABELS.irCalculatorPageHeading[selectedLang]} to="/about" itemKey="/about" icon={<Calculator />} />
      </SideNav.Section>
    </SideNav>
  );
}

export default IrSideNav;
