import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { TextInput, useLang } from '@manulife/mux';
import LABELS from '../../../assets/labels';
import NumberConstants from '../../../assets/appStyledComponents/numberConstant.json';

function IrName({ detailFor }) {
    const { num50 } = NumberConstants;
    const selectedLang = useLang();
    const { control } = useFormContext(); // retrieve all hook methods
    return (
        <Controller
            control={control}
            name={`${detailFor}.irName`}
            render={({
                field: { onChange, onBlur, value, ref },
            }) => (
                <TextInput
                    label={LABELS.question2[selectedLang][detailFor].question}
                    ariaLabel={LABELS.question2[selectedLang][detailFor].ariaLabel}
                    customStyle={{
                        rootStyle: {
                            width: "100%",
                        },
                    }}
                    ref={ref}
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    maxLength={num50}
                />
            )}
        />
    )
};

export default IrName;