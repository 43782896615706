import React from "react";
import {
  BREAKPOINTS,
  Stack,
  colors,
  rem,
} from "@manulife/mux";
import useWindowSize from "../../customHooks/useWindowSize";
import NumberConstants from '../../assets/appStyledComponents/numberConstant.json';

function IrTabBody({ leftColComponent, rightColComponent }) {
  const { width: windowWidth } = useWindowSize();

  const { num16, num32, num40, num20 } = NumberConstants;

  return (
    <Stack data-testid="tabBodyWrapper" align="flex-start" gap={`${rem(num40)}`} style={{
      maxWidth: `${rem(BREAKPOINTS.DESKTOP_MED)}`,
      padding: windowWidth > BREAKPOINTS.MAX_768 ? `${rem(num32)} ${rem(num20)}` : `${rem(num32)} ${rem(0)}`,
      alignSelf: "stretch",
      border: "1px solid #ECECEC",
      background: colors.white
    }}>
      <Stack direction={windowWidth > BREAKPOINTS.MAX_1280 ? "row" : "column"} gap={rem(num16)} align="flex-start" justify="center" style={{ alignSelf: "stretch" }}>
        <Stack direction={"column"} align="flex-start" gap={windowWidth > BREAKPOINTS.MAX_768 ? `${rem(num40)}` : `${rem(num32)}`} style={windowWidth > BREAKPOINTS.MAX_1280 ? { flex: "1 0 0" } : { flex: "1 0 0", width: "100%" }}>
          {leftColComponent}
        </Stack>
        {rightColComponent}
      </Stack>
    </Stack>
  );
}

export default IrTabBody;
