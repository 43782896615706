import React, { useEffect } from 'react';

import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { TextInput, useLang, BREAKPOINTS } from '@manulife/mux';
import { addPercentageSign, getAmountNumbers } from '../../../services/helpers/commonHelpers';
import NumberConstants from "../../../assets/appStyledComponents/numberConstant.json";
import LABELS from '../../../assets/labels';
import useWindowSize from '../../../customHooks/useWindowSize';

const { num100 } = NumberConstants;

function IrPercentage({ fieldName, fieldLabel, fieldAriaLabel, maxAmount = num100, minAmount = 0, handleCommonBlur = () => null, disabled, hideInput }) {
    const { width: windowWidth } = useWindowSize();
    const { num2 } = NumberConstants;
    const selectedLang = useLang();
    const { control, setValue, getValues } = useFormContext(); // retrieve all hook methods

    useEffect(() => {
        setValue(`${fieldName}`, addPercentageSign(getAmountNumbers(getValues(`${fieldName}`))));
        return () => null
    }, [setValue, getValues, fieldName]);

    const isHide = useWatch({
        control,
        name: `${hideInput}`
    });

    const isDisabled = useWatch({
        control,
        name: `${disabled}`
    });

    const handleChange = (newValue, event, onChange) => {
        /* onChange */
        const amountInNumber = getAmountNumbers(newValue);
        if (amountInNumber > maxAmount) {
            onChange(addPercentageSign(maxAmount));
        } else if (amountInNumber < minAmount) {
            onChange(addPercentageSign(minAmount));
        } else {
            onChange(addPercentageSign(amountInNumber));
        }
        setTimeout(() => {
            event.target.setSelectionRange(
                event.target.value.length - num2,
                event.target.value.length - num2
            );
        }, 0);
    };

    const handleAmountBlur = (newValue, onBlur) => {
        handleCommonBlur(newValue);
        onBlur();
    }

    return (
        <>
            {!isHide && <Controller
                control={control}
                name={`${fieldName}`}
                rules={{
                    required: LABELS.errors.required[selectedLang]
                }}
                render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { error },
                }) => (
                    <TextInput
                        label={windowWidth > BREAKPOINTS.MAX_768 ? "" : fieldLabel}
                        ariaLabel={fieldAriaLabel}
                        ariaRequired={true}
                        data-testid={name}
                        placeholder="0 %"
                        customStyle={{
                            rootStyle: {
                                width: "100%",
                            },
                        }}
                        ref={ref}
                        value={value}
                        // onBlur={onBlur}
                        onBlur={(newValue) => handleAmountBlur(newValue, onBlur)}
                        onFocus={(newValue, event) => {
                            event.target.setSelectionRange(
                                event.target.value.length - num2,
                                event.target.value.length - num2
                            );
                        }}
                        onChange={(newValue, event) => handleChange(newValue, event, onChange)}
                        errors={error && [{ id: `error-${name}`, isError: true, msg: error.message }]}
                        disabled={isDisabled}
                    />
                )}
            />}
        </>
    )
};

export default IrPercentage;