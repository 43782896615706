import React, { useEffect } from 'react';
import { IrTabSecWrapper, secComponents } from '../IrCommon';
import { useLang } from '@manulife/mux';
import { eEmploymentStatusType, eForWhom } from '../../services/helpers/udmConstants';
import { addCurrencySign, addPercentageSign, getOnlyNumbers } from '../../services/helpers/commonHelpers';
import { useFormContext } from 'react-hook-form';
import { setAboutYouData } from '../../appRedux/IrCalculator/Actions';
import { useDispatch } from 'react-redux';
import DisabilityInputs from './IrDisabilityInputs.json';
import DisabilityValues from './disabilityInsuranceValues.json';

function IrCurrentEmploymentIncome({ name1, name2, selectedIrInsuranceFor, tabName, storedAboutYouData }) {

    const selectedLang = useLang();
    const dispatch = useDispatch();
    const { getValues, setValue } = useFormContext();

    const SecName = "IrCurrentEmploymentIncome";
    const disabilityInsurance = "IrDisabilityInsuranceOrOtherIncome"
    const monthlyExpense = "IrMonthlyExpenses"

    const commonProps = {
        name1,
        name2,
        selectedIrInsuranceFor,
        tabName,
        secName: SecName,
    };

    const {
        currentIncome: {
            secTitle,
            inputComponents: {
                employmentStatus,
                monthlyIncome,
                total
            }
        },
        insuranceOrOtherIncome: {
            inputComponents: {
                nonTaxableBenefit,
                taxableBenefit,
                avgTaxRate,
                otherMonthlyIncome,
            }
        },
        monthlyExpense: {
            inputComponents: {
                mortageOrRent,
                deptPayments,
                savingContributions,
                otherExpenses
            }
        },
    } = DisabilityInputs;

    useEffect(() => {
        setInitialValues();
        return () => {
            const yourIncome = Number(getOnlyNumbers(getValues(`${tabName}.${SecName}.${eForWhom.you}.${monthlyIncome.name}`)));
            const partnerIncome = Number(getOnlyNumbers(getValues(`${tabName}.${SecName}.${eForWhom.partner}.${monthlyIncome.name}`)));

            const yourEmployment = getValues(`${tabName}.${SecName}.${eForWhom.you}.${employmentStatus.name}`);
            const partnerEmployment = getValues(`${tabName}.${SecName}.${eForWhom.partner}.${employmentStatus.name}`);

            const storedIncome = Math.round(Number(getOnlyNumbers(storedAboutYouData.you.irAnnualIncome))) / 12 || 0;
            const storedPartnerIncome = Math.round(Number(getOnlyNumbers(storedAboutYouData.partner.irAnnualIncome))) / 12 || 0;

            const finalIncome = yourIncome !== Math.round(storedIncome) ? yourIncome * 12 : Number(getOnlyNumbers(storedAboutYouData.you.irAnnualIncome));
            const finalPartnerIncome = partnerIncome !== Math.round(storedPartnerIncome) ? partnerIncome * 12 : Number(getOnlyNumbers(storedAboutYouData.partner.irAnnualIncome));

            dispatch(setAboutYouData({
                ...storedAboutYouData,
                you: {
                    ...storedAboutYouData.you,
                    irAnnualIncome: addCurrencySign(finalIncome, selectedLang),
                    irEmploymentStatus: yourEmployment
                },
                partner: {
                    ...storedAboutYouData.partner,
                    irAnnualIncome: addCurrencySign(finalPartnerIncome, selectedLang),
                    irEmploymentStatus: partnerEmployment
                }
            }));
        }
    }, [dispatch, getValues, monthlyIncome.name, employmentStatus.name, selectedLang, tabName])

    useEffect(() => {
        setInitialValues();
    }, [storedAboutYouData])

    const setInitialValues = () => {
        const yourIncome = Math.round(Number(getOnlyNumbers(storedAboutYouData.you.irAnnualIncome))) / 12 || 0;
        const partnerIncome = Math.round(Number(getOnlyNumbers(storedAboutYouData.partner.irAnnualIncome))) / 12 || 0;
        setValue(`${tabName}.${SecName}.${eForWhom.you}.${monthlyIncome.name}`, addCurrencySign(Math.round(yourIncome), selectedLang));
        setValue(`${tabName}.${SecName}.${eForWhom.partner}.${monthlyIncome.name}`, addCurrencySign(Math.round(partnerIncome), selectedLang));

        const yourEmployment = storedAboutYouData.you.irEmploymentStatus;
        const partnerEmployment = storedAboutYouData.partner.irEmploymentStatus;

        calculateTotal(eForWhom.you, true);
        calculateTotal(eForWhom.partner, true);

        setValue(`${tabName}.${SecName}.${eForWhom.you}.${employmentStatus.name}`, yourEmployment);
        setValue(`${tabName}.${SecName}.${eForWhom.partner}.${employmentStatus.name}`, partnerEmployment);

        checkEmployment(yourEmployment, eForWhom.you, true);
        checkEmployment(partnerEmployment, eForWhom.partner, true);
    }

    const setToDefault = (forWhom) => {
        const currentEmploymentData = getValues(`${tabName}.${SecName}.${forWhom}`)
        const monthlyExpenseData = getValues(`${tabName}.${monthlyExpense}.${forWhom}`)
        const disabilityInsuranceData = getValues(`${tabName}.${disabilityInsurance}.${forWhom}`)

        setValue(`${tabName}.${SecName}.${forWhom}`, {
            ...currentEmploymentData,
            [monthlyIncome.name]: addCurrencySign(0, selectedLang),
        })
        setValue(`${tabName}.${monthlyExpense}.${forWhom}`, {
            ...monthlyExpenseData,
            [mortageOrRent.name]: addCurrencySign(0, selectedLang),
            [deptPayments.name]: addCurrencySign(0, selectedLang),
            [savingContributions.name]: addCurrencySign(0, selectedLang),
            [otherExpenses.name]: addCurrencySign(0, selectedLang),
        })

        setValue(`${tabName}.${disabilityInsurance}.${forWhom}`, {
            ...disabilityInsuranceData,
            [nonTaxableBenefit.name]: addCurrencySign(0, selectedLang),
            [taxableBenefit.name]: addCurrencySign(0, selectedLang),
            [avgTaxRate.name]: addPercentageSign(0, selectedLang),
            [otherMonthlyIncome.name]: addCurrencySign(0, selectedLang),
        })

        setValue(`${tabName}.${monthlyExpense}.${forWhom}.totalAmount`, addCurrencySign(0, selectedLang));
        setValue(`${tabName}.${SecName}.${forWhom}.maxInsuranceLimit`, addCurrencySign(0, selectedLang));
    }

    const checkEmployment = (value, forWhom, setInitial) => {
        if (value === eEmploymentStatusType.notEmployed) {
            setValue(`${tabName}.${SecName}.${forWhom}.hideInput`, true);
            setValue(`${tabName}.${SecName}.${forWhom}.${total.name}`, '');
            setToDefault(forWhom);
        } else if (value !== eEmploymentStatusType.notEmployed) {
            setValue(`${tabName}.${SecName}.${forWhom}.hideInput`, false);
            const income = Math.round(Number(getOnlyNumbers(storedAboutYouData[forWhom].irAnnualIncome))) / 12 || 0;
            setValue(`${tabName}.${SecName}.${forWhom}.${monthlyIncome.name}`, addCurrencySign(Math.round(income), selectedLang));
            calculateTotal(forWhom, setInitial);
        }
    }

    const calculateTotal = (forWhom, setInitial) => {
        let taxable = 0;

        let monthlyLow = 0;
        let monthlyHigh = 0;
        let taxableLow = 0;
        let taxableHigh = 0;

        let totalAmt = 0;
        let income = Number(getOnlyNumbers(getValues(`${tabName}.${SecName}.${forWhom}.${monthlyIncome.name}`)));
        if (setInitial) {
            income = Math.round(Number(getOnlyNumbers(storedAboutYouData[forWhom].irAnnualIncome))) / 12 || 0;
        }
        DisabilityValues.map((item, index) => {
            if (item.monthly === income) {
                taxable = item.taxable;
            } else if (income > item.monthly && income < DisabilityValues[index + 1].monthly) {
                monthlyLow = item.monthly;
                monthlyHigh = DisabilityValues[index + 1].monthly;
                taxableLow = item.taxable;
                taxableHigh = DisabilityValues[index + 1].taxable;
            }
            return ''
        })
        if (taxable) {
            setValue(`${tabName}.${SecName}.${forWhom}.${total.name}`, addCurrencySign(taxable, selectedLang));
            totalAmt = taxable
        } else if (monthlyLow && monthlyHigh) {
            const totalAmount = taxableLow + (taxableHigh - taxableLow) * ((income - monthlyLow) / (monthlyHigh - monthlyLow));
            setValue(`${tabName}.${SecName}.${forWhom}.${total.name}`, addCurrencySign(Math.round(totalAmount), selectedLang));
            totalAmt = totalAmount
        } else {
            setValue(`${tabName}.${SecName}.${forWhom}.${total.name}`, '');
        }

        const nonTaxableIn = Number(getOnlyNumbers(getValues(`${tabName}.${disabilityInsurance}.${forWhom}.${nonTaxableBenefit.name}`))) || 0;
        const taxableIn = Number(getOnlyNumbers(getValues(`${tabName}.${disabilityInsurance}.${forWhom}.${taxableBenefit.name}`))) || 0;
        const taxRate = Number(getOnlyNumbers(getValues(`${tabName}.${disabilityInsurance}.${forWhom}.${avgTaxRate.name}`))) || 0;
        const otherMonthly = Number(getOnlyNumbers(getValues(`${tabName}.${disabilityInsurance}.${forWhom}.${otherMonthlyIncome.name}`))) || 0;

        let selfInsuredAmt = 0.5 * (otherMonthly - (0.15 * income));
        if (selfInsuredAmt < 0) {
            selfInsuredAmt = 0;
        }

        const final = totalAmt - nonTaxableIn - (taxableIn * (1 - (taxRate / 100))) - selfInsuredAmt;

        setValue(`${tabName}.${SecName}.${forWhom}.maxInsuranceLimit`, addCurrencySign(Math.round(final > 0 ? final : 0), selectedLang));
    }

    const handleDropdownChange = (newValue, forWhom) => {
        checkEmployment(newValue, forWhom);
    }

    const hideYourInput = `${tabName}.${SecName}.${eForWhom.you}.hideInput`;
    const hidePartnerInput = `${tabName}.${SecName}.${eForWhom.partner}.hideInput`;

    const ComponentList = [
        {
            compName: "IrTabTblHeader",
            compProps: {}
        },
        {
            compName: "IrTabTblRowDropdown",
            compProps: {
                rowLabel: employmentStatus.label[selectedLang],
                keyName: employmentStatus.name,
                fieldOptions: employmentStatus.options[selectedLang],
                handleDropdownChange: handleDropdownChange
            }
        },
        {
            compName: "IrTabTblRowAmount",
            compProps: {
                rowLabel: monthlyIncome.label[selectedLang],
                keyName: monthlyIncome.name,
                maxAmount: monthlyIncome.maxValue,
                hideYourInput,
                hidePartnerInput,
                onYouBlur: () => calculateTotal(eForWhom.you),
                onPartnerBlur: () => calculateTotal(eForWhom.partner),
            }
        },
        {
            compName: "IrTabTblRowTotal",
            compProps: {
                rowLabel: total.label[selectedLang],
                isKeyAsValue: true,
                value1: `${tabName}.${SecName}.${eForWhom.you}.${total.name}`,
                value2: `${tabName}.${SecName}.${eForWhom.partner}.${total.name}`,
            }
        }
    ];

    return (
        <IrTabSecWrapper secTitle={secTitle[selectedLang]}>
            {
                ComponentList.map((componentOdj, index) => {
                    const { compName, compProps } = componentOdj;
                    return React.createElement(secComponents[compName], {
                        key: `${Date.now()}${index}`,
                        ...commonProps,
                        ...compProps
                    })
                })
            }
        </IrTabSecWrapper>
    )
}

export default IrCurrentEmploymentIncome