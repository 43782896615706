import {
  SHOW_ERROR,
  HIDE_ERROR
} from "./Types";

const initialState = {
  isError: false
};

const ErrorReducer = (state = initialState, { type }) => {
  switch (type) {
    case SHOW_ERROR:
      return {
        isError: true,
      };

    case HIDE_ERROR:
      return {
        isError: false
      };

    default:
      return state;
  }
};

export default ErrorReducer;
