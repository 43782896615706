import React from 'react';
import IrTabTblRow from './IrTabTblRow';
import { eForWhom } from '../../services/helpers/udmConstants';
import { IrDropdown } from './Inputs';

function IrTabTblRowDropdown({
    tabName,
    secName,
    keyName,
    selectedIrInsuranceFor,
    name1,
    name2,
    rowLabel,
    rowSubLabel = "",
    fieldOptions,
    tooltipText = "",
    isTopLine = false,
    isBottomLine = true,
    handleDropdownChange = () => null
}) {
    return (
        <IrTabTblRow
            selectedIrInsuranceFor={selectedIrInsuranceFor}
            rowLabel={rowLabel}
            rowSubLabel={rowSubLabel}
            isTopLine={isTopLine}
            isBottomLine={isBottomLine}
            tooltipText={tooltipText}
            input1={<IrDropdown fieldName={`${tabName}.${secName}.${eForWhom.you}.${keyName}`} fieldLabel={name1} fieldAriaLabel={name1} fieldOptions={fieldOptions} handleDropdownChange={handleDropdownChange} forWhom={eForWhom.you} />}
            input2={<IrDropdown fieldName={`${tabName}.${secName}.${eForWhom.partner}.${keyName}`} fieldLabel={name2} fieldAriaLabel={name2} fieldOptions={fieldOptions} handleDropdownChange={handleDropdownChange} forWhom={eForWhom.partner} />}
        />
    )
}

export default React.memo(IrTabTblRowDropdown);