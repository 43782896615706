import React from 'react';
import { NormalText, SectionHeadding } from '../../assets/appStyledComponents';
import { BREAKPOINTS, Button, Stack, rem, useLang } from '@manulife/mux';
import { useNavigate } from 'react-router-dom';
import NumberConstants from '../../assets/appStyledComponents/numberConstant.json';
import useWindowSize from '../../customHooks/useWindowSize';
import { useFormContext } from 'react-hook-form';
import IrReplaceIncome from './IrReplaceIncome';
import IrPayDebtsAndExpenses from './IrPayDebtsAndExpenses';
import IrOffsetCosts from './IrOffsetCosts';
import CriticalInputs from './IrCriticalIllnessInputs.json';
import { useDispatch } from 'react-redux';
import { fetchReport } from '../../appRedux/IrCalculator/Actions';
import { eCalculatorTab } from '../../services/helpers/udmConstants';

function IrCriticalIllnessInputs({ storedAboutYouData }) {
    const { width: windowWidth } = useWindowSize();
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const { handleSubmit } = useFormContext(); // retrieve all hook methods

    const selectedLang = useLang();

    const { num32, num24 } = NumberConstants;

    const { tabHeading, tabSubHeading, backBtn, calculateBtn } = CriticalInputs;

    const { irInsuranceFor, you, partner } = storedAboutYouData;

    const fetchCalculation = (data) => {
        // console.log("check data => ", data);
        dispatch(fetchReport({
            ...data
        }, selectedLang));
    };

    return (
        <Stack align="flex-start" gap={`${rem(num32)}`} style={{ alignSelf: "stretch" }}>
            <SectionHeadding style={{
                padding: windowWidth > BREAKPOINTS.MAX_768 ? `${rem(0)}` : `${rem(0)} ${rem(num24)}`
            }}>{tabHeading[selectedLang]}</SectionHeadding>

            <NormalText style={{
                padding: windowWidth > BREAKPOINTS.MAX_768 ? `${rem(0)}` : `${rem(0)} ${rem(num24)}`
            }}>{tabSubHeading[selectedLang]}</NormalText>
            <IrReplaceIncome tabName={eCalculatorTab.criticalIllness} name1={you.irName} name2={partner.irName} selectedIrInsuranceFor={irInsuranceFor} storedAboutYouData={storedAboutYouData} />
            <IrPayDebtsAndExpenses tabName={eCalculatorTab.criticalIllness} name1={you.irName} name2={partner.irName} selectedIrInsuranceFor={irInsuranceFor} />
            <IrOffsetCosts tabName={eCalculatorTab.criticalIllness} name1={you.irName} name2={partner.irName} selectedIrInsuranceFor={irInsuranceFor} />
            <Stack direction={windowWidth > BREAKPOINTS.MAX_768 ? "row" : "column-reverse"} align="center" justify="space-between" style={{
                padding: windowWidth > BREAKPOINTS.MAX_768 ? `${rem(0)}` : `${rem(0)} ${rem(num24)}`,
                alignSelf: "stretch"
            }}>
                <Button
                    variant={Button.VARIANT.TERTIARY_BACK}
                    onClick={() => navigate("/about", { replace: true })}
                    customStyle={{
                        buttonStyle: {
                            media: [
                                {
                                    maximum: BREAKPOINTS.PHONE_PORTRAIT_LG,
                                    width: "100%",
                                },
                            ],
                        },
                    }}>{backBtn[selectedLang]}</Button>
                <Button
                    onClick={(v, e) => {
                        // console.log(e);
                        e.preventDefault();
                        window.scrollTo({ top: 0 })
                        handleSubmit(fetchCalculation)()
                    }}
                    customStyle={{
                        buttonStyle: {
                            media: [
                                {
                                    maximum: BREAKPOINTS.PHONE_PORTRAIT_LG,
                                    width: "100%",
                                },
                            ],
                        },
                    }}>{calculateBtn[selectedLang]}</Button>
            </Stack>
        </Stack>
    )
}

export default IrCriticalIllnessInputs