import {
  SHOW_ERROR,
  HIDE_ERROR
} from "./Types";

export const showError = () => {
  return {
    type: SHOW_ERROR
  };
};

export const hideError = () => {
  return {
    type: HIDE_ERROR
  };
};