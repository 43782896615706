import { eCapitalFundType } from "../helpers/udmConstants";

const defaultAmount = 0.0;
const defaultNumber = 0;

const generateAsset = (inputs) => {
    const defaults = {
        "availableSavingsAmount": defaultAmount,
        "deathBenefitAmount": defaultAmount,
        "existingInsuranceAmount": defaultAmount,
        "nonRegisteredInvestmentAmount": defaultAmount,
        "otherAmount": defaultAmount,
        "principalResidenceAmount": defaultAmount,
        "registeredInvestmentAmount": defaultAmount,
        "savingsAmount": defaultAmount,
        "taxFreeSavingsAmount": defaultAmount,
        "totalAmount": defaultAmount
    }
    return {
        ...defaults,
        ...inputs
    }
};

const generateExpense = (inputs) => {
    const defaults = {
        "debtsMonthCount": defaultNumber,
        "educationYear": defaultNumber,
        "homeCareChildCount": defaultNumber,
        "homeCareYear": defaultNumber,
        "livingMonthCount": defaultNumber,
        "rentMonthCount": defaultNumber,
        "savingMonthCount": defaultNumber,

        "additionalAmount": defaultAmount,
        "debtsAmount": defaultAmount,
        "debtsTotalAmount": defaultAmount,
        "educationAmount": defaultAmount,
        "educationTotalAmount": defaultAmount,
        "finalAmount": defaultAmount,
        "homeCareAmount": defaultAmount,
        "homeCareTotalAmount": defaultAmount,
        "livingAmount": defaultAmount,
        "livingTotalAmount": defaultAmount,
        "lumpSumPayment": defaultAmount,
        "otherCashAmount": defaultAmount,
        "rentAmount": defaultAmount,
        "rentTotalAmount": defaultAmount,
        "savingContribution": defaultAmount,
        "savingTotalAmount": defaultAmount,
        "totalAmount": defaultAmount
    }
    return {
        ...defaults,
        ...inputs
    }
};

const generateIncome = (inputs) => {
    const defaults = {
        "extraYear": defaultNumber,
        "replaceMonthCount": defaultNumber,
        "replacePartnerMonthCount": defaultNumber,
        "capitalFund": eCapitalFundType.deplete,
        "partnerAvailableAmount": defaultNumber,

        "averageTaxRate": defaultAmount,
        "benefitAmount": defaultAmount,
        "familyPercentage": defaultAmount,
        "householdAmount": defaultAmount,
        "maxInsuranceLimit": defaultAmount,
        "monthlyAmount": defaultAmount,
        "monthlyInsuranceAmount": defaultAmount,
        "monthlyNonTaxableBenefit": defaultAmount,
        "monthlyTaxableBenefit": defaultAmount,
        "monthlyOtherAmount": defaultAmount,
        "partnerAvailableIncome": defaultAmount,
        "replaceAmount": defaultAmount,
        "replacePartnerAmount": defaultAmount,
        "returnRatePercentage": defaultAmount,
        "shortageAmount": defaultAmount,
        "totalFamilyAmount": defaultAmount,
        "totalAmount": defaultAmount,
        "totalPartnerAmount": defaultAmount,
        "totalReplaceAmount": defaultAmount
    }
    delete inputs.employmentStatus;
    return {
        ...defaults,
        ...inputs
    }
};

export const generateCoverage = (
    coverageId,
    partyId,
    product,
    assetInputs = null,
    expenseInputs = null,
    incomeInputs = null
) => {
    return {
        coverageId,
        partyId,
        product,
        "asset": assetInputs ? generateAsset(assetInputs) : null,
        "expense": expenseInputs ? generateExpense(expenseInputs) : null,
        "income": incomeInputs ? generateIncome(incomeInputs) : null
    }
};