import React from "react";
import {
  Stack,
  useLang,
  rem,
  CheckboxGroup,
  Button,
  colors,
  BREAKPOINTS,
  TextLink,
} from "@manulife/mux";
import { Download, Email } from "@manulife/mux-cds-icons";
import {
  Divider,
  NormalText,
  SectionLabel,
} from "../../assets/appStyledComponents";
import { emailResult } from "./EmailTemplates/EmailTemplate";
import { formatDate } from "../../services/date/dateProcessors";
import ResultConstants from "../../assets/resultConstants";
import NumberConstants from "../../assets/appStyledComponents/numberConstant.json";
import useWindowSize from "../../customHooks/useWindowSize";
import { downloadPDF } from "../../services/helpers/download";
import { useDispatch, useSelector } from "react-redux";
import { fetchReport } from "../../appRedux/IrCalculator/Actions";
import { eCalculatorTab } from "../../services/helpers/udmConstants";
import { updateChecks } from "../../appRedux/IrResultCheckbox/Actions";

function IrResultWrapper({ children }) {
  const { width: windowWidth } = useWindowSize();
  const { num12, num24, num32, num20, num380 } = NumberConstants;
  const selectedLang = useLang();
  const dispatch = useDispatch();
  const {
    isDownloading,
    data: { result: ResultData, step1: PageData, step2: TabData },
  } = useSelector((state) => state.irCalculatorReducer);
  const checkedBtns = useSelector((state) => state.irResultCheckboxReducer);
  // const [disabled, setDisabled] = useState(true);
  const {
    sectionHeading,
    resultText,
    linkText,
    groupCheckbox,
    checkboxOptions,
    viewPDF,
    email,
  } = ResultConstants;

  const isCheckBoxEnable = (result) => {
    if (result?.[eCalculatorTab.life]?.status === "Success") {
      return false;
    } else if (result?.[eCalculatorTab.criticalIllness]?.status === "Success") {
      return false;
    } else if (result?.[eCalculatorTab.disability]?.status === "Success") {
      return false;
    }
    return true;
  }

  const isPdfEmailBtnEnable = (checkedBtns) => {
    if (checkedBtns?.[eCalculatorTab.life] === true) {
      return false;
    } else if (checkedBtns?.[eCalculatorTab.criticalIllness] === true) {
      return false;
    } else if (checkedBtns?.[eCalculatorTab.disability] === true) {
      return false;
    }
    return true;
  }

  return (
    <Stack
      style={{
        padding:
          windowWidth > BREAKPOINTS.MAX_768
            ? rem(0)
            : `${rem(0)} ${rem(num24)}`,
      }}
    >
      <Stack
        align="flex-start"
        style={{
          width: windowWidth > BREAKPOINTS.MAX_1280 ? rem(num380) : "100%",
          padding: rem(num20),
          flexDirection: `column`,
          alignSelf: `stretch`,
          border: `1px solid var(--neutral-dark-3-grey, #C2C3C9)`,
        }}
      >
        <Stack align="flex-start" gap={rem(num32)}>
          <Stack align="flex-start" gap={rem(num12)}>
            <SectionLabel data-testid="sectionLabel">
              {sectionHeading[`${selectedLang}`]}
            </SectionLabel>
            <NormalText data-testid="dateColumn">
              {formatDate("resultSection", selectedLang)}
            </NormalText>
          </Stack>
          {children}
          <NormalText>
            {resultText[`${selectedLang}`]}
            <TextLink
              children={ResultConstants.linkText.label[`${selectedLang}`]}
              to={linkText.URL[`${selectedLang}`]}
              aria-label={linkText.label[`${selectedLang}`]}
              target="_blank"
              rel="noopener noreferrer"
              isDocument
            />
          </NormalText>
          <Stack align="flex-start" gap={rem(num20)}>
            <Divider />
            <CheckboxGroup
              data-testid="groupCheckbox"
              id="group-checkbox"
              ariaRequired={true}
              legend={groupCheckbox[`${selectedLang}`]}
              onChange={(value) => dispatch(updateChecks(value))}
              selected={checkedBtns}
              values={checkboxOptions[`${selectedLang}`].options.map(opt => {
                return {
                  ...opt,
                  "disabled": ResultData?.[opt.value]?.status === "Success" ? false : true
                };
              })}
              disabled={isCheckBoxEnable(ResultData)}
            />
            <Stack direction="row" gap={rem(num20)}>
              <Button
                data-testid="pdf"
                variant={Button.VARIANT.TERTIARY_NOICON}
                icon={<Download color={colors.m_green} />}
                disabled={isPdfEmailBtnEnable(checkedBtns)}
                saving={isDownloading}
                ariaLabel={viewPDF[`${selectedLang}`]}
                onClick={(v, e) => {
                  e.preventDefault();
                  if (!ResultData?.illustration?.report?.data) {
                    dispatch(
                      fetchReport(
                        {
                          ...TabData,
                          isDownloadPDF: true,
                        },
                        selectedLang,
                        downloadPDF
                      )
                    );
                  } else {
                    downloadPDF(ResultData?.illustration?.report?.data);
                  }
                }}
                customStyle={{ buttonStyle: { padding: `0` } }}
              >
                {viewPDF[`${selectedLang}`]}
              </Button>
              <Button
                data-testid="email"
                variant={Button.VARIANT.TERTIARY_NOICON}
                icon={<Email color={colors.m_green} />}
                disabled={isPdfEmailBtnEnable(checkedBtns)}
                ariaLabel={email[`${selectedLang}`]}
                customStyle={{ buttonStyle: { padding: `0` } }}
                onClick={async (v, e) => {
                  e.preventDefault();
                  await emailResult(selectedLang, PageData, ResultData, checkedBtns);
                }}
              >
                {email[`${selectedLang}`]}
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default IrResultWrapper;
