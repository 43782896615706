import React, { useEffect, useCallback } from 'react'
import { IrTabSecWrapper, secComponents } from '../IrCommon';
import LifeInputs from './IrLifeInputs.json';
import { useLang } from '@manulife/mux';
import { eChoice, eForWhom } from '../../services/helpers/udmConstants';
import { useFormContext } from 'react-hook-form';
import { addCurrencySign, getAmountNumbers } from '../../services/helpers/commonHelpers';

function IrDebtsAndExpenses({ name1, name2, selectedIrInsuranceFor, tabName, storedAboutYouData }) {

    const selectedLang = useLang();
    const {
        deptsAndExpenses: {
            payOffMortage,
            payOffDepts,
            amtFinalExpense,
            savePostSecEducationAmt,
            xYears,
            xNumberOfChildren,
            amtForChildHC,
            xYearsHC,
            xNumberOfChildrenHC,
            otherCashNeeds,
            totalAmount,
            secTitle,
            secSubTitle,
            subTotalEdu,
            subTotalHC,
        }
    } = LifeInputs;

    const { getValues, setValue } = useFormContext();

    const SecName = "IrDebtsAndExpenses";

    const commonProps = {
        name1,
        name2,
        selectedIrInsuranceFor,
        tabName,
        secName: SecName,
    }

    const noDisplayRow = Number(storedAboutYouData.irChildCount) === 0 || storedAboutYouData.irHaveMoney === eChoice.yes
    const noDisplayRowHC = Number(storedAboutYouData.irChildCount) === 0;

    const calculateSubtotalEdu = useCallback(
        (forWhom, name) => {
            const you = forWhom === eForWhom.you;

            const payMortage = Number(getAmountNumbers(getValues(`${tabName}.${SecName}.${eForWhom.you}.${payOffMortage.name}`))) || 0;
            const payDepts = Number(getAmountNumbers(getValues(`${tabName}.${SecName}.${eForWhom.you}.${payOffDepts.name}`))) || 0;
            const amtFinalExp = Number(getAmountNumbers(getValues(`${tabName}.${SecName}.${eForWhom.you}.${amtFinalExpense.name}`))) || 0;
            const otherCashNeed = Number(getAmountNumbers(getValues(`${tabName}.${SecName}.${eForWhom.you}.${otherCashNeeds.name}`))) || 0;

            const educationAmt = Number(getAmountNumbers(getValues(`${tabName}.${SecName}.${eForWhom.you}.${savePostSecEducationAmt.name}`))) || 0;
            const years = Number(getAmountNumbers(getValues(`${tabName}.${SecName}.${eForWhom.you}.${xYears.name}`))) || 0;
            const Children = Number(getAmountNumbers(getValues(`${tabName}.${SecName}.${eForWhom.you}.${xNumberOfChildren.name}`))) || 0;

            const HCAmt = Number(getAmountNumbers(getValues(`${tabName}.${SecName}.${eForWhom.you}.${amtForChildHC.name}`))) || 0;
            const yearsHC = Number(getAmountNumbers(getValues(`${tabName}.${SecName}.${eForWhom.you}.${xYearsHC.name}`))) || 0;
            const ChildrenHC = Number(getAmountNumbers(getValues(`${tabName}.${SecName}.${eForWhom.you}.${xNumberOfChildrenHC.name}`))) || 0;

            const partnerPayOffMortage = you && name === payOffMortage.name ? payMortage : Number(getAmountNumbers(getValues(`${tabName}.${SecName}.${eForWhom.partner}.${payOffMortage.name}`))) || 0;
            const partnerPayOffDepts = you && name === payOffDepts.name ? payDepts : Number(getAmountNumbers(getValues(`${tabName}.${SecName}.${eForWhom.partner}.${payOffDepts.name}`))) || 0;
            const partnerAmtFinalExpense = you && name === amtFinalExpense.name ? amtFinalExp : Number(getAmountNumbers(getValues(`${tabName}.${SecName}.${eForWhom.partner}.${amtFinalExpense.name}`))) || 0;
            const partnerOtherCashNeeds = you && name === otherCashNeeds.name ? otherCashNeed : Number(getAmountNumbers(getValues(`${tabName}.${SecName}.${eForWhom.partner}.${otherCashNeeds.name}`))) || 0;

            const partnerEducationAmt = you && name === savePostSecEducationAmt.name ? educationAmt : Number(getAmountNumbers(getValues(`${tabName}.${SecName}.${eForWhom.partner}.${savePostSecEducationAmt.name}`))) || 0;
            const partnerYears = you && name === xYears.name ? years : Number(getAmountNumbers(getValues(`${tabName}.${SecName}.${eForWhom.partner}.${xYears.name}`))) || 0;
            const partnerChildren = you && name === xNumberOfChildren.name ? Children : Number(getAmountNumbers(getValues(`${tabName}.${SecName}.${eForWhom.partner}.${xNumberOfChildren.name}`))) || 0;

            const partnerHCAmt = you && name === amtForChildHC.name ? HCAmt : Number(getAmountNumbers(getValues(`${tabName}.${SecName}.${eForWhom.partner}.${amtForChildHC.name}`))) || 0;
            const partnerYearsHC = you && name === xYearsHC.name ? yearsHC : Number(getAmountNumbers(getValues(`${tabName}.${SecName}.${eForWhom.partner}.${xYearsHC.name}`))) || 0;
            const partnerChildrenHC = you && name === xNumberOfChildrenHC.name ? ChildrenHC : Number(getAmountNumbers(getValues(`${tabName}.${SecName}.${eForWhom.partner}.${xNumberOfChildrenHC.name}`))) || 0;

            const yourValue = payMortage + payDepts + amtFinalExp + (educationAmt * years * Children);
            const partnerValue = partnerPayOffMortage +
                partnerPayOffDepts + partnerAmtFinalExpense + (partnerEducationAmt * partnerYears * partnerChildren);

            if (forWhom === eForWhom.you) {
                setValue(`${tabName}.${SecName}.${eForWhom.you}.${subTotalEdu.name}`, addCurrencySign(yourValue, selectedLang));
                setValue(`${tabName}.${SecName}.${eForWhom.partner}.${subTotalEdu.name}`, addCurrencySign(partnerValue, selectedLang));
            } else {
                setValue(`${tabName}.${SecName}.${eForWhom.partner}.${subTotalEdu.name}`, addCurrencySign(partnerValue, selectedLang));
            }

            const payOff = payMortage + payDepts + amtFinalExp + (educationAmt * years * Children) + (HCAmt * yearsHC * ChildrenHC) + otherCashNeed;
            const partnerPayoff = partnerPayOffMortage +
                partnerPayOffDepts + partnerAmtFinalExpense + (partnerEducationAmt * partnerYears * partnerChildren) + (partnerHCAmt * partnerYearsHC * partnerChildrenHC) +
                partnerOtherCashNeeds;

            if (forWhom === eForWhom.you) {
                setValue(`${tabName}.${SecName}.${eForWhom.you}.${totalAmount.name}`, addCurrencySign(payOff, selectedLang));
                setValue(`${tabName}.${SecName}.${eForWhom.partner}.${totalAmount.name}`, addCurrencySign(partnerPayoff, selectedLang));
            } else {
                setValue(`${tabName}.${SecName}.${eForWhom.partner}.${totalAmount.name}`, addCurrencySign(partnerPayoff, selectedLang));
            }
        },
        [amtFinalExpense.name, amtForChildHC.name, getValues, otherCashNeeds.name, payOffDepts.name, payOffMortage.name, savePostSecEducationAmt.name, selectedLang, setValue, subTotalEdu.name, tabName, totalAmount.name, xNumberOfChildren.name, xNumberOfChildrenHC.name, xYears.name, xYearsHC.name],
    )

    useEffect(() => {
        setValue(`${tabName}.${SecName}.${eForWhom.you}.${totalAmount.name}`, addCurrencySign(0, selectedLang));
        setValue(`${tabName}.${SecName}.${eForWhom.partner}.${totalAmount.name}`, addCurrencySign(0, selectedLang));

        if (!getValues(`${tabName}.${SecName}.${eForWhom.you}.${subTotalHC.name}`)) {
            setValue(`${tabName}.${SecName}.${eForWhom.you}.${subTotalHC.name}`, addCurrencySign(0, selectedLang));
        }
        if (!getValues(`${tabName}.${SecName}.${eForWhom.partner}.${subTotalHC.name}`)) {
            setValue(`${tabName}.${SecName}.${eForWhom.partner}.${subTotalHC.name}`, addCurrencySign(0, selectedLang));
        }
        if (!noDisplayRow) {
            setValue(`${tabName}.${SecName}.${eForWhom.you}.${xNumberOfChildren.name}`, addCurrencySign(Number(storedAboutYouData.irChildCount), selectedLang));
            setValue(`${tabName}.${SecName}.${eForWhom.partner}.${xNumberOfChildren.name}`, addCurrencySign(Number(storedAboutYouData.irChildCount), selectedLang));
            setValue(`${tabName}.${SecName}.${eForWhom.you}.${xNumberOfChildrenHC.name}`, addCurrencySign(Number(storedAboutYouData.irChildCount), selectedLang));
            setValue(`${tabName}.${SecName}.${eForWhom.partner}.${xNumberOfChildrenHC.name}`, addCurrencySign(Number(storedAboutYouData.irChildCount), selectedLang));
        }
        calculateSubtotalEdu(eForWhom.you, xNumberOfChildren.name)
        calculateSubtotalEdu(eForWhom.partner, xNumberOfChildren.name)
    }, [calculateSubtotalEdu, noDisplayRow, selectedLang, getValues, setValue, storedAboutYouData.irChildCount, subTotalHC.name, tabName, totalAmount.name, xNumberOfChildren.name, xNumberOfChildrenHC.name])

    const calculateSubtotalHC = (forWhom, name) => {
        const you = forWhom === eForWhom.you;

        const payMortage = Number(getAmountNumbers(getValues(`${tabName}.${SecName}.${eForWhom.you}.${payOffMortage.name}`))) || 0
        const payDepts = Number(getAmountNumbers(getValues(`${tabName}.${SecName}.${eForWhom.you}.${payOffDepts.name}`))) || 0
        const amtFinalExp = Number(getAmountNumbers(getValues(`${tabName}.${SecName}.${eForWhom.you}.${amtFinalExpense.name}`))) || 0
        const otherCashNeed = Number(getAmountNumbers(getValues(`${tabName}.${SecName}.${eForWhom.you}.${otherCashNeeds.name}`))) || 0

        const educationAmt = Number(getAmountNumbers(getValues(`${tabName}.${SecName}.${eForWhom.you}.${savePostSecEducationAmt.name}`))) || 0
        const years = Number(getAmountNumbers(getValues(`${tabName}.${SecName}.${eForWhom.you}.${xYears.name}`))) || 0
        const Children = Number(getAmountNumbers(getValues(`${tabName}.${SecName}.${eForWhom.you}.${xNumberOfChildren.name}`))) || 0

        const HCAmt = Number(getAmountNumbers(getValues(`${tabName}.${SecName}.${eForWhom.you}.${amtForChildHC.name}`))) || 0
        const yearsHC = Number(getAmountNumbers(getValues(`${tabName}.${SecName}.${eForWhom.you}.${xYearsHC.name}`))) || 0
        const ChildrenHC = Number(getAmountNumbers(getValues(`${tabName}.${SecName}.${eForWhom.you}.${xNumberOfChildrenHC.name}`))) || 0

        const partnerPayOffMortage = you && name === payOffMortage.name ? payMortage : Number(getAmountNumbers(getValues(`${tabName}.${SecName}.${eForWhom.partner}.${payOffMortage.name}`))) || 0
        const partnerPayOffDepts = you && name === payOffDepts.name ? payDepts : Number(getAmountNumbers(getValues(`${tabName}.${SecName}.${eForWhom.partner}.${payOffDepts.name}`))) || 0
        const partnerAmtFinalExpense = you && name === amtFinalExpense.name ? amtFinalExp : Number(getAmountNumbers(getValues(`${tabName}.${SecName}.${eForWhom.partner}.${amtFinalExpense.name}`))) || 0
        const partnerOtherCashNeeds = you && name === otherCashNeeds.name ? otherCashNeed : Number(getAmountNumbers(getValues(`${tabName}.${SecName}.${eForWhom.partner}.${otherCashNeeds.name}`))) || 0

        const partnerEducationAmt = you && name === savePostSecEducationAmt.name ? educationAmt : Number(getAmountNumbers(getValues(`${tabName}.${SecName}.${eForWhom.partner}.${savePostSecEducationAmt.name}`))) || 0
        const partnerYears = you && name === xYears.name ? years : Number(getAmountNumbers(getValues(`${tabName}.${SecName}.${eForWhom.partner}.${xYears.name}`))) || 0
        const partnerChildren = you && name === xNumberOfChildren.name ? Children : Number(getAmountNumbers(getValues(`${tabName}.${SecName}.${eForWhom.partner}.${xNumberOfChildren.name}`))) || 0

        const partnerHCAmt = you && name === amtForChildHC.name ? HCAmt : Number(getAmountNumbers(getValues(`${tabName}.${SecName}.${eForWhom.partner}.${amtForChildHC.name}`))) || 0
        const partnerYearsHC = you && name === xYearsHC.name ? yearsHC : Number(getAmountNumbers(getValues(`${tabName}.${SecName}.${eForWhom.partner}.${xYearsHC.name}`))) || 0
        const partnerChildrenHC = you && name === xNumberOfChildrenHC.name ? ChildrenHC : Number(getAmountNumbers(getValues(`${tabName}.${SecName}.${eForWhom.partner}.${xNumberOfChildrenHC.name}`))) || 0

        const yourValue = HCAmt * yearsHC * ChildrenHC
        const partnerValue = partnerHCAmt * partnerYearsHC * partnerChildrenHC

        if (forWhom === eForWhom.you) {
            setValue(`${tabName}.${SecName}.${eForWhom.you}.${subTotalHC.name}`, addCurrencySign(yourValue, selectedLang));
            setValue(`${tabName}.${SecName}.${eForWhom.partner}.${subTotalHC.name}`, addCurrencySign(partnerValue, selectedLang));
        } else {
            setValue(`${tabName}.${SecName}.${eForWhom.partner}.${subTotalHC.name}`, addCurrencySign(partnerValue, selectedLang));
        }

        const payOff = payMortage + payDepts + amtFinalExp + (educationAmt * years * Children) + (HCAmt * yearsHC * ChildrenHC) + otherCashNeed
        const partnerPayoff = partnerPayOffMortage +
            partnerPayOffDepts + partnerAmtFinalExpense + (partnerEducationAmt * partnerYears * partnerChildren) + (partnerHCAmt * partnerYearsHC * partnerChildrenHC) +
            partnerOtherCashNeeds

        if (forWhom === eForWhom.you) {
            setValue(`${tabName}.${SecName}.${eForWhom.you}.${totalAmount.name}`, addCurrencySign(payOff, selectedLang));
            setValue(`${tabName}.${SecName}.${eForWhom.partner}.${totalAmount.name}`, addCurrencySign(partnerPayoff, selectedLang));
        } else {
            setValue(`${tabName}.${SecName}.${eForWhom.partner}.${totalAmount.name}`, addCurrencySign(partnerPayoff, selectedLang));
        }
    }

    const DebtsAndExpensesComponentList = [
        {
            compName: "IrTabTblHeader",
            compProps: {}
        },
        {
            compName: "IrTabTblRowAmount",
            compProps: {
                rowLabel: payOffMortage.label[selectedLang],
                keyName: payOffMortage.name,
                maxAmount: payOffMortage.maxValue,
                isBlurUpdateNext: true,
                onYouBlur: () => calculateSubtotalEdu(eForWhom.you, payOffMortage.name),
                onPartnerBlur: () => calculateSubtotalEdu(eForWhom.partner, payOffMortage.name)
            }
        },
        {
            compName: "IrTabTblRowAmount",
            compProps: {
                rowLabel: payOffDepts.label[selectedLang],
                rowSubLabel: payOffDepts.subLabel[selectedLang],
                keyName: payOffDepts.name,
                maxAmount: payOffDepts.maxValue,
                isBlurUpdateNext: true,
                onYouBlur: () => calculateSubtotalEdu(eForWhom.you, payOffDepts.name),
                onPartnerBlur: () => calculateSubtotalEdu(eForWhom.partner, payOffDepts.name)
            }
        },
        {
            compName: "IrTabTblRowAmount",
            compProps: {
                rowLabel: amtFinalExpense.label[selectedLang],
                keyName: amtFinalExpense.name,
                maxAmount: amtFinalExpense.maxValue,
                tooltipText: amtFinalExpense.toolTip[selectedLang],
                tooltipAriaLabel: amtFinalExpense.tooltipAriaLabel[selectedLang],
                tooltipWidth: amtFinalExpense.tooltipWidth,
                isBlurUpdateNext: true,
                onYouBlur: () => calculateSubtotalEdu(eForWhom.you, amtFinalExpense.name),
                onPartnerBlur: () => calculateSubtotalEdu(eForWhom.partner, amtFinalExpense.name)
            }
        },
        {
            compName: "IrTabTblRowAmount",
            compProps: {
                rowLabel: savePostSecEducationAmt.label[selectedLang],
                keyName: savePostSecEducationAmt.name,
                maxAmount: savePostSecEducationAmt.maxValue,
                tooltipText: savePostSecEducationAmt.toolTip[selectedLang],
                tooltipAriaLabel: savePostSecEducationAmt.tooltipAriaLabel[selectedLang],
                tooltipWidth: amtFinalExpense.tooltipWidth,
                isBlurUpdateNext: true,
                noDisplay: noDisplayRow,
                onYouBlur: () => calculateSubtotalEdu(eForWhom.you, savePostSecEducationAmt.name),
                onPartnerBlur: () => calculateSubtotalEdu(eForWhom.partner, savePostSecEducationAmt.name)
            }
        },
        {
            compName: "IrTabTblRowNumber",
            compProps: {
                rowLabel: xYears.label[selectedLang],
                keyName: xYears.name,
                maxNumber: xYears.maxValue,
                isBlurUpdateNext: true,
                noDisplay: noDisplayRow,
                onYouBlur: () => calculateSubtotalEdu(eForWhom.you, xYears.name),
                onPartnerBlur: () => calculateSubtotalEdu(eForWhom.partner, xYears.name)
            }
        },
        {
            compName: "IrTabTblRowNumber",
            compProps: {
                rowLabel: xNumberOfChildren.label[selectedLang],
                keyName: xNumberOfChildren.name,
                maxNumber: xNumberOfChildren.maxValue,
                isBlurUpdateNext: true,
                noDisplay: noDisplayRow,
                onYouBlur: () => calculateSubtotalEdu(eForWhom.you, xNumberOfChildren.name),
                onPartnerBlur: () => calculateSubtotalEdu(eForWhom.partner, xNumberOfChildren.name)
            }
        },
        {
            compName: "IrTabTblRowTotal",
            compProps: {
                rowLabel: subTotalEdu.label[selectedLang],
                bgColor: "light_grey",
                isKeyAsValue: true,
                noDisplay: noDisplayRow,
                value1: `${tabName}.${SecName}.${eForWhom.you}.${subTotalEdu.name}`,
                value2: `${tabName}.${SecName}.${eForWhom.partner}.${subTotalEdu.name}`,
            }
        },
        {
            compName: "IrTabTblRowAmount",
            compProps: {
                rowLabel: amtForChildHC.label[selectedLang],
                keyName: amtForChildHC.name,
                maxAmount: amtForChildHC.maxValue,
                isBlurUpdateNext: true,
                noDisplay: noDisplayRowHC,
                onYouBlur: () => calculateSubtotalHC(eForWhom.you, amtForChildHC.name),
                onPartnerBlur: () => calculateSubtotalHC(eForWhom.partner, amtForChildHC.name)
            }
        },

        {
            compName: "IrTabTblRowNumber",
            compProps: {
                rowLabel: xYearsHC.label[selectedLang],
                keyName: xYearsHC.name,
                maxNumber: xYearsHC.maxValue,
                isBlurUpdateNext: true,
                noDisplay: noDisplayRowHC,
                onYouBlur: () => calculateSubtotalHC(eForWhom.you, xYearsHC.name),
                onPartnerBlur: () => calculateSubtotalHC(eForWhom.partner, xYearsHC.name)
            }
        },
        {
            compName: "IrTabTblRowNumber",
            compProps: {
                rowLabel: xNumberOfChildrenHC.label[selectedLang],
                keyName: xNumberOfChildrenHC.name,
                maxNumber: xNumberOfChildrenHC.maxValue,
                isBlurUpdateNext: true,
                noDisplay: noDisplayRowHC,
                onYouBlur: () => calculateSubtotalHC(eForWhom.you, xNumberOfChildrenHC.name),
                onPartnerBlur: () => calculateSubtotalHC(eForWhom.partner, xNumberOfChildrenHC.name)
            }
        },
        {
            compName: "IrTabTblRowTotal",
            compProps: {
                rowLabel: subTotalHC.label[selectedLang],
                bgColor: "light_grey",
                isKeyAsValue: true,
                noDisplay: noDisplayRowHC,
                value1: `${tabName}.${SecName}.${eForWhom.you}.${subTotalHC.name}`,
                value2: `${tabName}.${SecName}.${eForWhom.partner}.${subTotalHC.name}`,
            }
        },
        {
            compName: "IrTabTblRowAmount",
            compProps: {
                rowLabel: otherCashNeeds.label[selectedLang],
                keyName: otherCashNeeds.name,
                maxAmount: otherCashNeeds.maxValue,
                tooltipText: otherCashNeeds.toolTip[selectedLang],
                tooltipAriaLabel: otherCashNeeds.tooltipAriaLabel[selectedLang],
                isBlurUpdateNext: true,
                onYouBlur: () => calculateSubtotalEdu(eForWhom.you, otherCashNeeds.name),
                onPartnerBlur: () => calculateSubtotalEdu(eForWhom.partner, otherCashNeeds.name)
            }
        },
        {
            compName: "IrTabTblRowTotal",
            compProps: {
                rowLabel: totalAmount.label[selectedLang],
                isKeyAsValue: true,
                value1: `${tabName}.${SecName}.${eForWhom.you}.${totalAmount.name}`,
                value2: `${tabName}.${SecName}.${eForWhom.partner}.${totalAmount.name}`,
                isTopLine: true,
                isBottomLine: false
            }
        }
    ];

    return (
        <IrTabSecWrapper secTitle={secTitle[selectedLang]} secSubTitle={secSubTitle[selectedLang]}>
            {
                DebtsAndExpensesComponentList.map((componentOdj, index) => {
                    const { compName, compProps } = componentOdj;
                    if (!compProps.noDisplay) {
                        return React.createElement(secComponents[compName], {
                            key: `${Date.now()}${index}`,
                            ...commonProps,
                            ...compProps
                        })
                    } else {
                        return null;
                    }
                })
            }
        </IrTabSecWrapper>
    )
}

export default React.memo(IrDebtsAndExpenses);