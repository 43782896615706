import { useLang, rem, Stack, colors } from "@manulife/mux";
import { ToiletAccessible } from "@manulife/mux-cds-icons";
import {
  NormalText,
  SectionLabel,
  InsuranceAmt,
} from "../../assets/appStyledComponents";
import ResultConstants from "../../assets/resultConstants";
import DisabilityResultConstants from "../../assets/resultDisabilityConstants.json";
import NumberConstants from "../../assets/appStyledComponents/numberConstant.json";
import { formatAmount } from "../../services/helpers/commonHelpers";

function DisabilityDetails({ name, insuranceAmount }) {
  const { num20, num8 } = NumberConstants;
  const selectedLang = useLang();
  const getName = (name) => {
    if (name === "you") {
      return ResultConstants.name[selectedLang];
    } else if (name === "partner") {
      return ResultConstants.partnerName[selectedLang];
    } else {
      return name;
    }
  };

  return (
    <>
      <Stack
        align="flex-start"
        style={{ alignSelf: "stretch" }}
        gap={rem(num20)}
      >
        <SectionLabel>{getName(name)}</SectionLabel>
        <Stack direction="row" gapX="0">
          <Stack style={{ width: "10%" }}>
            <ToiletAccessible
              fontSize={rem(num20)}
              color={colors.dark_navy}
              style={{ marginRight: rem(num8) }}
            />
          </Stack>
          <Stack style={{ width: "90%" }}>
            {DisabilityResultConstants.productName[`${selectedLang}`]}
          </Stack>
        </Stack>
        <Stack
          align="flex-start"
          style={{ alignSelf: "stretch" }}
          gap={rem(num8)}
        >
          <NormalText>
            {DisabilityResultConstants.insuranceAmt[`${selectedLang}`]}
          </NormalText>
          <InsuranceAmt>
            {formatAmount(insuranceAmount, selectedLang)}
          </InsuranceAmt>
        </Stack>
        <Stack
          align="flex-start"
          style={{ alignSelf: "stretch" }}
          gap={rem(num8)}
        >
          <NormalText>
            {DisabilityResultConstants.costAmt[`${selectedLang}`]}
          </NormalText>
          <InsuranceAmt>
            {DisabilityResultConstants.costAmtValue[`${selectedLang}`]}
          </InsuranceAmt>
        </Stack>
      </Stack>
    </>
  );
};

export default DisabilityDetails;
