import React, { useEffect } from 'react';
import { BREAKPOINTS, Button, ButtonSelector, Stack, TextInput, rem, useLang } from "@manulife/mux";
import { colors } from '@manulife/mux';
import { NormalText, SectionHeadding } from '../../assets/appStyledComponents';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import IrAboutYouInputs from './IrAboutYouInputs';
import LABELS from '../../assets/labels';
import { eForWhom, eIncluded } from '../../services/helpers/udmConstants';
import NumberConstants from '../../assets/appStyledComponents/numberConstant.json';
import useWindowSize from '../../customHooks/useWindowSize';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetCalculatorDataResult, setAboutYouData } from '../../appRedux/IrCalculator/Actions';
import { addCurrencySign, getAmountNumbers, getOnlyNumbers } from '../../services/helpers/commonHelpers';
import { resetChecks } from '../../appRedux/IrResultCheckbox/Actions';

function IrAboutYouForm() {
    const { width: windowWidth } = useWindowSize();
    const selectedLang = useLang();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const storedAboutYouData = useSelector(
        (state) => state.irCalculatorReducer.data.step1
    );
    const { num10, num20, num24, num32, num40, num300 } = NumberConstants;
    const methods = useForm({
        defaultValues: {
            ...storedAboutYouData,
            you: {
                ...storedAboutYouData.you,
                irName: storedAboutYouData.you.irName === eForWhom.you ? "" : storedAboutYouData.you.irName,
                irAnnualIncome: addCurrencySign(getAmountNumbers(storedAboutYouData.you.irAnnualIncome), selectedLang)
            },
            partner: {
                ...storedAboutYouData.partner,
                irName: storedAboutYouData.partner.irName === eForWhom.partner ? "" : storedAboutYouData.partner.irName,
                irAnnualIncome: addCurrencySign(getAmountNumbers(storedAboutYouData.partner.irAnnualIncome), selectedLang)
            }
        }
    });
    const { formState: { isSubmitted }, control, handleSubmit, trigger, watch, setValue, getValues } = methods;

    useEffect(() => {
        if (isSubmitted) {
            trigger();
        }
        return () => null
    }, [isSubmitted, selectedLang, trigger]);

    useEffect(() => {
        const you = getValues('you')
        const partner = getValues('partner')
        if (storedAboutYouData?.you?.irEmploymentStatus) {
            setValue("you", {
                ...you,
                irEmploymentStatus: storedAboutYouData?.you?.irEmploymentStatus
            })
        }
        if (storedAboutYouData?.partner?.irEmploymentStatus) {
            setValue("partner", {
                ...partner,
                irEmploymentStatus: storedAboutYouData?.partner?.irEmploymentStatus
            })
        }
    }, [getValues, setValue, storedAboutYouData?.you?.irEmploymentStatus,
        storedAboutYouData?.partner?.irEmploymentStatus]);

    useEffect(() => {
        const you = getValues('you')
        const partner = getValues('partner')

        if (storedAboutYouData?.you?.irAnnualIncome) {
            setValue("you", {
                ...you,
                irAnnualIncome: storedAboutYouData?.you?.irAnnualIncome
            })
        }
        if (storedAboutYouData?.partner?.irAnnualIncome) {
            setValue("partner", {
                ...partner,
                irAnnualIncome: storedAboutYouData?.partner?.irAnnualIncome
            })
        }

    }, [getValues, setValue, storedAboutYouData?.you?.irAnnualIncome,
        storedAboutYouData?.partner?.irAnnualIncome]);

    const selectedIrInsuranceFor = watch("irInsuranceFor");

    const enteredIrChildCount = watch("irChildCount");

    useEffect(() => {
        if (selectedIrInsuranceFor === eIncluded.justMe) {
            setValue("partner", {
                "irName": "",
                "irAge": "",
                "irSex": "",
                "irSmokingStatus": "",
                "irEmploymentStatus": "",
                "irAnnualIncome": "0"
            })
        }
        return () => null
    }, [selectedIrInsuranceFor, setValue])

    const fetchCalculation = (data) => {
        const { you: { irName: youName }, partner: { irName: partnerName } } = data;
        dispatch(resetChecks());
        dispatch(resetCalculatorDataResult());
        dispatch(setAboutYouData({
            ...data,
            you: {
                ...data.you,
                irName: youName === "" ? eForWhom.you : data.you.irName
            },
            partner: {
                ...data.partner,
                irName: partnerName === "" ? eForWhom.partner : data.partner.irName
            }
        }));
        navigate("/calculator");
    };
    return (
        <FormProvider {...methods}>
            <Stack as="form" align="flex-start" gap={`${rem(num40)}`}
                onSubmit={handleSubmit(fetchCalculation)}
                style={{
                    border: `1px solid ${colors.dark_3_light_grey}`,
                    background: `#FFF`,
                    padding: windowWidth > BREAKPOINTS.MAX_768 ? `${rem(num32)} ${rem(num40)}` : `${rem(num40)} ${rem(num24)}`,
                    alignSelf: `stretch`
                }}>
                <Stack align="flex-start" gap={`${rem(num20)}`}>
                    <SectionHeadding>{LABELS.aboutYouHeading[selectedLang]}</SectionHeadding>
                    <NormalText>{LABELS.aboutYouDescription1[selectedLang]}</NormalText>
                    <NormalText>{LABELS.aboutYouDescription2[selectedLang]}</NormalText>
                </Stack>
                <Controller
                    control={control}
                    name={`irInsuranceFor`}
                    render={({
                        field: { onChange, onBlur, value, name },
                        fieldState: { error },
                    }) => (
                        <ButtonSelector
                            data-testid="IrInsuranceForBtn"
                            id={name}
                            label={LABELS.question1[selectedLang].question}
                            ariaLabel={LABELS.question1[selectedLang].ariaLabel}
                            ariaRequired={true}
                            values={LABELS.question1[selectedLang].options}
                            customStyle={{
                                rootStyle: {
                                    width: '50%',
                                    media: [
                                        {
                                            maximum: BREAKPOINTS.MAX_1280,
                                            width: '100%',
                                        },
                                    ],
                                },
                            }}
                            verticalStackingBreakPoint={num300}
                            value={value}
                            onBlur={onBlur}
                            onChange={onChange}
                            errors={error && [{ id: `error-${name}`, isError: true, msg: error.message }]}
                        />
                    )}
                />
                <Stack direction="row" gapX={rem(140)} align="flex-start" style={{ alignSelf: "stretch" }}>
                    <IrAboutYouInputs forWhom={eForWhom.you} />
                    {selectedIrInsuranceFor === eIncluded.meAndPartner && <IrAboutYouInputs forWhom={eForWhom.partner} />}
                </Stack>

                <Stack direction="row" gapX={rem(140)} align="flex-start" style={{ alignSelf: "stretch" }}>
                    <Stack
                        align="flex-start"
                        style={{
                            width: windowWidth > BREAKPOINTS.MAX_1280 ? LABELS.colWidth : "100%"
                        }}
                    >
                        <Controller
                            control={control}
                            name={`irChildCount`}
                            rules={{
                                required: LABELS.errors.required[selectedLang]
                            }}
                            render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: { error },
                            }) => (
                                <TextInput
                                    label={LABELS.question8[selectedLang].question}
                                    ariaLabel={LABELS.question8[selectedLang].ariaLabel}
                                    customStyle={{
                                        rootStyle: {
                                            width: LABELS.colWidth,
                                            media: [
                                                {
                                                    maximum: BREAKPOINTS.MAX_1280,
                                                    width: '100%',
                                                },
                                            ],
                                        },
                                    }}
                                    ref={ref}
                                    value={value}
                                    onBlur={(newValue) => {
                                        /* onChange */
                                        const countInNumber = Number(getOnlyNumbers(newValue));
                                        if (countInNumber > num10) {
                                            onChange(String(num10));
                                        } else {
                                            onChange(String(countInNumber));
                                        }
                                        onBlur()
                                    }}
                                    onChange={(newValue) => onChange(getOnlyNumbers(newValue))}
                                    errors={error && [{ id: `error-${name}`, isError: true, msg: error.message }]}
                                />
                            )}
                        />
                    </Stack>
                    {
                        Number(enteredIrChildCount) > 0 && <Stack
                            align="flex-start"
                            style={{
                                width: windowWidth > BREAKPOINTS.MAX_1280 ? LABELS.colWidth : "100%"
                            }}
                        >
                            <Controller
                                control={control}
                                name={`irHaveMoney`}
                                render={({
                                    field: { onChange, onBlur, value, name },
                                    fieldState: { error },
                                }) => (
                                    <ButtonSelector
                                        id={name}
                                        label={LABELS.question9[selectedLang].question}
                                        ariaLabel={LABELS.question9[selectedLang].ariaLabel}
                                        ariaRequired={true}
                                        values={LABELS.question9[selectedLang].options}
                                        verticalStackingBreakPoint={num300}
                                        value={value}
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        errors={error && [{ id: `error-${name}`, isError: true, msg: error.message }]}
                                    />
                                )}
                            />
                        </Stack>
                    }

                </Stack>
                <Button
                    data-testid="calculateBtn"
                    saving={false}
                    disabled={false}
                    customStyle={{
                        buttonStyle: {
                            media: [
                                {
                                    maximum: BREAKPOINTS.TABLET_PORTRAIT,
                                    width: "100%",
                                },
                            ],
                        },
                    }}
                    ariaLabel={LABELS.aboutYouSubmitBtn[selectedLang].ariaLabel}
                >
                    {LABELS.aboutYouSubmitBtn[selectedLang].label}
                </Button>
            </Stack>
        </FormProvider>
    )
}

export default IrAboutYouForm