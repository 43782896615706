import { LANG } from "@manulife/mux";
import AppURL from "../../assets/url.json";

const replaceKeysWithValues = (str, valueObj) => {
  let newStr = str;
  const keys = Object.keys(valueObj);
  keys.forEach((key) => {
    let regex = new RegExp(`{{${key}}}`, "g");
    newStr = newStr.replace(regex, valueObj[key]);
  });
  return newStr;
};

const changeLang = (selectedLang) => {
  const CURR_ENV_URL_OBJ = AppURL.find(ele => ele[selectedLang].includes(window.location.host));
  window.open(`${CURR_ENV_URL_OBJ[selectedLang === LANG.EN_CA ? LANG.FR_CA : LANG.EN_CA]}${window.location.pathname}`, "_self");
};

const addCurrencySign = (amountInNumber, selectedLang) => {
  switch (selectedLang) {
    case LANG.EN_CA:
      return `$ ${amountInNumber
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;

    case LANG.FR_CA:
      return `${amountInNumber
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")} $`;

    default:
      return 0;
  }
};

const addPercentageSign = (number) => {
  return `${number} %`
}

const formatAmount = (amount = 0, selectedLang) => {
  switch (selectedLang) {
    case LANG.EN_CA:
      return `$${amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;

    case LANG.FR_CA:
      return `${amount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        .replace(".", ",")} $`;

    default:
      return 0;
  }
};

const getAmountNumbers = (amount = 0, isZeroAllowedInFront = false) => {
  const amtInNum = amount.toString().replace(/[^0-9-]+/g, "");
  if (isZeroAllowedInFront) {
    return amtInNum;
  }
  if (Number(amtInNum) === 0 || amtInNum === "") {
    return 0;
  }
  return amtInNum.replace(/^0+/g, "");
};

const getOnlyNumbers = (value = 0, isZeroAllowedInFront = false) => {
  if (isZeroAllowedInFront) {
    return value.toString().replace(/[^0-9]+/g, "");
  }
  return value
    .toString()
    .replace(/[^0-9]+/g, "")
    .replace(/^0+/g, "");
};

const replaceAllKeysAmtToNum = (objToUpdate = {}, keysToExclude = []) => {
  const updatedObj = {};
  for (const key in objToUpdate) {
    if (!keysToExclude.includes(key) && objToUpdate.hasOwnProperty(key)) {
      updatedObj[key] = getAmountNumbers(objToUpdate[key]);
    } else {
      updatedObj[key] = objToUpdate[key]
    }
  }
  return updatedObj;
}

const isObjectEmpty = (objToCheck) => {
  return objToCheck && Object.keys(objToCheck).length === 0 && objToCheck.constructor === Object;
}

export {
  replaceKeysWithValues,
  changeLang,
  formatAmount,
  addCurrencySign,
  addPercentageSign,
  getAmountNumbers,
  getOnlyNumbers,
  replaceAllKeysAmtToNum,
  isObjectEmpty
};