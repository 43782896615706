import axios from 'axios';

const instance = axios.create();
instance.defaults.timeout = 0;

/**
 * private method to initiate an http request
 *
 * @param {*} param0
 */
const _request = ({ endpoint, method, headers, query, body, responseHandler, props }) => {
    const requestConfig = {
        url: endpoint,
        method,
        headers,
        params: query,
        data: body
    };

    instance
        .request(requestConfig)
        .then((response) => {
            responseHandler && responseHandler({ response }, props);
        })
        .catch((error) => {
            responseHandler && responseHandler({ error }, props);
        });
};

/**
 * method to send a get reqeust.
 *
 * @param {*} url
 * @param {*} headers
 * @param {*} query
 * @returns
 */
const get = (url, headers, query) => {
    return (responseHandler, props) => {
        _request({
            endpoint: url,
            method: 'get',
            headers,
            query,
            responseHandler,
            props
        });
    };
};

/**
 * method to send a post reqeust.
 *
 * @param {*} url
 * @param {*} headers
 * @param {*} body
 * @param {*} query
 * @returns
 */
const post = (url, headers, body, query) => {
    return (responseHandler, props) => {
        _request({
            endpoint: url,
            method: 'post',
            headers,
            query,
            body,
            responseHandler,
            props
        });
    };
};

/**
 * method to send a patch reqeust.
 *
 * @param {*} url
 * @param {*} headers
 * @param {*} body
 * @param {*} query
 * @returns
 */
const patch = (url, headers, body, query) => {
    return (responseHandler, props) => {
        _request({
            endpoint: url,
            method: 'patch',
            headers,
            query,
            body,
            responseHandler,
            props
        });
    };
};

/**
 * method to send a put reqeust.
 *
 * @param {*} url
 * @param {*} headers
 * @param {*} body
 * @param {*} query
 * @returns
 */
const put = (url, headers, body, query) => {
    return (responseHandler, props) => {
        _request({
            endpoint: url,
            method: 'put',
            headers,
            query,
            body,
            responseHandler,
            props
        });
    };
};

export { get, post, patch, put };