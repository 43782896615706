import React, { useEffect, useState } from 'react';
import IrHeading from '../components/IrAboutYou/IrHeading';
import { BREAKPOINTS, Stack, Tabs, rem, useLang } from '@manulife/mux';
import NumberConstants from '../assets/appStyledComponents/numberConstant.json';
import useWindowSize from '../customHooks/useWindowSize';
import LABELS from '../assets/labels';
import IrTabBody from '../components/IrCommon/IrTabBody';
import IrLifeInputs from '../components/IrLifeInputs/IrLifeInputs';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import IrDisabilityInputs from '../components/IrDisabilityInputs/IrDisabilityInputs';
import IrCriticalIllnessInputs from '../components/IrCriticalIllnessInputs/IrCriticalIllnessInputs';
import { eCalculatorTab } from '../services/helpers/udmConstants';
import IrLifeResultSection from '../components/IrResultSection/IrLifeResultSection';
import IrDisabilityResultSection from '../components/IrResultSection/IrDisabilityResultSection';
import DefaultValues from './DefaultValues.json'
import IrCIResultSection from '../components/IrResultSection/IrCIResultSection';
import { setCurrTab } from '../appRedux/IrCalculator/Actions';

function IrCalculatorPage() {
    const { width: windowWidth } = useWindowSize();
    const selectedLang = useLang();
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const [currentTab, setCurrentTab] = useState(eCalculatorTab.life);

    const { num30, num32, num40, num64, num50, num100 } = NumberConstants;

    const storedAboutYouData = useSelector(
        (state) => state.irCalculatorReducer.data.step1
    );

    useEffect(() => {
        if (storedAboutYouData.you.irSex === "") {
            navigate("/about", { replace: true })
        }
        return () => null
    }, [navigate, storedAboutYouData]);

    const methods = useForm({
        defaultValues: DefaultValues
    });

    const tabs = [
        {
            id: "tab-1",
            label: LABELS.tabs.life[`${selectedLang}`].label,
            value: eCalculatorTab.life,
        },
        {
            id: "tab-2",
            label: LABELS.tabs.disability[`${selectedLang}`].label,
            value: eCalculatorTab.disability,
        },
        {
            id: "tab-3",
            label: LABELS.tabs.criticalIllness[`${selectedLang}`].label,
            value: eCalculatorTab.criticalIllness,
        },
    ]
    useEffect(() => {
        window.scrollTo({ top: 0 })
    }, []);
    useEffect(() => {
        dispatch(setCurrTab(currentTab));
    }, [currentTab, dispatch]);
    return (
        <Stack data-testid="IrCalculatorPageWrapper" align="flex-start" gap={`${rem(num30)}`} style={{
            maxWidth: `${rem(BREAKPOINTS.DESKTOP_MED)}`,
            padding: windowWidth > BREAKPOINTS.MAX_768 ? `${rem(num40)} ${rem(num64)} ${rem(num50)} ${rem(num64)}` : `${rem(num32)} ${rem(0)} ${rem(0)} ${rem(0)}`
        }}>
            <IrHeading />
            <FormProvider {...methods} >
                <form style={{ width: `${num100}%` }} >
                    <Tabs
                        tabItems={tabs}
                        variant={Tabs.VARIANT.GREY}
                        tabAlignment={Tabs.ALIGNMENT.LEFT}
                        selectedTab={currentTab}
                        onChange={(val) => {
                            setCurrentTab(val);
                        }}
                    >
                        <Tabs.Panel>
                            <IrTabBody leftColComponent={<IrLifeInputs storedAboutYouData={storedAboutYouData} />} rightColComponent={<IrLifeResultSection />} />
                        </Tabs.Panel>
                        <Tabs.Panel>
                            <IrTabBody leftColComponent={<IrDisabilityInputs storedAboutYouData={storedAboutYouData} />} rightColComponent={<IrDisabilityResultSection />} />
                        </Tabs.Panel>
                        <Tabs.Panel>
                            <IrTabBody leftColComponent={<IrCriticalIllnessInputs storedAboutYouData={storedAboutYouData} />} rightColComponent={<IrCIResultSection selectedTab={currentTab} />} />
                        </Tabs.Panel>
                    </Tabs>
                </form>
            </FormProvider>
        </Stack>
    )
}

export default IrCalculatorPage;