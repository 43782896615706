import React, { useEffect } from 'react';
import { IrTabSecWrapper, secComponents } from '../IrCommon';
import { useLang } from '@manulife/mux';
import { eForWhom } from '../../services/helpers/udmConstants';
import DisabilityInputs from './IrDisabilityInputs.json';
import { addCurrencySign, getOnlyNumbers } from '../../services/helpers/commonHelpers';
import { useFormContext, useWatch } from 'react-hook-form';

function IrDisabilityInsuranceOrOtherIncome({ name1, name2, selectedIrInsuranceFor, tabName }) {

    const selectedLang = useLang();

    const { control, setValue, getValues } = useFormContext();

    const SecName = "IrDisabilityInsuranceOrOtherIncome";
    const MonthlyExpense = "IrMonthlyExpenses";
    const currEmployment = "IrCurrentEmploymentIncome";

    const commonProps = {
        name1,
        name2,
        selectedIrInsuranceFor,
        tabName,
        secName: SecName,
    };

    const {
        insuranceOrOtherIncome: {
            secTitle,
            inputComponents: {
                nonTaxableBenefit,
                taxableBenefit,
                avgTaxRate,
                otherMonthlyIncome,
                total
            }
        },
        currentIncome: {
            inputComponents: {
                monthlyIncome,
                total: maxInsuranceTotal
            }
        },
        monthlyExpense: {
            inputComponents: {
                mortageOrRent,
                deptPayments,
                savingContributions,
                otherExpenses
            }
        },
    } = DisabilityInputs;

    useEffect(() => {
        if (!getValues(`${tabName}.${SecName}.${eForWhom.you}.${total.name}`)) {
            setValue(`${tabName}.${SecName}.${eForWhom.you}.${total.name}`, addCurrencySign(0, selectedLang));
        }
        if (!getValues(`${tabName}.${SecName}.${eForWhom.partner}.${total.name}`)) {
            setValue(`${tabName}.${SecName}.${eForWhom.partner}.${total.name}`, addCurrencySign(0, selectedLang));
        }
    }, [selectedLang, getValues, setValue, tabName, total.name]);

    const calculateTotal = (forWhom) => {
        const nonTaxable = Number(getOnlyNumbers(getValues(`${tabName}.${SecName}.${forWhom}.${nonTaxableBenefit.name}`))) || 0;
        const taxable = Number(getOnlyNumbers(getValues(`${tabName}.${SecName}.${forWhom}.${taxableBenefit.name}`))) || 0;
        const taxRate = Number(getOnlyNumbers(getValues(`${tabName}.${SecName}.${forWhom}.${avgTaxRate.name}`))) || 0;
        const otherMonthly = Number(getOnlyNumbers(getValues(`${tabName}.${SecName}.${forWhom}.${otherMonthlyIncome.name}`))) || 0;

        const totalAmount = Math.round(nonTaxable + (taxable * (1 - (taxRate / 100))) + otherMonthly);

        const income = Number(getOnlyNumbers(getValues(`${tabName}.${currEmployment}.${forWhom}.${monthlyIncome.name}`)));
        const currEmpTotal = Number(getOnlyNumbers(getValues(`${tabName}.${currEmployment}.${forWhom}.${maxInsuranceTotal.name}`)));

        let selfInsuredAmt = 0.5 * (otherMonthly - (0.15 * income));
        if (selfInsuredAmt < 0) {
            selfInsuredAmt = 0;
        }
        const final = currEmpTotal - nonTaxable - (taxable * (1 - (taxRate / 100))) - selfInsuredAmt;

        setValue(`${tabName}.${SecName}.${forWhom}.${total.name}`, addCurrencySign(totalAmount, selectedLang));

        setValue(`${tabName}.${currEmployment}.${forWhom}.maxInsuranceLimit`, addCurrencySign(Math.round(final > 0 ? final : 0), selectedLang));

        const mortage = Number(getOnlyNumbers(getValues(`${tabName}.${MonthlyExpense}.${forWhom}.${mortageOrRent.name}`))) || 0;
        const dept = Number(getOnlyNumbers(getValues(`${tabName}.${MonthlyExpense}.${forWhom}.${deptPayments.name}`))) || 0;
        const savings = Number(getOnlyNumbers(getValues(`${tabName}.${MonthlyExpense}.${forWhom}.${savingContributions.name}`))) || 0;
        const other = Number(getOnlyNumbers(getValues(`${tabName}.${MonthlyExpense}.${forWhom}.${otherExpenses.name}`))) || 0;

        const totalExpenseAmount = mortage + dept + savings + other - nonTaxable - (taxable * (1 - (taxRate / 100))) - otherMonthly

        setValue(`${tabName}.${MonthlyExpense}.${forWhom}.totalAmount`, addCurrencySign(totalExpenseAmount > 0 ? totalExpenseAmount : 0, selectedLang));

        if (getValues(hideYourInput)) {
            setValue(`${tabName}.${SecName}.${eForWhom.you}.${total.name}`, '');
        }
        if (getValues(hidePartnerInput)) {
            setValue(`${tabName}.${SecName}.${eForWhom.partner}.${total.name}`, '');
        }
    }

    const hideYourInput = `${tabName}.${currEmployment}.${eForWhom.you}.hideInput`;
    const hidePartnerInput = `${tabName}.${currEmployment}.${eForWhom.partner}.hideInput`;

    const isHidden = useWatch({
        control,
        name: `${hideYourInput}`
    })
    const isPartnerHidden = useWatch({
        control,
        name: `${hidePartnerInput}`
    })

    if (isHidden) {
        setValue(`${tabName}.${SecName}.${eForWhom.you}.${total.name}`, '');
    }
    if (!isHidden) {
        calculateTotal(eForWhom.you);
    }
    if (isPartnerHidden) {
        setValue(`${tabName}.${SecName}.${eForWhom.partner}.${total.name}`, '');
    }
    if (!isPartnerHidden) {
        calculateTotal(eForWhom.partner);
    }

    const ComponentList = [
        {
            compName: "IrTabTblHeader",
            compProps: {}
        },
        {
            compName: "IrTabTblRowAmount",
            compProps: {
                rowLabel: nonTaxableBenefit.label[selectedLang],
                keyName: nonTaxableBenefit.name,
                maxAmount: nonTaxableBenefit.maxValue,
                tooltipText: nonTaxableBenefit.toolTip[selectedLang],
                tooltipAriaLabel: nonTaxableBenefit.tooltipAriaLabel[selectedLang],
                hideYourInput,
                hidePartnerInput,
                onYouBlur: () => calculateTotal(eForWhom.you),
                onPartnerBlur: () => calculateTotal(eForWhom.partner),
            }
        },
        {
            compName: "IrTabTblRowAmount",
            compProps: {
                rowLabel: taxableBenefit.label[selectedLang],
                keyName: taxableBenefit.name,
                maxAmount: taxableBenefit.maxValue,
                tooltipText: taxableBenefit.toolTip[selectedLang],
                tooltipAriaLabel: taxableBenefit.tooltipAriaLabel[selectedLang],
                hideYourInput,
                hidePartnerInput,
                onYouBlur: () => calculateTotal(eForWhom.you),
                onPartnerBlur: () => calculateTotal(eForWhom.partner),
            }
        },
        {
            compName: "IrTabTblRowPercentage",
            compProps: {
                rowLabel: avgTaxRate.label[selectedLang],
                keyName: avgTaxRate.name,
                maxAmount: avgTaxRate.maxValue,
                hideYourInput,
                hidePartnerInput,
                onYouBlur: () => calculateTotal(eForWhom.you),
                onPartnerBlur: () => calculateTotal(eForWhom.partner),
            }
        },
        {
            compName: "IrTabTblRowAmount",
            compProps: {
                rowLabel: otherMonthlyIncome.label[selectedLang],
                keyName: otherMonthlyIncome.name,
                maxAmount: otherMonthlyIncome.maxValue,
                tooltipText: otherMonthlyIncome.toolTip[selectedLang],
                tooltipAriaLabel: otherMonthlyIncome.tooltipAriaLabel[selectedLang],
                hideYourInput,
                hidePartnerInput,
                onYouBlur: () => calculateTotal(eForWhom.you),
                onPartnerBlur: () => calculateTotal(eForWhom.partner),
            }
        },
        {
            compName: "IrTabTblRowTotal",
            compProps: {
                rowLabel: total.label[selectedLang],
                isKeyAsValue: true,
                value1: `${tabName}.${SecName}.${eForWhom.you}.${total.name}`,
                value2: `${tabName}.${SecName}.${eForWhom.partner}.${total.name}`,
            }
        }

    ];

    return (
        <IrTabSecWrapper secTitle={secTitle[selectedLang]}>
            {
                ComponentList.map((componentOdj, index) => {
                    const { compName, compProps } = componentOdj;
                    return React.createElement(secComponents[compName], {
                        key: `${Date.now()}${index}`,
                        ...commonProps,
                        ...compProps
                    })
                })
            }
        </IrTabSecWrapper>
    )
}

export default IrDisabilityInsuranceOrOtherIncome