import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { TextInput, useLang } from '@manulife/mux';
import LABELS from '../../../assets/labels';
import { getOnlyNumbers } from '../../../services/helpers/commonHelpers';
import NumberConstants from "../../../assets/appStyledComponents/numberConstant.json";

function IrAge({ detailFor }) {
    const { num18, num85 } = NumberConstants;
    const selectedLang = useLang();
    const { control } = useFormContext(); // retrieve all hook methods

    const handleAgeChange = (newValue, event, onChange, onBlur) => {
        /* onBlur */
        if (newValue) {
            const ageInNumber = Number(getOnlyNumbers(newValue));
            if (ageInNumber < num18) {
                onChange(String(num18));
            } else if (ageInNumber > num85) {
                onChange(String(num85));
            } else {
                onChange(String(ageInNumber));
            }
            onBlur();
        }
    };

    return (
        <Controller
            control={control}
            name={`${detailFor}.irAge`}
            rules={{
                required: LABELS.errors.required.irAge[detailFor][selectedLang]
            }}
            render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { error },
            }) => (
                <TextInput
                    label={LABELS.question3[selectedLang][detailFor].question}
                    ariaLabel={LABELS.question3[selectedLang][detailFor].ariaLabel}
                    customStyle={{
                        rootStyle: {
                            width: "100%",
                        },
                    }}
                    ref={ref}
                    value={value}
                    onBlur={(newValue, event) => handleAgeChange(newValue, event, onChange, onBlur)}
                    onChange={(newValue) => onChange(getOnlyNumbers(newValue))}
                    errors={error && [{ id: `error-${name}`, isError: true, msg: error.message }]}
                />
            )}
        />
    )
};

export default IrAge;