import React from 'react';
import IrTabTblRow from './IrTabTblRow';
import useWindowSize from '../../customHooks/useWindowSize';
import NumberConstants from '../../assets/appStyledComponents/numberConstant.json';
import { BREAKPOINTS, Stack, rem } from '@manulife/mux';
import { BoldResultText } from '../../assets/appStyledComponents';
import { useFormContext, useWatch } from 'react-hook-form';

export function IrKeyAsValue({ cLabel, cValue, hideInput }) {
    const { width: windowWidth } = useWindowSize();
    const { num10, num200 } = NumberConstants;
    const { control } = useFormContext();
    const tVal = useWatch({
        control,
        name: `${cValue}`
    })
    const hideTotal = useWatch({
        control,
        name: `${hideInput}`
    })
    return (
        <Stack direction="row" justify={windowWidth > BREAKPOINTS.MAX_768 ? "flex-end" : "space-between"} gap={`${rem(num10)}`} style={windowWidth > BREAKPOINTS.MAX_768 ? { width: rem(num200), alignSelf: `stretch` } : { alignSelf: `stretch`, width: "100%" }}>
            {
                windowWidth < BREAKPOINTS.MAX_768 && (<BoldResultText>{cLabel}</BoldResultText>)
            }
            {!hideTotal && <BoldResultText>{tVal}</BoldResultText>}
        </Stack>
    )
}

export function IrTotal({ cLabel, cValue, hideInput }) {
    const { width: windowWidth } = useWindowSize();
    const { num10, num200 } = NumberConstants;
    const { control } = useFormContext();
    const hideTotal = useWatch({
        control,
        name: `${hideInput}`
    })
    return (
        <Stack direction="row" justify={windowWidth > BREAKPOINTS.MAX_768 ? "flex-end" : "space-between"} gap={`${rem(num10)}`} style={windowWidth > BREAKPOINTS.MAX_768 ? { width: rem(num200), alignSelf: `stretch` } : { alignSelf: `stretch`, width: "100%" }}>
            {
                windowWidth < BREAKPOINTS.MAX_768 && (<BoldResultText>{cLabel}</BoldResultText>)
            }
            {!hideTotal && <BoldResultText>{cValue}</BoldResultText>}
        </Stack>
    )
}

function IrTabTblRowTotal({ selectedIrInsuranceFor, value1, value2, name1, name2, rowLabel, rowSubLabel = "", tooltipText = "", isTopLine = false, isBottomLine = true, bgColor = "white", isKeyAsValue = false, hideYourInput, hidePartnerInput }) {
    return (
        <IrTabTblRow
            bgColor={bgColor}
            selectedIrInsuranceFor={selectedIrInsuranceFor}
            rowLabel={rowLabel}
            rowSubLabel={rowSubLabel}
            isTopLine={isTopLine}
            isBottomLine={isBottomLine}
            tooltipText={tooltipText}
            cellAlign="end"
            input1={isKeyAsValue ? <IrKeyAsValue cLabel={name1} cValue={value1} hideInput={hideYourInput} /> : <IrTotal cLabel={name1} cValue={value1} hideInput={hideYourInput} />}
            input2={isKeyAsValue ? <IrKeyAsValue cLabel={name2} cValue={value2} hideInput={hidePartnerInput} /> : <IrTotal cLabel={name2} cValue={value2} hideInput={hidePartnerInput} />}
        />
    )
}

export default IrTabTblRowTotal;