import React from 'react';
import IrTabTblRow from './IrTabTblRow';
import { eForWhom, eIncluded } from '../../services/helpers/udmConstants';
import NumberConstants from "../../assets/appStyledComponents/numberConstant.json";
import { IrPercentage } from './Inputs';
import { useFormContext } from 'react-hook-form';

const { num100 } = NumberConstants;

function IrTabTblRowPercentage({ tabName, secName, keyName, selectedIrInsuranceFor, name1, name2, rowLabel, rowSubLabel = "", tooltipText = "", isTopLine = false, isBottomLine = true, maxAmount = num100, minAmount = 0, isBlurUpdateNext = false, onYouBlur = () => null, onPartnerBlur = () => null, disableYourInput, disablePartnerInput, hideYourInput, hidePartnerInput }) {

    const { setValue } = useFormContext();

    const handleYouCommonBlur = (value) => {
        if (isBlurUpdateNext && selectedIrInsuranceFor === eIncluded.meAndPartner) {
            setValue(`${tabName}.${secName}.${eForWhom.partner}.${keyName}`, value);
        }
        onYouBlur();
    }
    const handlePartnerCommonBlur = (value) => {
        onPartnerBlur();
    }

    return (
        <IrTabTblRow
            selectedIrInsuranceFor={selectedIrInsuranceFor}
            rowLabel={rowLabel}
            rowSubLabel={rowSubLabel}
            isTopLine={isTopLine}
            isBottomLine={isBottomLine}
            tooltipText={tooltipText}
            input1={<IrPercentage fieldName={`${tabName}.${secName}.${eForWhom.you}.${keyName}`} fieldLabel={name1} fieldAriaLabel={name1} maxAmount={maxAmount} minAmount={minAmount} handleCommonBlur={handleYouCommonBlur} disabled={disableYourInput} hideInput={hideYourInput} />}
            input2={<IrPercentage fieldName={`${tabName}.${secName}.${eForWhom.partner}.${keyName}`} fieldLabel={name2} fieldAriaLabel={name2} maxAmount={maxAmount} minAmount={minAmount} handleCommonBlur={handlePartnerCommonBlur} disabled={disablePartnerInput} hideInput={hidePartnerInput} />}
        />
    )
}

export default React.memo(IrTabTblRowPercentage);