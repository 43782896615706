import React, { useCallback, useEffect } from 'react'
import LifeInputs from './IrLifeInputs.json';
import { IrTabSecWrapper, secComponents } from '../IrCommon';
import { useLang } from '@manulife/mux';
import { eCapitalFundType, eForWhom } from '../../services/helpers/udmConstants';
import { addCurrencySign, addPercentageSign, getOnlyNumbers } from '../../services/helpers/commonHelpers';
import { useFormContext } from 'react-hook-form';

function IrIncome({ name1, name2, selectedIrInsuranceFor, tabName, storedAboutYouData }) {

    const selectedLang = useLang();

    const {
        income: {
            annualHouseholdIncome,
            annualIncomePercentage,
            subTotalIncome,
            partnerAvailableIncome,
            annualCPPBenefits,
            annualIncomeShortage,
            CFDropdown,
            familyNeeds,
            rateOfReturn,
            totalIncome,
            secTitle,
            secSubTitle,
        }
    } = LifeInputs;

    const SecName = "IrIncome";

    const commonProps = {
        name1,
        name2,
        selectedIrInsuranceFor,
        tabName,
        secName: SecName,
    }

    const { getValues, setValue } = useFormContext();

    const householdIncome = Number(getOnlyNumbers(storedAboutYouData.you.irAnnualIncome)) + Number(getOnlyNumbers(storedAboutYouData.partner.irAnnualIncome)) || 0;

    const yourIncome = Number(getOnlyNumbers(storedAboutYouData.you.irAnnualIncome)) || 0;
    const partnerIncome = Number(getOnlyNumbers(storedAboutYouData.partner.irAnnualIncome)) || 0;

    const handleBlurSub = (forWhom, name) => {
        const AIPercentage = Number(getOnlyNumbers(getValues(`${tabName}.${SecName}.${forWhom}.${annualIncomePercentage.name}`)));
        const AnnualCpp = Number(getOnlyNumbers(getValues(`${tabName}.${SecName}.${forWhom}.${annualCPPBenefits.name}`)));
        const selectedIncome = forWhom === eForWhom.you ? partnerIncome : yourIncome;

        const sub = Math.round((householdIncome * (AIPercentage / 100))) || 0;
        if (name === annualIncomePercentage.name) {
            setValue(`${tabName}.${SecName}.${forWhom}.${subTotalIncome.name}`, addCurrencySign(sub, selectedLang));
        }
        const AIShortage = sub - selectedIncome - AnnualCpp;
        setValue(`${tabName}.${SecName}.${forWhom}.${annualIncomeShortage.name}`, addCurrencySign(AIShortage > 0 ? Math.round(AIShortage) : 0, selectedLang));
    }

    const handleBlur = useCallback((forWhom, name) => {
        const AIPercentage = Number(getOnlyNumbers(getValues(`${tabName}.${SecName}.${forWhom}.${annualIncomePercentage.name}`)));
        const AnnualCpp = Number(getOnlyNumbers(getValues(`${tabName}.${SecName}.${forWhom}.${annualCPPBenefits.name}`)));
        let ReturnRate = Number(getOnlyNumbers(getValues(`${tabName}.${SecName}.${forWhom}.${rateOfReturn.name}`)));
        const FamilyNeedYears = Number(getOnlyNumbers(getValues(`${tabName}.${SecName}.${forWhom}.${familyNeeds.name}`)));
        const dropdownValue = getValues(`${tabName}.${SecName}.${forWhom}.${CFDropdown.name}`);

        if (Number(getOnlyNumbers(getValues(`${tabName}.${SecName}.${forWhom}.${rateOfReturn.name}`))) === 0) {
            ReturnRate = 1;
            setValue(`${tabName}.${SecName}.${forWhom}.${rateOfReturn.name}`, addPercentageSign(1))
        }

        const sub = householdIncome * (AIPercentage / 100);
        const selectedIncome = forWhom === eForWhom.you ? partnerIncome : yourIncome;
        const AIShortage = sub - selectedIncome - AnnualCpp;

        if (dropdownValue === eCapitalFundType.deplete) {
            const val = ((1 - Math.pow((1 + (ReturnRate / 100)), (-1 * FamilyNeedYears))) / (ReturnRate / 100)) * AIShortage;
            const totalVal = val >= 0 && !isNaN(val) && val !== Infinity ? Math.round(val) : 0;
            setValue(`${tabName}.${SecName}.${forWhom}.${totalIncome.name}`, addCurrencySign(totalVal, selectedLang));
        } else {
            const val = AIShortage / (ReturnRate / 100);
            const totalVal = val >= 0 && !isNaN(val) && val !== Infinity ? Math.round(val) : 0;
            setValue(`${tabName}.${SecName}.${forWhom}.${totalIncome.name}`, addCurrencySign(totalVal, selectedLang));
        }
    }, [CFDropdown.name, annualCPPBenefits.name, annualIncomePercentage.name, familyNeeds.name, getValues, householdIncome, partnerIncome, rateOfReturn.name, selectedLang, setValue, tabName, totalIncome.name, yourIncome])

    const setInitialValues = useCallback(() => {
        const percent = getValues(`${tabName}.${SecName}.${eForWhom.you}.${annualIncomePercentage.name}`)
        const percentPartner = getValues(`${tabName}.${SecName}.${eForWhom.partner}.${annualIncomePercentage.name}`)

        const defPercent = percent ? percent : addPercentageSign(60, selectedLang)
        const defPercentPartner = percentPartner ? percentPartner : addPercentageSign(60, selectedLang)

        const defaultPercentage = Number(getOnlyNumbers(defPercent));
        const defaultPercentagePartner = Number(getOnlyNumbers(defPercentPartner));

        setValue(`${tabName}.${SecName}.${eForWhom.you}.${annualIncomePercentage.name}`, addPercentageSign(defaultPercentage, selectedLang))
        setValue(`${tabName}.${SecName}.${eForWhom.partner}.${annualIncomePercentage.name}`, addPercentageSign(defaultPercentagePartner, selectedLang))

        const sub = Math.round(householdIncome * (defaultPercentage / 100)) || 0;
        const subPartner = Math.round(householdIncome * (defaultPercentagePartner / 100)) || 0;

        const shortage = Math.round(sub - partnerIncome - Number(getOnlyNumbers(getValues(`${tabName}.${SecName}.${eForWhom.you}.${annualCPPBenefits.name}`))));
        const shortagePartner = Math.round(subPartner - yourIncome - Number(getOnlyNumbers(getValues(`${tabName}.${SecName}.${eForWhom.partner}.${annualCPPBenefits.name}`))));

        setValue(`${tabName}.${SecName}.${eForWhom.you}.${annualHouseholdIncome.name}`, addCurrencySign(householdIncome, selectedLang));
        setValue(`${tabName}.${SecName}.${eForWhom.partner}.${annualHouseholdIncome.name}`, addCurrencySign(householdIncome, selectedLang));

        setValue(`${tabName}.${SecName}.${eForWhom.you}.${partnerAvailableIncome.name}`, addCurrencySign(partnerIncome, selectedLang));
        setValue(`${tabName}.${SecName}.${eForWhom.partner}.${partnerAvailableIncome.name}`, addCurrencySign(yourIncome, selectedLang));

        setValue(`${tabName}.${SecName}.${eForWhom.you}.${annualIncomeShortage.name}`, addCurrencySign(shortage > 0 ? shortage : 0, selectedLang));
        setValue(`${tabName}.${SecName}.${eForWhom.partner}.${annualIncomeShortage.name}`, addCurrencySign(shortagePartner > 0 ? shortagePartner : 0, selectedLang));

        setValue(`${tabName}.${SecName}.${eForWhom.you}.${subTotalIncome.name}`, addCurrencySign(sub, selectedLang));
        setValue(`${tabName}.${SecName}.${eForWhom.partner}.${subTotalIncome.name}`, addCurrencySign(subPartner, selectedLang));

        setValue(`${tabName}.${SecName}.${eForWhom.you}.${totalIncome.name}`, addCurrencySign(0, selectedLang));
        setValue(`${tabName}.${SecName}.${eForWhom.partner}.${totalIncome.name}`, addCurrencySign(0, selectedLang));
        if (!getValues(`${tabName}.${SecName}.${eForWhom.you}.${CFDropdown.name}`)) {
            setValue(`${tabName}.${SecName}.${eForWhom.you}.${CFDropdown.name}`, eCapitalFundType.deplete);
        }
        if (!getValues(`${tabName}.${SecName}.${eForWhom.partner}.${CFDropdown.name}`)) {
            setValue(`${tabName}.${SecName}.${eForWhom.partner}.${CFDropdown.name}`, eCapitalFundType.deplete);
        }
        handleBlur(eForWhom.you);
        handleBlur(eForWhom.partner);
    }, [CFDropdown.name, annualCPPBenefits.name, annualHouseholdIncome.name, annualIncomePercentage.name, annualIncomeShortage.name, getValues, handleBlur, householdIncome, partnerAvailableIncome.name, partnerIncome, selectedLang, setValue, subTotalIncome.name, tabName, totalIncome.name, yourIncome])

    useEffect(() => {
        setInitialValues();
    }, [setInitialValues, storedAboutYouData]);

    const handleDropdownChange = (newValue, forWhom) => {
        if (newValue === eCapitalFundType.retain) {
            setValue(`${tabName}.${SecName}.${forWhom}.hideFamilyNeeds`, true);
        } else if (newValue === eCapitalFundType.deplete) {
            setValue(`${tabName}.${SecName}.${forWhom}.hideFamilyNeeds`, false);
        }
        handleBlur(forWhom, CFDropdown.name);
    }

    const IncomeComponentList = [
        {
            compName: "IrTabTblHeader",
            compProps: {}
        },
        {
            compName: "IrTabTblRowTotal",
            compProps: {
                rowLabel: annualHouseholdIncome.label[selectedLang],
                isKeyAsValue: true,
                value1: `${tabName}.${SecName}.${eForWhom.you}.${annualHouseholdIncome.name}`,
                value2: `${tabName}.${SecName}.${eForWhom.partner}.${annualHouseholdIncome.name}`,
            }
        },
        {
            compName: "IrTabTblRowPercentage",
            compProps: {
                rowLabel: annualIncomePercentage.label[selectedLang],
                keyName: annualIncomePercentage.name,
                maxAmount: annualIncomePercentage.maxValue,
                tooltipText: annualIncomePercentage.toolTip[selectedLang],
                tooltipAriaLabel: annualIncomePercentage.tooltipAriaLabel[selectedLang],
                onYouBlur: () => {
                    handleBlurSub(eForWhom.you, annualIncomePercentage.name);
                    handleBlur(eForWhom.you, annualIncomePercentage.name);
                },
                onPartnerBlur: () => {
                    handleBlurSub(eForWhom.partner, annualIncomePercentage.name);
                    handleBlur(eForWhom.partner, annualIncomePercentage.name);
                }
            }
        },
        {
            compName: "IrTabTblRowTotal",
            compProps: {
                rowLabel: subTotalIncome.label[selectedLang],
                bgColor: "light_grey",
                isKeyAsValue: true,
                value1: `${tabName}.${SecName}.${eForWhom.you}.${subTotalIncome.name}`,
                value2: `${tabName}.${SecName}.${eForWhom.partner}.${subTotalIncome.name}`,
            }
        },
        {
            compName: "IrTabTblRowTotal",
            compProps: {
                rowLabel: partnerAvailableIncome.label[selectedLang],
                isKeyAsValue: true,
                value1: `${tabName}.${SecName}.${eForWhom.you}.${partnerAvailableIncome.name}`,
                value2: `${tabName}.${SecName}.${eForWhom.partner}.${partnerAvailableIncome.name}`,
            }
        },
        {
            compName: "IrTabTblRowAmount",
            compProps: {
                rowLabel: annualCPPBenefits.label[selectedLang],
                keyName: annualCPPBenefits.name,
                maxAmount: annualCPPBenefits.maxValue,
                tooltipText: annualCPPBenefits.toolTip[selectedLang],
                tooltipAriaLabel: annualCPPBenefits.tooltipAriaLabel[selectedLang],
                onYouBlur: () => {
                    handleBlurSub(eForWhom.you, annualCPPBenefits.name);
                    handleBlur(eForWhom.you, annualCPPBenefits.name);
                },
                onPartnerBlur: () => {
                    handleBlurSub(eForWhom.partner, annualCPPBenefits.name);
                    handleBlur(eForWhom.partner, annualCPPBenefits.name);
                }
            }
        },
        {
            compName: "IrTabTblRowTotal",
            compProps: {
                rowLabel: annualIncomeShortage.label[selectedLang],
                tooltipText: annualIncomeShortage.toolTip[selectedLang],
                tooltipAriaLabel: annualIncomeShortage.tooltipAriaLabel[selectedLang],
                isKeyAsValue: true,
                value1: `${tabName}.${SecName}.${eForWhom.you}.${annualIncomeShortage.name}`,
                value2: `${tabName}.${SecName}.${eForWhom.partner}.${annualIncomeShortage.name}`,
            }
        },
        {
            compName: "IrTabTblRowDropdown",
            compProps: {
                rowLabel: CFDropdown.label[selectedLang],
                keyName: CFDropdown.name,
                fieldOptions: CFDropdown.options[selectedLang],
                tooltipText: CFDropdown.toolTip[selectedLang],
                tooltipAriaLabel: CFDropdown.tooltipAriaLabel[selectedLang],
                handleDropdownChange: handleDropdownChange
            }
        },
        {
            compName: "IrTabTblRowNumber",
            compProps: {
                rowLabel: familyNeeds.label[selectedLang],
                keyName: familyNeeds.name,
                maxNumber: familyNeeds.maxValue,
                tooltipText: familyNeeds.toolTip[selectedLang],
                tooltipAriaLabel: familyNeeds.tooltipAriaLabel[selectedLang],
                onYouBlur: () => handleBlur(eForWhom.you, familyNeeds.name),
                onPartnerBlur: () => handleBlur(eForWhom.partner, familyNeeds.name),
                hideYourInput: `${tabName}.${SecName}.${eForWhom.you}.hideFamilyNeeds`,
                hidePartnerInput: `${tabName}.${SecName}.${eForWhom.partner}.hideFamilyNeeds`
            }
        },
        {
            compName: "IrTabTblRowPercentage",
            compProps: {
                rowLabel: rateOfReturn.label[selectedLang],
                keyName: rateOfReturn.name,
                maxAmount: rateOfReturn.maxValue,
                minAmount: rateOfReturn.minValue,
                tooltipText: rateOfReturn.toolTip[selectedLang],
                tooltipAriaLabel: rateOfReturn.tooltipAriaLabel[selectedLang],
                onYouBlur: () => handleBlur(eForWhom.you, rateOfReturn.name),
                onPartnerBlur: () => handleBlur(eForWhom.partner, rateOfReturn.name)
            }
        },
        {
            compName: "IrTabTblRowTotal",
            compProps: {
                rowLabel: totalIncome.label[selectedLang],
                isKeyAsValue: true,
                value1: `${tabName}.${SecName}.${eForWhom.you}.${totalIncome.name}`,
                value2: `${tabName}.${SecName}.${eForWhom.partner}.${totalIncome.name}`,
            }
        },
    ];

    return (
        <IrTabSecWrapper secTitle={secTitle[selectedLang]} secSubTitle={secSubTitle[selectedLang]}>
            {
                IncomeComponentList.map((componentOdj, index) => {
                    const { compName, compProps } = componentOdj;
                    return React.createElement(secComponents[compName], {
                        key: `${Date.now()}${index}`,
                        ...commonProps,
                        ...compProps
                    })
                })
            }
        </IrTabSecWrapper>
    )
}

export default React.memo(IrIncome);