import React, { useEffect } from 'react'
import { IrTabSecWrapper, secComponents } from '../IrCommon';
import { useLang } from '@manulife/mux';
import CriticalInputs from './IrCriticalIllnessInputs.json'
import { addCurrencySign, getOnlyNumbers } from '../../services/helpers/commonHelpers';
import { useFormContext } from 'react-hook-form';
import { eForWhom } from '../../services/helpers/udmConstants';

function IrOffsetCosts({ name1, name2, selectedIrInsuranceFor, tabName }) {

    const selectedLang = useLang();

    const { setValue, getValues } = useFormContext();

    const SecName = "IrOffsetCosts";

    const commonProps = {
        name1,
        name2,
        selectedIrInsuranceFor,
        tabName,
        secName: SecName,
    }

    const {
        assetsAndInsurance: {
            secTitle,
            inputComponents: {
                savingAndAssets,
                exitingCIInsurance,
                total
            }
        }
    } = CriticalInputs

    useEffect(() => {
        if (!getValues(`${tabName}.${SecName}.${eForWhom.you}.${total.name}`)) {
            setValue(`${tabName}.${SecName}.${eForWhom.you}.${total.name}`, addCurrencySign(0, selectedLang));
        }
        if (!getValues(`${tabName}.${SecName}.${eForWhom.partner}.${total.name}`)) {
            setValue(`${tabName}.${SecName}.${eForWhom.partner}.${total.name}`, addCurrencySign(0, selectedLang));
        }
    }, [selectedLang, getValues, setValue, tabName, total.name])

    const calculateTotal = (forWhom) => {
        const savings = Number(getOnlyNumbers(getValues(`${tabName}.${SecName}.${forWhom}.${savingAndAssets.name}`))) || 0;
        const existing = Number(getOnlyNumbers(getValues(`${tabName}.${SecName}.${forWhom}.${exitingCIInsurance.name}`))) || 0;

        setValue(`${tabName}.${SecName}.${forWhom}.${total.name}`, addCurrencySign(savings + existing, selectedLang));
    }

    const ComponentList = [
        {
            compName: "IrTabTblHeader",
            compProps: {}
        },
        {
            compName: "IrTabTblRowAmount",
            compProps: {
                rowLabel: savingAndAssets.label[selectedLang],
                keyName: savingAndAssets.name,
                maxAmount: savingAndAssets.maxValue,
                tooltipText: savingAndAssets.toolTip[selectedLang],
                tooltipAriaLabel: savingAndAssets.tooltipAriaLabel[selectedLang],
                onYouBlur: () => calculateTotal(eForWhom.you),
                onPartnerBlur: () => calculateTotal(eForWhom.partner),
            }
        },
        {
            compName: "IrTabTblRowAmount",
            compProps: {
                rowLabel: exitingCIInsurance.label[selectedLang],
                keyName: exitingCIInsurance.name,
                maxAmount: exitingCIInsurance.maxValue,
                onYouBlur: () => calculateTotal(eForWhom.you),
                onPartnerBlur: () => calculateTotal(eForWhom.partner),
            }
        },
        {
            compName: "IrTabTblRowTotal",
            compProps: {
                rowLabel: total.label[selectedLang],
                isKeyAsValue: true,
                value1: `${tabName}.${SecName}.${eForWhom.you}.${total.name}`,
                value2: `${tabName}.${SecName}.${eForWhom.partner}.${total.name}`,
            }
        }
    ];

    return (
        <IrTabSecWrapper secTitle={secTitle[selectedLang]}>
            {
                ComponentList.map((componentOdj, index) => {
                    const { compName, compProps } = componentOdj;
                    return React.createElement(secComponents[compName], {
                        key: `${Date.now()}${index}`,
                        ...commonProps,
                        ...compProps
                    })
                })
            }
        </IrTabSecWrapper>
    )
}

export default IrOffsetCosts