import { BREAKPOINTS, Stack, Tooltip, colors, rem } from '@manulife/mux';
import React from 'react';
import useWindowSize from '../../customHooks/useWindowSize';
import NumberConstants from '../../assets/appStyledComponents/numberConstant.json';
import { BoldResultText, NormalText } from '../../assets/appStyledComponents';
import { eIncluded } from '../../services/helpers/udmConstants';

function IrTabTblRow({ selectedIrInsuranceFor, rowLabel = "", rowSubLabel = "", input1, input2, isTopLine = false, isBottomLine = true, cellAlign = "start", tooltipText = "", tooltipAriaLabel = "", bgColor = "white", tooltipWidth }) {
    const { width: windowWidth } = useWindowSize();
    const { num2, num12, num24, num16, num10, num200 } = NumberConstants;
    return (
        <Stack align="flex-start" gap={`${rem(0)}`} style={{
            alignSelf: `stretch`,
            backgroundColor: colors[bgColor]
        }}>
            {
                isTopLine && <Stack align="flex-start" gap={`${rem(0)}`} style={{
                    padding: rem(num2),
                    alignSelf: `stretch`,
                    backgroundColor: colors.light_grey
                }}>
                </Stack>
            }
            <Stack direction="row" align="flex-start" gap={`${rem(num24)}`}
                style={{
                    padding: windowWidth > BREAKPOINTS.MAX_768 ? `${rem(num16)} ${rem(num12)}` : `${rem(num16)} ${rem(num24)}`,
                    alignSelf: `stretch`
                }}>
                <Stack direction="row" align="center" gap={`${rem(num10)}`} style={windowWidth > BREAKPOINTS.MAX_768 ? { flex: "1 0 0", alignSelf: `stretch` } : { alignSelf: `stretch`, width: "100%" }}>
                    <div style={{ flex: "1 0 0", display: "inline" }}>
                        <BoldResultText style={{ display: "inline" }}>{rowLabel} </BoldResultText>
                        <NormalText style={{ display: "inline" }}>{rowSubLabel}</NormalText>
                    </div>
                    {
                        tooltipText !== "" && <Tooltip
                            title={tooltipText}
                            ariaLabel={tooltipAriaLabel}
                            bubbleWidth={tooltipWidth ? tooltipWidth : num200}
                            variant={Tooltip.VARIANT.SECONDARY}
                        />
                    }
                </Stack>
                <Stack align={`flex-${cellAlign}`} gap={`${rem(num10)}`} style={windowWidth > BREAKPOINTS.MAX_768 ? { width: rem(num200), alignSelf: `stretch` } : { alignSelf: `stretch`, width: "100%" }}>
                    {input1}
                </Stack>
                <Stack align={`flex-${cellAlign}`} gap={`${rem(num10)}`} style={windowWidth > BREAKPOINTS.MAX_768 ? { width: rem(num200), alignSelf: `stretch` } : { alignSelf: `stretch`, width: "100%" }}>
                    {selectedIrInsuranceFor === eIncluded.meAndPartner && <>{input2}</>}
                </Stack>
            </Stack>
            {
                isBottomLine && <Stack align="flex-start" gap={`${rem(0)}`} style={{
                    padding: rem(1),
                    margin: windowWidth > BREAKPOINTS.MAX_768 ? rem(0) : `${rem(0)} ${rem(num24)}`,
                    alignSelf: `stretch`,
                    backgroundColor: colors.light_grey
                }}>
                </Stack>
            }
        </Stack>
    )
}

export default IrTabTblRow;