import React, { useEffect } from 'react';
import IrHeading from '../components/IrAboutYou/IrHeading';
import IrAboutYouForm from '../components/IrAboutYou/IrAboutYouForm';
import { BREAKPOINTS, Stack, rem } from '@manulife/mux';
import NumberConstants from '../assets/appStyledComponents/numberConstant.json';
import useWindowSize from '../customHooks/useWindowSize';

function IrAboutYou() {
    const { width: windowWidth } = useWindowSize();
    const { num30, num32, num40, num64, num50 } = NumberConstants;
    useEffect(() => {
        window.scrollTo({ top: 0 })
    }, []);
    return (
        <Stack data-testid="IrAboutYouWrapper" align="flex-start" gap={`${rem(num30)}`} style={{
            maxWidth: `${rem(BREAKPOINTS.DESKTOP_MED)}`,
            padding: windowWidth > BREAKPOINTS.MAX_768 ? `${rem(num40)} ${rem(num64)} ${rem(num50)} ${rem(num64)}` : `${rem(num32)} ${rem(0)} ${rem(0)} ${rem(0)}`
        }}>
            <IrHeading />
            <IrAboutYouForm />
        </Stack>
    )
}

export default IrAboutYou;