import React from 'react';
import IrTabTblRow from './IrTabTblRow';
import { eForWhom, eIncluded } from '../../services/helpers/udmConstants';
import NumberConstants from "../../assets/appStyledComponents/numberConstant.json";
import { IrNumber } from './Inputs';
import { useFormContext } from 'react-hook-form';

const { num100 } = NumberConstants;

function IrTabTblRowNumber({ tabName, secName, keyName, selectedIrInsuranceFor, name1, name2, rowLabel, rowSubLabel = "", tooltipText = "", isTopLine = false, isBottomLine = true, maxNumber = num100, isBlurUpdateNext = false, onYouBlur = () => null, onPartnerBlur = () => null, hideYourInput, hidePartnerInput }) {

    const { setValue } = useFormContext();

    const handleYouCommonBlur = (value) => {
        if (isBlurUpdateNext && selectedIrInsuranceFor === eIncluded.meAndPartner) {
            setValue(`${tabName}.${secName}.${eForWhom.partner}.${keyName}`, value);
        }
        onYouBlur();
    }
    const handlePartnerCommonBlur = (value) => {
        onPartnerBlur();
    }

    return (
        <IrTabTblRow
            selectedIrInsuranceFor={selectedIrInsuranceFor}
            rowLabel={rowLabel}
            rowSubLabel={rowSubLabel}
            isTopLine={isTopLine}
            isBottomLine={isBottomLine}
            tooltipText={tooltipText}
            input1={<IrNumber fieldName={`${tabName}.${secName}.${eForWhom.you}.${keyName}`} fieldLabel={name1} fieldAriaLabel={name1} maxNumber={maxNumber} handleCommonBlur={handleYouCommonBlur} hideInput={hideYourInput} />}
            input2={<IrNumber fieldName={`${tabName}.${secName}.${eForWhom.partner}.${keyName}`} fieldLabel={name2} fieldAriaLabel={name2} maxNumber={maxNumber} handleCommonBlur={handlePartnerCommonBlur} hideInput={hidePartnerInput} />}
        />
    )
}

export default React.memo(IrTabTblRowNumber);