import React, { useEffect } from 'react';

import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { TextInput, useLang, LANG, BREAKPOINTS } from '@manulife/mux';
import { addCurrencySign, getAmountNumbers } from '../../../services/helpers/commonHelpers';
import NumberConstants from "../../../assets/appStyledComponents/numberConstant.json";
import LABELS from '../../../assets/labels';
import useWindowSize from '../../../customHooks/useWindowSize';

const { num1000000 } = NumberConstants;

function IrAmount({ fieldName, fieldLabel, fieldAriaLabel, maxAmount = num1000000, handleCommonBlur = () => null, hideInput, disabled }) {
    const { width: windowWidth } = useWindowSize();
    const { num2 } = NumberConstants;
    const selectedLang = useLang();
    const { control, setValue, getValues } = useFormContext(); // retrieve all hook methods

    useEffect(() => {
        setValue(`${fieldName}`, addCurrencySign(getAmountNumbers(getValues(`${fieldName}`)), selectedLang));
        return () => null
    }, [selectedLang, setValue, getValues, fieldName]);

    const isHide = useWatch({
        control,
        name: `${hideInput}`
    })
    const isDisabled = useWatch({
        control,
        name: `${disabled}`
    })

    const handleAmountChange = (newValue, event, onChange) => {
        /* onChange */
        const amountInNumber = getAmountNumbers(newValue);
        if (amountInNumber > maxAmount) {
            onChange(addCurrencySign(maxAmount, selectedLang));
        } else {
            onChange(addCurrencySign(amountInNumber, selectedLang));
        }
        if (selectedLang === LANG.FR_CA) {
            setTimeout(() => {
                event.target.setSelectionRange(
                    event.target.value.length - num2,
                    event.target.value.length - num2
                );
            }, 0);
        };
    };

    const handleAmountBlur = (newValue, onBlur) => {
        handleCommonBlur(newValue);
        onBlur()
    }

    const normalStyle = {
        width: "100%",
    }

    return (
        <>
            {!isHide && <Controller
                control={control}
                name={`${fieldName}`}
                rules={{
                    required: LABELS.errors.required[selectedLang]
                }}
                render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { error },
                }) => (
                    <TextInput
                        label={windowWidth > BREAKPOINTS.MAX_768 ? "" : fieldLabel}
                        ariaLabel={fieldAriaLabel}
                        ariaRequired={true}
                        data-testid={name}
                        placeholder={selectedLang === LANG.EN_CA ? "$ 0" : "0 $"}
                        customStyle={{
                            rootStyle: normalStyle,
                        }}
                        ref={ref}
                        value={value}
                        // onBlur={onBlur}
                        onBlur={(newValue) => handleAmountBlur(newValue, onBlur)}
                        onFocus={(newValue, event) => {
                            if (selectedLang === LANG.FR_CA) {
                                event.target.setSelectionRange(
                                    event.target.value.length - num2,
                                    event.target.value.length - num2
                                );
                            }
                        }}
                        onChange={(newValue, event) => handleAmountChange(newValue, event, onChange)}
                        errors={error && [{ id: `error-${name}`, isError: true, msg: error.message }]}
                        disabled={isDisabled}
                    />
                )}
            />}
        </>
    )
};

export default IrAmount;