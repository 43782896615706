import { eChoice, eForWhom, eIncluded, eSex, eHealthstyleType, eEmploymentStatusType } from "../services/helpers/udmConstants";
import { rem } from '@manulife/mux';
import NumberConstants from "./appStyledComponents/numberConstant.json"

const { num360 } = NumberConstants;

const LABELS = {
  colWidth: rem(num360),
  select: {
    "en-CA": "Select",
    "fr-CA": "Sélectionnez"
  },
  contactUs: {
    "en-CA": {
      label: "Contact us",
      url: "https://www.manulife.ca/personal/support/contact-us.html",
    },
    "fr-CA": {
      label: "Pour nous joindre",
      url: "https://www.manuvie.ca/particuliers/soutien/pour-nous-joindre.html",
    },
  },
  contactYourWholesaler: {
    "en-CA": {
      label: "Find an advisor",
      url: "https://www.manulife.ca/personal/support/find-an-advisor/insurance.html",
    },
    "fr-CA": {
      label: "Trouver un conseiller",
      url: "https://www.manuvie.ca/particuliers/soutien/trouver-un-conseiller/assurance.html#search?lob=66866",
    },
  },
  Advisor: {
    "en-CA": "Advisor Portal",
    "fr-CA": "Portail des conseillers",
  },
  Profile: {
    "en-CA": "Profile",
    "fr-CA": "Profil",
  },
  youHeader: {
    "en-CA": "You:",
    "fr-CA": "Vous :",
  },
  partnerHeader: {
    "en-CA": "Your partner:",
    "fr-CA": "Votre conjoint :",
  },
  copyrightTextFooter: {
    "en-CA": "© The Manufacturers Life Insurance Company, 1999-2023.",
    "fr-CA": "© La Compagnie d'Assurance-Vie Manufacturers, 1999-2023.",
  },
  Privacy: {
    "en-CA": {
      label: "Privacy",
      url: "https://www.manulife.ca/privacy-policies.html",
    },
    "fr-CA": {
      label: "Politique de confidentialité",
      url: "https://www.manuvie.ca/politiques-de-confidentialite.html",
    },
  },
  Legal: {
    "en-CA": {
      label: "Legal",
      url: "https://www.manulife.com/en/legal.html",
    },
    "fr-CA": {
      label: "Notice juridique",
      url: "https://www.manulife.com/fr/legal.html",
    },
  },
  Accessibility: {
    "en-CA": {
      label: "Accessibility",
      url: "https://www.manulife.ca/about-us/accessibility.html",
    },
    "fr-CA": {
      label: "Accessibilité | Manuvie",
      url: "https://www.manuvie.ca/a-propos-de-nous/accessibilite.html",
    },
  },
  Disclaimer: {
    "en-CA": {
      label: "Disclaimer",
    },
    "fr-CA": {
      label: "Avis important",
    },
  },
  errors: {
    apiFailed: {
      "en-CA":
        "There is a connection issue and we can’t complete the illustration at this time. Close the program and try again. If the problem continues, please contact Manulife.",
      "fr-CA":
        "Un problème de connexion nous empêche actuellement de créer le projet informatisé. Veuillez fermer le programme et essayer de nouveau. Si le problème persiste, communiquez avec Manuvie.",
    },
    required: {
      irAge: {
        [eForWhom.you]: {
          "en-CA": "Please enter your age",
          "fr-CA": "Veuillez indiquer votre âge"
        },
        [eForWhom.partner]: {
          "en-CA": "Please enter your partner’s age",
          "fr-CA": "Veuillez entrer l’âge de votre conjoint ou conjointe"
        }
      },
      irSex: {
        [eForWhom.you]: {
          "en-CA": "Please select your sex",
          "fr-CA": "Veuillez indiquer votre sexe"
        },
        [eForWhom.partner]: {
          "en-CA": "Please select your partner’s sex",
          "fr-CA": "Veuillez sélectionner le sexe de votre conjoint ou conjointe"
        }
      },
      irSmokingStatus: {
        [eForWhom.you]: {
          "en-CA": "Please select your smoking status",
          "fr-CA": "Veuillez indiquer votre statut de femeur"
        },
        [eForWhom.partner]: {
          "en-CA": "Please select your partner’s smoking status",
          "fr-CA": "Veuillez sélectionner le statut de fumeur de votre partenaire"
        }
      },
      irEmploymentStatus: {
        [eForWhom.you]: {
          "en-CA": "Please select your employment status",
          "fr-CA": "Veuillez sélectionner votre situation d’emploi"
        },
        [eForWhom.partner]: {
          "en-CA": "Please select your partner's employment status",
          "fr-CA": "Veuillez sélectionner la situation d’emploi de votre conjoint"
        }
      }
    }
  },
  ProductOtIllustration: {
    "en-CA": {
      label: "Products to illustrate",
      url: "https://www.insurance.manulife.ca",
    },
    "fr-CA": {
      label: "Produits qui feront l'objet d'un projet informatisé",
      url: "https://id.manulife.ca/advisor/signin?ui_locales=fr-CA",
    },
  },
  Pay: {
    "en-CA": "15 Pay",
    "fr-CA": "15 pmts",
  },
  Level: {
    "en-CA": "Level",
    "fr-CA": "Uniforme",
  },
  LearnMore: {
    "en-CA": "Learn More",
    "fr-CA": "En savoir plus",
  },
  ariaAgeLearnMore: {
    "en-CA": "Age Learn More ",
    "fr-CA": "Âge En savoir plus",
  },
  ariaHealthstyleLearnMore: {
    "en-CA": "Smoking status Learn More",
    "fr-CA": "Usage du tabac En savoir plus",
  },
  disclaimer: {
    "en-CA": "Disclaimer: Important information about this quote",
    "fr-CA": "Avertissement : Renseignements importants sur ce devis",
  },
  manulifeLink: {
    "en-CA": {
      label: "Manulife Illustrations",
      url: "https://www.insurance.manulife.ca/illustrations/",
    },
    "fr-CA": {
      label: "Projets informatisés de Manuvie",
      url: "https://www.insurance.manulife.ca/illustrations/",
    },
  },
  irCalculatorPageHeading: {
    "en-CA": "InsureRight Calculator",
    "fr-CA": "Calculateur Bien s’assurer",
  },
  irCalculatorDescription: {
    "en-CA": "With our InsureRight Calculator, you can complete an analysis of your life, disability and critical illness insurance needs. The calculator uses your financial information today to help you decide how much insurance you may need.",
    "fr-CA": "Grâce à notre calculateur Bien s’assurer, vous pouvez effectuer une analyse plus détaillée de vos besoins d’assurances vie, invalidité et maladies graves. En utilisant vos données financières actuelles, le calculateur vous aide à déterminer le montant d’assurance dont vous avez besoin.",
  },
  aboutYouHeading: {
    "en-CA": "About you",
    "fr-CA": "Renseignements sur vous",
  },
  aboutYouDescription1: {
    "en-CA": "Let’s get started. First, tell us about yourself and your family. Then click on the section you’d like to complete (if you’re not sure of the answers, your advisor can help).",
    "fr-CA": "Nous sommes maintenant prêts à commencer. Tout d’abord, vous devez nous fournir des renseignements sur vous et votre famille. Ensuite, cliquez sur la section que vous aimeriez remplir ( vous pouvez vous adresser à votre conseiller si vous avez besoin d’aide pour certaines questions ).",
  },
  aboutYouDescription2: {
    "en-CA": "All fields are mandatory unless marked optional.",
    "fr-CA": "Sauf indication contraire, tous les champs sont obligatoires.",
  },
  question1: {
    "en-CA": {
      question: "Who is included in this insurance needs analysis?",
      ariaLabel: "Who is included in this insurance needs analysis?",
      options: [
        {
          label: "Just me",
          value: eIncluded.justMe,
        },
        {
          label: "Me & my partner",
          value: eIncluded.meAndPartner,
        }
      ],
    },
    "fr-CA": {
      question: "Qui est inclus dans cette analyse des besoins en assurance?",
      ariaLabel: "Qui est inclus dans cette analyse des besoins en assurance?",
      options: [
        {
          label: "Juste moi",
          value: eIncluded.justMe,
        },
        {
          label: "Moi et mon partenaire",
          value: eIncluded.meAndPartner,
        }
      ],
    },
  },
  youSectionHeading: {
    "en-CA": "Your information",
    "fr-CA": "Vous",
  },
  partnerSectionHeading: {
    "en-CA": "Your partner’s information",
    "fr-CA": "Votre conjoint :",
  },
  question2: {
    "en-CA": {
      [eForWhom.you]: {
        question: "What is your name?",
        ariaLabel: "What is your name?"
      },
      [eForWhom.partner]: {
        question: "What is your partner’s name?",
        ariaLabel: "What is your partner’s name?"
      }
    },
    "fr-CA": {
      [eForWhom.you]: {
        question: "Prénom :",
        ariaLabel: "Prénom :"
      },
      [eForWhom.partner]: {
        question: "Prénom :",
        ariaLabel: "Prénom :"
      }
    },
  },
  question3: {
    "en-CA": {
      [eForWhom.you]: {
        question: "What is your age? (18-85)",
        ariaLabel: "What is your age? (18-85)"
      },
      [eForWhom.partner]: {
        question: "What is your partner’s age? (18-85)",
        ariaLabel: "What is your partner’s age? (18-85)"
      }
    },
    "fr-CA": {
      [eForWhom.you]: {
        question: "Quel âge avez-vous? (18 à 85) :",
        ariaLabel: "Quel âge avez-vous? (18 à 85) :"
      },
      [eForWhom.partner]: {
        question: "Quel est l'âge de votre partenaire? (18 å 85) :",
        ariaLabel: "Quel est l'âge de votre partenaire? (18 å 85) :"
      }
    },
  },
  question4: {
    "en-CA": {
      [eForWhom.you]: {
        question: "What is your sex?",
        ariaLabel: "What is your sex?",
      },
      [eForWhom.partner]: {
        question: "What is your partner’s sex?",
        ariaLabel: "What is your partner’s sex?"
      },
      options: [
        {
          label: "Male",
          value: eSex.male,
        },
        {
          label: "Female",
          value: eSex.female,
        },
      ],
    },
    "fr-CA": {
      [eForWhom.you]: {
        question: "Quel est votre sexe?",
        ariaLabel: "Quel est votre sexe?",
      },
      [eForWhom.partner]: {
        question: "Quel est le sexe de votre partenaire?",
        ariaLabel: "Quel est le sexe de votre partenaire?"
      },
      options: [
        {
          label: "Homme",
          value: eSex.male,
        },
        {
          label: "Femme",
          value: eSex.female,
        },
      ],
    },
  },
  question5: {
    "en-CA": {
      [eForWhom.you]: {
        question: "What is your smoking status?",
        ariaLabel: "What is your smoking status?",
      },
      [eForWhom.partner]: {
        question: "What is your partner’s smoking status?",
        ariaLabel: "What is your partner’s smoking status?"
      },
      options: [
        {
          label: "Non-smoker",
          value: eHealthstyleType.nonSmoker,
        },
        {
          label: "Smoker",
          value: eHealthstyleType.smoker,
        },
      ],
    },
    "fr-CA": {
      [eForWhom.you]: {
        question: "Quel est votre statut de fumeur?",
        ariaLabel: "Quel est votre statut de fumeur?",
      },
      [eForWhom.partner]: {
        question: "Quel est le statut de fumeur de votre partenaire?",
        ariaLabel: "Quel est le statut de fumeur de votre partenaire?"
      },
      options: [
        {
          label: "Non fumeur",
          value: eHealthstyleType.nonSmoker,
        },
        {
          label: "Fumeur",
          value: eHealthstyleType.smoker,
        },
      ],
    },
  },
  question6: {
    "en-CA": {
      [eForWhom.you]: {
        question: "What is your employment status?",
        ariaLabel: "What is your employment status?",
      },
      [eForWhom.partner]: {
        question: "What is your partner’s employment status?",
        ariaLabel: "What is your partner’s employment status?"
      },
      options: [
        {
          label: "Employed",
          value: eEmploymentStatusType.employed,
        },
        {
          label: "Not employed",
          value: eEmploymentStatusType.notEmployed,
        },
        {
          label: "Self-employed",
          value: eEmploymentStatusType.selfEmployed,
        },
      ],
    },
    "fr-CA": {
      [eForWhom.you]: {
        question: "Situation d’emploi :",
        ariaLabel: "Situation d’emploi :",
      },
      [eForWhom.partner]: {
        question: "Situation d’emploi :",
        ariaLabel: "Situation d’emploi :"
      },
      options: [
        {
          label: "Employé",
          value: eEmploymentStatusType.employed,
        },
        {
          label: "Sans emploi",
          value: eEmploymentStatusType.notEmployed,
        },
        {
          label: "Travailleur indépendant",
          value: eEmploymentStatusType.selfEmployed,
        },
      ],
    },
  },
  question7: {
    "en-CA": {
      [eForWhom.you]: {
        question: "What is your annual income (before tax)? (optional)",
        ariaLabel: "What is your annual income (before tax)? (optional)"
      },
      [eForWhom.partner]: {
        question: "What is your partner's annual income (before tax)? (optional)",
        ariaLabel: "What is your partner's annual income (before tax)? (optional)"
      },
    },
    "fr-CA": {
      [eForWhom.you]: {
        question: "Revenu annuel (avant impôts) (facultatif)",
        ariaLabel: "Revenu annuel (avant impôts) (facultatif)"
      },
      [eForWhom.partner]: {
        question: "Revenu annuel (avant impôts) :",
        ariaLabel: "Revenu annuel (avant impôts) :"
      },
    },
  },
  question8: {
    "en-CA": {
      question: "How many children do you have?",
      ariaLabel: "How many children do you have?"
    },
    "fr-CA": {
      question: "Vous avez combien d’enfants?",
      ariaLabel: "Vous avez combien d’enfants?"
    },
  },
  question9: {
    "en-CA": {
      question: "Do you have enough money to send your children to college or university?",
      ariaLabel: "Do you have enough money to send your children to college or university?",
      options: [
        {
          label: "Yes",
          value: eChoice.yes,
        },
        {
          label: "No",
          value: eChoice.no,
        }
      ]
    },
    "fr-CA": {
      question: "Disposez-vous de sommes suffisantes pour couvrir les frais d’études collégiales ou universitaires de vos enfants?",
      ariaLabel: "Disposez-vous de sommes suffisantes pour couvrir les frais d’études collégiales ou universitaires de vos enfants?",
      options: [
        {
          label: "Oui",
          value: eChoice.yes,
        },
        {
          label: "Non",
          value: eChoice.no,
        }
      ]
    },
  },
  aboutYouSubmitBtn: {
    "en-CA": {
      label: "Continue",
      ariaLabel: "Continue"
    },
    "fr-CA": {
      label: "Continuer",
      ariaLabel: "Continuer"
    },
  },
  tabs: {
    life: {
      "en-CA": {
        "label": "Life"
      },
      "fr-CA": {
        "label": "Vie"
      }
    },
    disability: {
      "en-CA": {
        "label": "Disability"
      },
      "fr-CA": {
        "label": "Invalidité"
      }
    },
    criticalIllness: {
      "en-CA": {
        "label": "Critical illness"
      },
      "fr-CA": {
        "label": "Maladies graves"
      }
    },
  },
  CFDropdown: {
    Deplete: {
      "en-CA": "Deplete",
      "fr-CA": "Epuisement"
    },
    Retain: {
      "en-CA": "Retain",
      "fr-CA": "Conservation"
    }
  }
};
export default LABELS;
