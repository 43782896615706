import {
  eCalculatorTab,
  eChoice,
  eIncluded,
} from "../../services/helpers/udmConstants";
import {
  IRC_SET_INITIAL_STATE,
  IRC_SET_STEP_1,
  IRC_SET_STEP_2,
  RESET_STEP_2_RESULT,
  FETCH_REPORT_REQUEST,
  DOWNLOAD_REPORT_REQUEST,
  FETCH_REPORT_SUCCESS,
  FETCH_REPORT_FAILURE,
  CURR_TAB,
} from "./Types";

const initialState = {
  isLoading: false,
  isDownloading: false,
  error: "",
  currTab: "",
  data: {
    step1: {
      irInsuranceFor: eIncluded.justMe,
      irHaveMoney: eChoice.no,
      irChildCount: "0",
      you: {
        irName: "",
        irAge: "",
        irSex: "",
        irSmokingStatus: "",
        irEmploymentStatus: "",
        irAnnualIncome: "0",
      },
      partner: {
        irName: "",
        irAge: "",
        irSex: "",
        irSmokingStatus: "",
        irEmploymentStatus: "",
        irAnnualIncome: "0",
      },
    },
    step2: {
      [eCalculatorTab.life]: {},
      [eCalculatorTab.disability]: {},
      [eCalculatorTab.criticalIllness]: {},
    },
    result: {
      [eCalculatorTab.life]: {},
      [eCalculatorTab.disability]: {},
      [eCalculatorTab.criticalIllness]: {},
    },
  },
};

const IrCalculatorReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case IRC_SET_INITIAL_STATE:
      return {
        ...initialState,
      };

    case IRC_SET_STEP_1:
      return {
        ...state,
        data: {
          ...state.data,
          step1: {
            ...payload,
          }
        },
      };

    case IRC_SET_STEP_2:
      return {
        ...state,
        data: {
          ...state.data,
          step2: {
            ...payload,
          },
        },
      };

    case RESET_STEP_2_RESULT:
      return {
        ...state,
        data: {
          ...state.data,
          step2: { ...initialState.data.step2 },
          result: { ...initialState.data.result },
        },
      };

    case CURR_TAB:
      return {
        ...state,
        currTab: payload,
      };

    case FETCH_REPORT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case DOWNLOAD_REPORT_REQUEST:
      return {
        ...state,
        isDownloading: true,
      };

    case FETCH_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isDownloading: false,
        error: "",
        data: {
          ...state.data,
          result: {
            ...state.data.result,
            [state.currTab]: {
              ...payload,
            },
          },
        },
      };

    case FETCH_REPORT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isDownloading: false,
        data: {
          ...state.data,
          result: {
            ...state.data.result,
            [state.currTab]: state.isDownloading ? state.data.result?.[state.currTab] : {},
          },
        },
        error: payload,
      };

    default:
      return state;
  }
};

export default IrCalculatorReducer;
