import IrTabBody from "./IrTabBody";
import IrTabSecWrapper from "./IrTabSecWrapper";
import IrTabTblHeader from "./IrTabTblHeader";
import IrTabTblRow from "./IrTabTblRow";
import IrTabTblRowAmount from "./IrTabTblRowAmount";
import IrTabTblRowDropdown from "./IrTabTblRowDropdown";
import IrTabTblRowNumber from "./IrTabTblRowNumber";
import IrTabTblRowPercentage from "./IrTabTblRowPercentage";
import IrTabTblRowTotal from "./IrTabTblRowTotal";

const secComponents = { IrTabTblHeader, IrTabTblRowAmount, IrTabTblRowDropdown, IrTabTblRowNumber, IrTabTblRowPercentage, IrTabTblRowTotal };

export {
    IrTabSecWrapper,
    IrTabTblRowTotal,
    IrTabTblRowPercentage,
    IrTabTblRowNumber,
    IrTabTblRowDropdown,
    IrTabTblRowAmount,
    IrTabTblRow,
    IrTabTblHeader,
    IrTabBody,
    secComponents
};