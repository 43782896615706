import {
  RESET_CHECKS,
  UPDATE_CHECKS,
} from "./Types";

export const resetChecks = () => {
  return {
    type: RESET_CHECKS,
  };
};

export const updateChecks = (checksObj) => {
  return {
    type: UPDATE_CHECKS,
    payload: checksObj,
  };
};