import { eCalculatorTab } from "../services/helpers/udmConstants";

const rc = {
    "sectionHeading": {
        "en-CA": "Your results",
        "fr-CA": "Vos résultats"
    },
    "name": {
        "en-CA": "You",
        "fr-CA": "Vous"
    },
    "partnerName": {
        "en-CA": "Your partner",
        "fr-CA": "Votre conjoint"
    },
    "resultText": {
        "en-CA": "Based on the information you’ve provided, we’ve calculated the amount of insurance you may need and how much it may cost. You may find it helpful to discuss these results with your financial advisor. If you don’t have a financial advisor, we can help you ",
        "fr-CA": "En tenant compte des renseignements que vous nous avez fournis, nous avons calculé le montant d’assurance dont vous pourriez avoir besoin ainsi que son coût. Vous pourriez trouver utile de discuter de ces résultats avec votre conseiller financier. Si vous n’avez pas de conseiller financier, nous pouvons vous aider à en "
    },
    "linkText": {
        "label": {
            "en-CA": "locate someone in your area.",
            "fr-CA": "trouver un qui offre ses services dans votre région"
        },
        "URL": {
            "en-CA": "https://www.manulife.ca/personal/support/find-an-advisor/insurance.html#search?lob=66866",
            "fr-CA": "https://www.manuvie.ca/particuliers/soutien/trouver-un-conseiller/assurance.html#search?lob=66866"
        }

    },
    "groupCheckbox": {
        "en-CA": "Download or email needs analysis results",
        "fr-CA": "Télécharger ou envoyer par courriel les résultats de l’analyse des besoins"
    },
    "checkboxOptions": {
        "en-CA": {
            "options": [
                {
                    "label": "Life",
                    "value": eCalculatorTab.life,
                    "disabled": true
                },
                {
                    "label": "Disability",
                    "value": eCalculatorTab.disability,
                    "disabled": true
                },
                {
                    "label": "Critical illness",
                    "value": eCalculatorTab.criticalIllness,
                    "disabled": true
                }
            ]
        },
        "fr-CA": {
            "options": [
                {
                    "label": "Vie",
                    "value": eCalculatorTab.life,
                    "disabled": true
                },
                {
                    "label": "Invalidité",
                    "value": eCalculatorTab.disability,
                    "disabled": true
                },
                {
                    "label": "Maladies graves",
                    "value": eCalculatorTab.criticalIllness,
                    "disabled": true
                }
            ]
        }
    },
    "viewPDF": {
        "en-CA": "Download PDF",
        "fr-CA": "Télécharger PDF"
    },
    "email": {
        "en-CA": "Email results",
        "fr-CA": "Envoyer par courriel"
    }
};

export default rc;